import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_HEADER, kickout_registrar_rfi_url, get_project_details_url, BASE_DOCUMENT } from "../../config";
import { toast } from "react-toastify";
import MultipleFileUploader from "../../Pages/MultipleFileUploader";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";
import LoaderComponent from "../../utlis/LoderComponent";



const KickoutRegistrarDocs = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size


    const [registrarRFIDateTo, setRegistrarRFIDateTo] = useState(null)
    const [registrarRFIRemark, setRegistrarRFIRemark] = useState(null)
    const [status, setStatus] = useState('')

    const [fvrName, setFVRName] = useState(null)
    const [fvr, setFvr] = useState("")
    const [pddName, setPDDName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setERName] = useState(null);
    const [er, setER] = useState("");
    const [verificationDeedName, setVerificationDeedName] = useState(null);
    const [verificationDeed, setVerificationDeed] = useState("");
    const [validationDeedName, setValidationDeedName] = useState(null);
    const [validationDeed, setValidationDeed] = useState("");

    const [rfiReport, setRfiReport] = useState(null);
    const [rfi, setRFI] = useState(null);

    const [newRegistrarDocName, setNewRegistrarDocName] = useState(null)
    const [newRegistrarDoc, setNewRegistrarDoc] = useState('')

    const [newValidationDeedName, setNewValidationDeedName] = useState(null);
    const [newValidationDeed, setNewValidationDeed] = useState('');

    const [newVarificationDeedName, setNewVarificationDeedName] = useState(null);
    const [newVarificationDeed, setNewVarificationDeed] = useState('');

    const [newOtherDocName, setNewOtherDocName] = useState([]);
    const [newOtherDoc, setNewOtherDoc] = useState([]);
    const [kickoutId, setKickoutId] = useState('');



    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file3, setFile3] = useState('');
    const [file4, setFile4] = useState([]);




    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);





    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${ids}`,
                API_HEADER
            );
            const { record } = response.data;

            setStatus(record.status)

            // setKickoutId(kickout.id)


            setKickoutId(record.kickout_id)


            let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.fvr_report}`;
            setFVRName(url1);
            setFvr(record.fvr_report);

            let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
            setERName(url2);
            setER(record.tr_er_doc);


            let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
            setPDDName(url3);
            setPdd(record.tr_pdd_doc);

            let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
            setVerificationDeedName(url4);
            setVerificationDeed(record.verification_deed);


            let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
            setValidationDeedName(url5);
            setValidationDeed(record.validation_deed);

            let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
            setRfiReport(url6);
            setRFI(record.rfi_report);

            let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
            setNewVarificationDeedName(url7);
            setNewVarificationDeed(record.verification_deed);

            let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
            setNewValidationDeedName(url8);
            setNewValidationDeed(record.validation_deed);

            let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
            setNewRegistrarDocName(url9);
            setNewRegistrarDoc(record.rfi_report);

            let url10 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_registrar_docs}`;
            setNewOtherDocName(url10);
            setNewOtherDoc(record.rfi_registrar_docs);

        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
    }, [id]);

    const getCurrentDate = () => {
        const today = new Date();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        const year = today.getFullYear();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    };

    const handleToDateChange = (e) => {
        const value = e.target.value;
        setRegistrarRFIDateTo(value)
    };

    const handleRegistrarRFIRemark = (content) => {
        setRegistrarRFIRemark(content);
    }

    const CONFIG_Token2 = {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setPercent(percentCompleted);
        }
      };

    const handleRegistrarRFISubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(false);


        if ((registrarRFIRemark == null || registrarRFIRemark == '<p><br></p>' || registrarRFIRemark == '') || (file1 == null || file1 == "")) {
            toast.error('Please upload the document and remarks.')
        }
        else {

            try {
                setIsSubmitting(true);

                let formData = new FormData();
                formData.append("project_id", id);
                formData.append("rfi_registrar_remarks", registrarRFIRemark);
                formData.append("rfi_registrar_uploading_date", registrarRFIDateTo);
                formData.append("rfi_report", file1);
                formData.append("kickout_id", id);
                // formData.append("validation_deed", file2);
                // formData.append("vrification_deed", file3);
                formData.append('kickout_id', kickoutId)

                for (let i = 0; i < file4.length; i++) {
                    formData.append("rfi_registrar_docs[]", file4[i]);
                }

                const response = await axios.post(
                    kickout_registrar_rfi_url,
                    formData,
                    CONFIG_Token2
                );

                if (!response.data.status) {
                    toast.error(response.data.message);
                } else {
                    toast.success("Form Submitted Successfully");
                    navigate("/dashboard");
                }

            } catch (error) {
                console.error("Error occurred while submitting data:", error);
                toast.error("Error occurred while submitting data");
            }
            finally {
                // setLoading(false); // Hide loader
                setIsSubmitting(false);

            }
        }
    };



    const handleNewOtherDocChange = (file) => {
        setFile4(file);
    };

    return (
        <>      
        <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

            <form onSubmit={handleRegistrarRFISubmit}>
                <div>

                    <div className="row" style={{ marginTop: '20px' }} >
                        <div className="col-3 mb-3 ">
                            <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                                RFI/RFR Submission Date<span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className="col-4 mb-3 ">
                            <input
                                type="date"
                                className={`form-control borderlightgreen`}
                                id="rfidate"
                                required
                                name="rfidate"
                                onChange={handleToDateChange}
                                min={getCurrentDate()}
                            />
                        </div>
                    </div>


                    <>
                        <div className="row my-4" >
                            <div className="col-6"  >
                                <label className="form-label lightgreen fs-6">RFI Report
                                    <span style={{ color: "red" }}>*</span>
                                </label>


                                <FileUploaderComponent file={file1} setFile={setFile1} />
                            </div>

                            {/* <div className="col-6" >
                                <label className="form-label lightgreen fs-6">Validation Deed
                                </label>

                                <FileUploaderComponent file={file2} setFile={setFile2} />

                            </div> */}

<div className="col-6">
                                <label className="form-label lightgreen fs-6">Other Docs
                                </label>


                                <MultipleFileUploader
                                    dynamicMaxSize={dynamicMaxSize}
                                    onFilesChange={handleNewOtherDocChange}
                                />

                            </div>

                        </div>


                        {/* <div className="row">
                            <div className="col-6" >
                                <label className="form-label lightgreen fs-6">Verification Deed
                                </label>

                                <FileUploaderComponent file={file3} setFile={setFile3} />

                            </div> 

                                <label className="form-label lightgreen fs-6">Other Docs
                                </label>


                                <MultipleFileUploader
                                    dynamicMaxSize={dynamicMaxSize}
                                    onFilesChange={handleNewOtherDocChange}
                                />

                            </div> 
                        </div> */}
                    </>

                    <div >
                        <p className="lightgreen fw-bold  fs-6 my-4">
                            Registrar Remarks <span style={{ color: "red" }}>*</span>
                        </p>
                        <hr className="mb-3 lightgreen" />
                        <div>
                            <ReactQuill
                                theme="snow"
                                value={registrarRFIRemark}
                                name="remarks"
                                onChange={handleRegistrarRFIRemark}
                                style={{
                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>


                    <div className="col-12 d-flex justify-content-end gap-3 pr-0">
                        <button
                            type="submit"
                            className="btn btn-success"
                        >
                            Submit
                        </button>
                    </div>

                </div>
            </form>
        </>
    )
}

export default KickoutRegistrarDocs
