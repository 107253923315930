export const base_url="https://earthood.intileotech.xyz/api";
export const user_api_url="https://earthood.intileotech.xyz/api/user";
export const client_api_url=`${base_url}/client`;
export const BASE_DOCUMENT="https://earthood.intileotech.xyz/uploads";
   
export const login_url=`${user_api_url}/login`;
export const autologin_url=`${user_api_url}/AutoLogin`;

export const getDashboardData = `${user_api_url}/getDashboardData`;
export const getAllClients = `${user_api_url}/getAllClients`;
export const getAllProposals = `${user_api_url}/getAllProposals`;
export const getAllProjects = `${user_api_url}/getAllProjects`;
export const getAllProjectsDashboard = `${user_api_url}/projectdashboard/getProjectDetails`;

export const getAllProjectReports = `${user_api_url}/getAllProjectReports`;

export const getAllProposalDetails = `${user_api_url}/getAllProposalDetails`;

export const getCountryList = `${user_api_url}/getCountryList`;
export const getNotificationList = `${user_api_url}/getNotificationList`;
export const markNotificationAsRead = `${user_api_url}/markNotificationAsRead`;

export const saveClient = `${user_api_url}/saveClient`;
export const getClientDetails = `${user_api_url}/getClientDetails`;
export const pt_forgotpassword_url = `${user_api_url}/forgot_password`;
export const pt_forgotpassword_verify_url = `${user_api_url}/forgot_password_verify`;
export const pt_proposal_team_url = `${user_api_url}/proposalteam/submitproposalInfo`;
export const pt_proposal_submit_url = `${user_api_url}/proposalteam/submitproposalDocument`;
export const get_client_name_url = `${user_api_url}/get_clients`;
export const get_scope_url = `${user_api_url}/get_sectors`;
export const get_sectoralscope_url = `${user_api_url}/get_scopes`;
export const get_program_url = `${user_api_url}/get_programs`;
export const get_scope_pa_poa_url = `${user_api_url}/get_scope_pa`;
export const get_meths_url = `${user_api_url}/get_meths`;
export const get_ta_tr_experts = `${user_api_url}/get_taexperts`;
export const update_project_details_url = `${user_api_url}/updateProjectDetails`;
export const tm_support_remotesitevisit_url =  `${user_api_url}/tmSupportRemote`;
export const tl_remotesitevisit_url =  `${user_api_url}/requestRemoteVisit`;
export const get_remote_sitevisit_reasons_url = `${user_api_url}/get_remote_visit_reasons`;
export const get_contact_person_url = `${user_api_url}/get_clientcontacts`;
export const get_sales_person_url = `${user_api_url}/get_saleslists`;
export const get_country_url = `${user_api_url}/getCountryList`;
export const get_assesment_url = `${user_api_url}/get_atlists`;
export const get_trsbu_url = `${user_api_url}/get_trsbu`;
export const sales_update_proposal_url=`${user_api_url}/updateProposalInfo`;
export const get_proposal_detail_url = `${user_api_url}/getProposalDetails`;
export const get_all_propoposal_url = `${user_api_url}/getAllProposals`;
export const pt_tm_proposalaction_url = `${user_api_url}/tm_proposalaction`;
export const tms_proposalaction_url = `${user_api_url}/teamSupportProposal`;
export const mail_reminder_url = `${user_api_url}/reminder_settings`;
export const get_regions_url = `${user_api_url}/get_regions`;
export const get_pt_forwardToSales_url = `${user_api_url}/proposalteam/sentToSales`;
export const get_sales_action_url = `${user_api_url}/sales_action`;
export const proposal_summary_url = `${user_api_url}/getProposalSummary`;
export const at_tl_data_url = `${user_api_url}/getAllProjects`;
export const get_project_details_url =`${user_api_url}/getProjectDetails`; 
export const client_doc_url =  `${base_url}/client/clientDocSend`;
export const at_desk_review_url =`${user_api_url}/deskReview`;
export const submit_Finding_url =   `${user_api_url}/submitFinding`;
export const get_project_activity_url =`${user_api_url}/getProjectActivity`; 
export const at_site_visit_url =`${user_api_url}/siteVisitRequest`; 
export const site_visit_completed =`${user_api_url}/completeSiteVisit`; 
export const finding_file_url =`${user_api_url}/submitDraftFinding`;
export const sbu_action_url =`${user_api_url}/sbu_action`;
export const site_finding_url =`${user_api_url}/submitSiteFinding`;
export const dvr_report_url =`${user_api_url}/uploadDVRReport`;
export const at_trround_url =`${user_api_url}/atTRReview`;
export const tr_action_url =`${user_api_url}/tr_action`;
export const edit_assessment_team_url =`${user_api_url}/changeTeam`;
export const tl_ccreview_url =`${user_api_url}/atccReview`;
export const get_list_of_users_sbu_cc_url =`${user_api_url}/get_cclists`;
export const sbu_cc_action_url =`${user_api_url}/actionCCReview`;
export const tl_rfi_url =`${user_api_url}/tlSubmitRFI`;
export const tm_rfi_url =`${user_api_url}/tmActionRFI`;
export const tm_kickout_rfi_url =`${user_api_url}/kickout/tmActionRFI`;
export const kickout_registrar_rfi_url =`${user_api_url}/kickout/registrarCompleteRFI`;

export const md_action_url = `${user_api_url}/mdActionRFI`;
export const md_kickout_action_url = `${user_api_url}/kickout/mdActionRFI`;
export const getKickoutTracking =`${user_api_url}/kickout/getKickoutTracking`;

export const dasboard_counter_url = `${user_api_url}/getDashboardCounters`;
export const commonproject_graphs_url = `${user_api_url}/project_graphs`;
export const scopewiseproject_graphs_url = `${user_api_url}/project_scope_graphs`;
export const projectstages_graphs_url = `${user_api_url}/project_stage_wise_graphs`;
export const workload_graphs_url = `${user_api_url}/project_workload_graphs`;
export const contractsigned_monthly_graphs_url = `${user_api_url}/contract_signed_monthly`;
export const project_delivered_monthly_url = `${user_api_url}/project_delivered_monthly`;
export const target_analysis_url = `${user_api_url}/target_monthly_projects`;
export const country_map_url = `${user_api_url}/get_project_country_map`;
export const get_dealLostReasons_url = `${user_api_url}/get_deal_lost_reasons`;
export const get_kickout_url = `${user_api_url}/kickout/getAllProjects`;
export const Registrar_kickoutAction_url = `${user_api_url}/kickout/registrarAction`;
export const Registrar_RFI_kickoutAction_url = `${user_api_url}/kickout/registrarActionRFI`;

export const TL_kickout_url = `${user_api_url}/kickout/tlSubmitDocs`;
export const KickoutDocuments_url = `${user_api_url}/kickout/getKickoutDocuments`;
export const at_allprojects_url = `${user_api_url}/getMyProjects`;
export const get_taexpert_projects = `${user_api_url}/getTAExpertProjects`;

export const get_project_stages_url = `${user_api_url}/get_project_stages`;
export const get_all_priority = `${user_api_url}/get_priority`;
export const get_proposal_entity  = `${user_api_url}/get_entity`;
export const team_change_url  = `${user_api_url}/changeTeamRequest`;
export const tm_team_change_url  = `${user_api_url}/tmActionTeam`;
export const get_project_team_info  = `${user_api_url}/getProjectTeamInfo`;
export const registrar_rfi_url  = `${user_api_url}/registrarActionRFI`;
export const get_KickoutInfo_url  = `${user_api_url}/kickout/getKickoutInfo`;
export const getKickoutDetails  = `${user_api_url}/kickout/getKickoutDetails`;

export const tldocs_Kickout_url  = `${user_api_url}/kickout/tlSubmitDocs`;
export const clientdocs_Kickout_url  = `${user_api_url}/kickout/clientSubmitDocs`;
export const Sbu_kickoutAction_url = `${user_api_url}/kickout/sbuAction`;
export const tlTRRound_Kickout_url  = `${user_api_url}/kickout/trRoundTeam`;
export const ClientTRRound_Kickout_url  = `${user_api_url}/kickout/clientTRRound`;
export const TRRound_Kickout_url  = `${user_api_url}/kickout/trReview`;
export const tr_cc_action_kickout_url =`${user_api_url}/kickout/actionCCReview`;
export const client_cc_action_kickout_url =`${user_api_url}/kickout/clientCCRound`;
export const tl_ccreview_kickout_url =`${user_api_url}/kickout/atccReview`;
export const tl_rfi_kickout_url =`${user_api_url}/kickout/tlSubmitRFI`;
export const tm_rfi_kickout_url =`${user_api_url}/kickout/tmActionRFI`;
export const md_rfi_kickout_url =`${user_api_url}/kickout/mdActionRFI`;
export const registrar_rfi_kickout_url =`${user_api_url}/kickout/registrarActionRFI`;
export const contract_check_url = `${base_url}/client/contractCheck`;
export const contract_upload_url = `${base_url}/client/contractUpload`;
export const clients_allprojects_data_url = `${client_api_url}/getAllProjects`;
export const client_finding_url =  `${client_api_url}/clientSubmitFinding`;
export const client_sitefinding_url =  `${client_api_url}/clientSiteFinding`;
export const client_tr_round_url =  `${client_api_url}/trRound`;
export const client_cc_round_url =  `${client_api_url}/clientCCRound`;
export const project_wise_project_url = `${user_api_url}/projectdashboard/program_wise_projects`;
export const project_stage_wise_url = `${user_api_url}/projectdashboard/project_stage_wise_graphs`;
export const workload_stage_wise_url = `${user_api_url}/projectdashboard/project_workload_graphs`;
export const region_wise_projects_url = `${user_api_url}/projectdashboard/region_wise_projects`;
export const contract_signed_monthly = `${user_api_url}/projectdashboard/contract_signed_monthly`;
export const kickout_monthly_wise = `${user_api_url}/projectdashboard/kickout_month_wise`;
export const project_moving_datewise_url = `${user_api_url}/projectdashboard/project_moving_datewise`;

export const team_change_doc_url = `${user_api_url}/prepareTeamChangeDocument`;
export const team_change_listing_url = `${user_api_url}/getTeamChangeListing`;
export const sbu_team_change_url = `${user_api_url}/SbuChangeTeam`;

export const client_project_wise_graph_url =  `${client_api_url}/projectdashboard/program_wise_projects`;
export const client_project_stage_wise_graph_url =  `${client_api_url}/projectdashboard/project_stage_wise_graphs`;
export const client_project_moving_wise_graph_url =  `${client_api_url}/projectdashboard/project_moving_datewise`;
export const client_contract_signed_graph_url =  `${client_api_url}/projectdashboard/contract_signed_vs_rfi_monthly`;



const API_HEADER2 = {                                         
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + sessionStorage.getItem("token")
    }
}

export const API_HEADER=API_HEADER2;
