import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Tabs,
  Select,
  Upload,
  message,
  Input,
  Alert,
  Spin,
  Modal,
} from "antd";
import axios from "axios";
import { Tooltip } from 'antd';
import { API_HEADER, BASE_DOCUMENT, get_client_name_url } from "../config";
import { get_scope_url } from "../config";
import { get_sectoralscope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_assesment_url } from "../config";
import { toast } from "react-toastify";
import Header from "./Header";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import {
  get_project_details_url,
  edit_assessment_team_url,
  get_scope_pa_poa_url,
  get_KickoutInfo_url, get_ta_tr_experts, get_meths_url
} from "../config";
import { get_trsbu_url } from "../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { at_desk_review_url } from "../config";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ActivityChain from "../Components/ActivityChain";
import { MultiSelect } from "react-multi-select-component";
import KickOutDocs from "../Components/kickout/KickOutDocs";
import RegistrarKickout from "../Components/kickout/RegistrarKickout";
import SbuKickout from "../Components/kickout/SbuKickout";
import TLKickout from "../Components/kickout/TLKickout";
import ATKickout from "../Components/kickout/ATkickout";
import ClientKickout from "../Components/kickout/ClientKickout";
import TLKickoutTRRound from "../Components/kickout/KickoutTRRound/TLKickoutTRRound";
import TRKickoutTRRound from "../Components/kickout/KickoutTRRound/TRKickoutTRRound";
import ATKickoutTRRound from "../Components/kickout/KickoutTRRound/ATKickoutTRRound";
import ClientKickoutTRRound from "../Components/kickout/KickoutTRRound/ClientKickoutTRRound";
import TLKickoutCCRound from "../Components/kickout/KickoutCCRound/TLKickoutCCRound";
import TRKickoutCCRound from "../Components/kickout/KickoutCCRound/TRKickoutCCRound";
import ClientKickoutCCRound from "../Components/kickout/KickoutCCRound/ClientKickoutCCRound";
import SBUKickoutCCRound from "../Components/kickout/KickoutCCRound/SBUKickoutCCRound";
import CCKickoutCCRound from "../Components/kickout/KickoutCCRound/CCKickoutCCRound";
import TLKickoutRFIRound from "../Components/kickout/KickoutRFIRound/TLKickoutRFIRound";
import TMKickoutRFIRound from "../Components/kickout/KickoutRFIRound/TMKickoutRFIRound";
import MDKickoutRFIRound from "../Components/kickout/KickoutRFIRound/MDKickoutRFIRound";
import ATKickoutCCRound from "../Components/kickout/KickoutCCRound/ATKickoutCCRound";
import ATKickoutRFIRound from "../Components/kickout/KickoutRFIRound/ATKickoutRFIRound";
import RegistrarKickoutRFIRound from "../Components/kickout/KickoutRFIRound/RegistrarKickoutRFIRound";
import KickoutFinalAction from "../Components/kickout/KickoutFinalAction";
import KickoutRegistrarDocs from "../Components/kickout/KickoutRegistrarDocs";
import ProjectTeamChangeSummary from "../Components/TeamChange/ProjectTeamChangeSummary";


const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const KickOutModule = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const designation_id = sessionStorage.getItem("designation_id");
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const cc_id = userdata.id;

  const [projectid, setProjectId] = useState(null);
  const [clientName, setClientName] = useState([]);
  const [sectoralScope, setSectoralScope] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setformSubmitting] = useState(false);

  const [myscope, setMyScope] = useState([]);
  const [program, setProgram] = useState([]);
  const [country, setCountry] = useState([]);
  const [atlist, setAtList] = useState([]);
  const [f23name, setF23Name] = useState(null);
  const [f23, setF23] = useState("");
  const [rowCount, setRowCount] = useState(1);
  const [formEdit, setformEdit] = useState(true);
  const [trlist, setTRList] = useState([]);
  const [remark, setRemark] = useState("");
  const [fileTLSizeError, setFileTLSizeError] = useState(false);
  const [file, setFile] = useState(null);
  const [formdisable, setFormDisable] = useState(false);

  const [scope_PA_POA, setScope_PA_POA] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [isTL, setIsTL] = useState(false);
  const [isAT, setIsAT] = useState(false);
  const [isCC, setIsCC] = useState(false);
  const [member, setMember] = useState();
  const [projectstatus, setProjectStatus] = useState(null);
  const [status, SetStatus] = useState(null);
  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [role, setRole] = useState("");
  const [mysectorscope, setMySectorScope] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [selected, setSelected] = useState([]);

  const [kickoutStatus, setKickoutStatus] = useState('')
  const [teamLeader, setTeamLeader] = useState("");
  const [taExpert, setTAExpert] = useState("");
  const [validator, setValidator] = useState("");
  const [financeExpert, setFinanceExpert] = useState("");
  const [localExpert, setLocalExpert] = useState("");
  const [methExpert, setMethExpert] = useState("");
  const [traineeExpert, setTraineeExpert] = useState("");

  const [meth, setMeth] = useState([])
  const [taTrExpert, setTATRExpert] = useState([]);
  const [mytaexpert, setMyTaexpert] = useState([]);
  const [myexperttr, setMyExpertTr] = useState([]);
  const [trExpert, setTRExpert] = useState([]);
  const [apiData, setAPIData] = useState('')

  const [rfiReport, setRfiReport] = useState("");
  const [rfiReportDoc, setRfiReportDoc] = useState(null);

  const [newRegistrarDocName, setNewRegistrarDocName] = useState(null)
  const [newRegistrarDoc, setNewRegistrarDoc] = useState('')

  const [newValidationDeedName, setNewValidationDeedName] = useState(null);
  const [newValidationDeed, setNewValidationDeed] = useState('');

  const [newVarificationDeedName, setNewVarificationDeedName] = useState(null);
  const [newVarificationDeed, setNewVarificationDeed] = useState('');

  const [newOtherDocName, setNewOtherDocName] = useState(null);
  const [newOtherDoc, setNewOtherDoc] = useState([]);


  useEffect(() => {
    setMember(isTL ? 4 : 3);
  }, [isTL]);



  const [formData, setFormData] = useState({
    project_name: "",
    project_status: "",
    earthood_id: "",
    client_id: "",
    country: "",
    program: "",
    program_id: "",
    implemented_fees: "",
    created_at: "",
    scope: [],
    scope_pa: "",
    sectoral_scope: [],
    team_leader: "",
    ta_expert: [],
    validator_verifier: "",
    finance_expert: "",
    local_expert: "",
    meth: '',
    meth_expert: "",
    trainee_validator: "",
    technical_reviewer: "",
    expert_tr: [],
    sbu_head: "",
    pdd_documents: "",
    er_documents: "",
    mr_documents: "",
    evidence_name: [],
  });

  const fetchData = async (ids) => {
    try {

      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );

      if (response && response.data && response.data.record) {
        const { record } = response.data;

        setProjectId(record.id);
        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record.program}`
        );

        const isTeamLeader = record.team.some((item) => item.role_id == 1);
        setIsTL(isTeamLeader);

        const isTeamMember = record.team.some((item) => item.role_id > 1);
        setIsAT(isTeamMember);

        if (designation_id == 8) {
          if (isTeamLeader) {
            setRole("Team Leader");
          } else {
            setRole("Team Member");
          }
        }



        setRowCount(record.evidences ? record.evidences.length : 1);
        setProjectStatus(record.project_status);
        setKickoutStatus(record.kickout_status);
        SetStatus(record.status);
        setFormDisable(true);
        setformEdit(true)

        setAtList(responseteamleader.data.data);
        setAPIData(record)
        setTeamLeader(record.team_leader);
        setTAExpert(record.ta_expert);
        setValidator(record.validator_verifier);
        setFinanceExpert(record.finance_expert);
        setLocalExpert(record.local_expert);
        setMethExpert(record.meth_expert);
        setTraineeExpert(record.trainee_validator);

        setFormData({
          project_name: record.project_name,
          project_status: record.project_status,
          earthood_id: record.earthood_id,
          client_id: record.client_id,
          country: record.country,
          program: record.program,
          program_id: record.program_id,
          implemented_fees: record.implemented_fees,
          scope: record.scope,
          scope_pa: record.scope_pa,
          sectoral_scope: selected,
          evidence_name: record.evidences || [],
          team_leader: record.team_leader,
          ta_expert: record.ta_expert,
          validator_verifier: record.validator_verifier,
          finance_expert: record.finance_expert,
          local_expert: record.local_expert,
          meth: record.meth,
          meth_expert: record.meth_expert,
          trainee_validator: record.trainee_validator,
          technical_reviewer: record.technical_reviewer,
          expert_tr: record.expert_tr,
          sbu_head: record.sbu_head,
          status: record.status,
        });

        let url3 = `${BASE_DOCUMENT}/documents/${record.earthood_id.split(" ")[0]
          }/${record.signed_contract}`;

        setF23Name(url3);
        setF23(record.signed_contract);

        let url10 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
        setRfiReportDoc(url10);
        setRfiReport(record.rfi_report);

        let url17 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
        setNewVarificationDeedName(url17);
        setNewVarificationDeed(record.verification_deed);

        let url18 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
        setNewValidationDeedName(url18);
        setNewValidationDeed(record.validation_deed);

        let url19 = `${BASE_DOCUMENT}/projects/${record.id}/${record.rfi_report}`;
        setNewRegistrarDocName(url19);
        setNewRegistrarDoc(record.rfi_report);

        let otherDocsUrls = record.rfi_registrar_docs.split(',').map(doc => `${BASE_DOCUMENT}/projects/${record.id}/${doc.trim()}`);

        let otherDocsNames = record.rfi_registrar_docs.split(',');

        setNewOtherDocName(otherDocsUrls);
        setNewOtherDoc(otherDocsNames);

      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
    fetchDataKickout(id);
  }, [id, myscope]);

  const combined = newOtherDoc?.map((name, index) => ({
    name: name,
    url: newOtherDocName[index]
  }));

  useEffect(() => {
    if (apiData) {
      const sectorscopes = apiData.sectoral_scope.split(",").map(Number);
      const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
        sectorscopes.includes(sectorscope.value)
      );
      setSelectedSector(filteredSectorScopes);
    }
  }, [apiData, mysectorscope])

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  const fetchDataKickout = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );

      const { kickout } = response.data;

      if (kickout.cc_id == cc_id) {
        setIsCC(true);
      } else {
        setIsCC(false);
      }

    } catch (error) { }
  };


  useEffect(() => {
    const fetchDataMeth = async () => {
      try {
        const responsemeths = await axios.get(`${get_meths_url}`);
        setMeth(responsemeths.data.data);
      } catch (error) { }
    };

    fetchDataMeth();
  }, []);

  useEffect(() => {
    const fetchDataClientName = async () => {
      try {
        const responseclientname = await axios.get(`${get_client_name_url}`);
        setClientName(responseclientname.data.data);
      } catch (error) { }
    };

    fetchDataClientName();
  }, []);

  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(
          `${get_sectoralscope_url}`
        );

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );

      } catch (error) { }
    };

    fetchSectoralScope();
  }, []);

  useEffect(() => {
    const fetchDataScope = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_url}`);
        setMyScope(responsescope.data.data);
      } catch (error) { }
    };

    fetchDataScope();
  }, []);

  useEffect(() => {
    const fetchDataProgram = async () => {
      try {
        const responseprogram = await axios.get(`${get_program_url}`);
        setProgram(responseprogram.data.data);
      } catch (error) { }
    };

    fetchDataProgram();
  }, []);

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) { }
    };

    fetchDataSope_PA_POA();
  }, []);

  useEffect(() => {
    const fetchDataCountry = async () => {
      try {
        const responsecountry = await axios.get(`${get_country_url}`);
        setCountry(responsecountry.data.data);
      } catch (error) { }
    };

    fetchDataCountry();
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}`);
        setTRList(responsetrdata.data.data);
      } catch (error) { }
    };

    fetchDataTrData();
  }, []);

  useEffect(() => {
    const fetchDataTATRExpert = async () => {
      try {
        const responsetatrexpert = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=7`
        );
        setTATRExpert(responsetatrexpert.data.data.map((taexpert) => ({
          value: taexpert.id,
          label: taexpert.name,
        }))
        )

      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTATRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && taTrExpert.length > 0) {
      const taExpert = apiData.ta_expert?.split(",").map(Number);
      const filteredTaExpert = taTrExpert.filter((taexpert) =>
        taExpert.includes(taexpert.value)
      );
      setMyTaexpert(filteredTaExpert);
    }
  }, [apiData, taTrExpert]);


  useEffect(() => {
    const fetchDataTRExpert = async () => {
      try {
        const responsetatrexperts = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=8`
        );

        setTRExpert(responsetatrexperts.data.data.map((trexpert) => ({
          value: trexpert.id,
          label: trexpert.name,
        }))
        )
      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && trExpert.length > 0) {

      const trExperts = apiData.expert_tr?.split(",").map(Number);

      const filteredTrExpert = trExpert.filter((taexpert) =>
        trExperts.includes(taexpert.value)
      );

      setMyExpertTr(filteredTrExpert);
    }
  }, [apiData, trExpert]);

  const handleTeamChange = () => {
    navigate(`/teamchangemodule/${id}`)
  }

  // console.log("newOtherDoc", newOtherDoc)
  // console.log("newValidationDeed",newValidationDeed)



  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row pb-5 ">
              <div className="col-12 ">
                <div className="d-flex justify-content-between">
                  <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                    Project Details
                  </h4>

                  <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                    {role}
                  </h4>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  className="mx-5"
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  indicator={{ Backgroundcolor: "#07B6AF" }}
                  size="large"
                >

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 1 ? "box_sh" : ""
                          }`}
                      >
                        <p>Project Details</p>
                      </div>
                    }
                    key="1"
                  >
                    <form>
                      <div className="col-12 border-0 py-2 mx-auto">
                        <fieldset disabled={formEdit}>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="projectname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Tooltip title={formData.project_name} placement="top">
                                <input
                                  type="text"
                                  className="form-control borderlightgreen"
                                  id="project_name"
                                  placeholder="Project Name"
                                  required
                                  name="project_name"
                                  readOnly={true}
                                  value={formData.project_name}
                                />
                              </Tooltip>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="eid"
                                className="form-label lightgreen fs-6"
                              >
                                Earthood Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control borderlightgreen"
                                id="earthood_id"
                                placeholder="Earthood Id"
                                name="earthood_id"
                                required
                                readOnly={true}
                                value={formData.earthood_id}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Client Name
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="client_id"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="client_id"
                                value={formData.client_id}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {clientName.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="country"
                                className="form-label lightgreen fs-6"
                                required
                              >
                                Project Country<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="country"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="country"
                                value={formData.country}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {country.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program"
                                className="form-label lightgreen fs-6"
                              >
                                Program<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="program"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="program"
                                value={formData.program}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {program.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.program_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program Id"
                                className="form-label lightgreen fs-6"
                              >
                                Program Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="program_id"
                                placeholder="Program Id"
                                required
                                name="program_id"
                                className="form-control borderlightgreen"
                                value={formData.program_id}
                                readOnly={true}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Implementation Fees"
                                className="form-label lightgreen fs-6"
                              >
                                Implementation Fees
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control borderlightgreen"
                                id="implemented_fees"
                                placeholder="Implementation Fees"
                                required
                                name="implemented_fees"
                                value={formData.implemented_fees}
                                readOnly={true}
                              />
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope(PO/POA)"
                                className="form-label lightgreen fs-6"
                              >
                                Scope(PA/POA)
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="scope_pa"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope_pa"
                                value={formData.scope_pa}
                                readOnly={true}
                              >
                                <option value={""}>Select</option>
                                {scope_PA_POA.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.scope}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Sectoral Scope
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelect
                                options={mysectorscope}
                                value={selectedSector}
                                onChange={setSelectedSector}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />
                            </div>
                          </div>




                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Scope<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="scope"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope"
                                value={formData.scope}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {myscope.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.sector_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="SBU Head"
                                  className="form-label lightgreen fs-6"
                                >
                                  SBU Head
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="sbu_head"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="sbu_head"
                                  required
                                  value={formData.sbu_head}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      sbu_head: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {trlist
                                    .filter(
                                      (option) => option.designation_id == 9
                                    )
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Meth"
                                className="form-label lightgreen fs-6"
                              >
                                Meth

                              </label>
                              <select
                                id="meth"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth"
                                value={formData.meth}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    meth: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {meth.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Team Leader
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="team_leader"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="team_leader"
                                required
                                value={formData.team_leader}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id === 1)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Service Agreement
                              </label>
                              <div>
                                <a href={f23name}>{f23}</a>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                RFI Report
                              </label>
                              <div>
                                <a target="_blank" href={rfiReportDoc}>
                                  {rfiReport}
                                </a>
                              </div>
                            </div>

                            {formData.project_status >= 10 ?
                              <>
                                {/* <div className="col-lg-4 col-md-4 col-12 mb-3">
                                  <label
                                    htmlFor="taexpert"
                                    className="form-label lightgreen fs-6"
                                  >
                                    RFI Report
                                  </label>
                                  <div>
                                    <a target="_blank" href={newRegistrarDocName}>
                                      {newRegistrarDoc}
                                    </a>
                                  </div>
                                </div> */}


                                <div className="row">
                                  {newValidationDeed &&
                                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                                      <label
                                        htmlFor="taexpert"
                                        className="form-label lightgreen fs-6"
                                      >
                                        Validation Deed
                                      </label>
                                      <div>
                                        <a target="_blank" href={newValidationDeedName}>
                                          {newValidationDeed}
                                        </a>
                                      </div>
                                    </div>
                                  }

                                  {newVarificationDeed &&
                                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                                      <label
                                        htmlFor="taexpert"
                                        className="form-label lightgreen fs-6"
                                      >
                                        Verification Deed
                                      </label>
                                      <div>
                                        <a target="_blank" href={newVarificationDeedName}>
                                          {newVarificationDeed}
                                        </a>
                                      </div>
                                    </div>
                                  }

                                  {(newOtherDoc.length > 0) &&
                                    <div className="col-lg-4 col-md-4 col-12 mb-3">
                                      <label
                                        htmlFor="taexpert"
                                        className="form-label lightgreen fs-6"
                                      >
                                        Other Docs
                                      </label>
                                      <div>
                                        {/* <a target="_blank" href={newOtherDocName}>
                                        {newOtherDoc}
                                      </a> */}

                                        {combined.map((item) => (
                                          <div>
                                            <a target="_blank" href={item.url}>
                                              {item.name}

                                            </a>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  }

                                </div>
                              </>

                              : ''}

                          </div>
                        </fieldset>
                      </div>


                      {designation_id != 13 ?
                        <div>
                          <div className="d-flex justify-content-between  mt-5 mb-3">
                            <p className="textlightgreen fw-bold fs-6">
                              Assessment Team
                            </p>



                            {isTL || designation_id == 9 ? (
                              <button type="button" class="btn btn-outline-success"
                                // onClick={() => setIsModalOpen(true)}
                                onClick={handleTeamChange}
                              >
                                Change Team
                              </button>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* <hr className="mb-5 lightgreen" /> */}
                          <fieldset disabled={true}>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="teamleader"
                                  className="form-label lightgreen fs-6"
                                >
                                  Team Leader
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="team_leader"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="team_leader"
                                  required
                                  value={formData.team_leader}
                                  readOnly={true}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {atlist
                                    .filter((option) => option.skill_id == 1)
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="taexpert"
                                  className="form-label lightgreen fs-6"
                                >
                                  TA Expert
                                  <span style={{ color: "red" }}>*</span>
                                </label>



                                <MultiSelect
                                  options={taTrExpert}
                                  value={mytaexpert}
                                  onChange={setMyTaexpert}
                                  labelledBy="Select"
                                  className={formEdit ? 'multi' : ''}
                                />


                              </div>

                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="teamleader"
                                  className="form-label lightgreen fs-6"
                                >
                                  Local Expert
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="local_expert"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="local_expert"
                                  value={formData.local_expert}
                                  readOnly={true}
                                  required
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {atlist
                                    .filter((option) => option.skill_id == 6)
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="financeexpert"
                                  className="form-label lightgreen fs-6"
                                >
                                  Finance Expert
                                </label>

                                <select
                                  id="finance_expert"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="finance_expert"
                                  value={formData.finance_expert}
                                  readOnly={true}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {atlist
                                    .filter((option) => option.skill_id == 4)
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="validator"
                                  className="form-label lightgreen fs-6"
                                >
                                  Validator/verifier
                                </label>

                                <select
                                  id="validator_verifier"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="validator_verifier"
                                  value={formData.validator_verifier}
                                  readOnly={true}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {atlist
                                    .filter((option) => option.skill_id == 2)
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="methexpert"
                                  className="form-label lightgreen fs-6"
                                >
                                  Meth Expert
                                </label>

                                <select
                                  id="meth_expert"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="meth_expert"
                                  value={formData.meth_expert}
                                  readOnly={true}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {atlist
                                    .filter((option) => option.skill_id == 5)
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="taexpert"
                                  className="form-label lightgreen fs-6"
                                >
                                  Trainee Validator/verifier
                                </label>

                                <select
                                  id="trainee_validator"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="trainee_validator"
                                  value={formData.trainee_validator}
                                  readOnly={true}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {atlist
                                    .filter((option) => option.skill_id == 3)
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="technicalreviewer"
                                  className="form-label lightgreen fs-6"
                                >
                                  Technical Reviewer
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="technical_reviewer"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="technical_reviewer"
                                  required
                                  value={formData.technical_reviewer}
                                  readOnly={true}
                                >
                                  <option selected value={""}>
                                    Select
                                  </option>
                                  {trlist
                                    .filter(
                                      (option) => option.designation_id == 7
                                    )
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="col-lg-4 col-md-4 col-12 mb-3">
                                <label
                                  htmlFor="technicalreviewer"
                                  className="form-label lightgreen fs-6"
                                >
                                  Expert to Technical Reviewer
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <MultiSelect
                                  options={trExpert}
                                  value={myexperttr}
                                  onChange={setMyExpertTr}
                                  labelledBy="Select"
                                  className={formEdit ? 'multi' : ''}
                                />

                              </div>
                            </div>
                          </fieldset>
                        </div>
                        : ''}


                    </form>
                  </Tabs.TabPane>

                  {projectstatus > 1 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 2 ? "box_sh" : ""
                            }`}
                        >
                          <p>Project Documents</p>
                        </div>
                      }
                      key="2"
                    >
                      <KickOutDocs />
                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}

                  {isTL && projectstatus == 10 && (designation_id == 8) ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 4 ? "box_sh" : ""
                            }`}
                        >
                          <p>Registry Action</p>
                        </div>
                      }
                      key="4"
                    >
                      {designation_id == 8 ? (
                        <RegistrarKickout />
                      ) : ''
                      }
                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}

                  {projectstatus >= 11 && designation_id == 8 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={`textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 5 ? "box_sh" : ""
                            }`}
                        >
                          <p>KickOut</p>
                        </div>
                      }
                      key="5"
                    >
                      {
                        isTL ?

                          <TLKickout />

                          :

                          <ATKickout />

                      }


                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}

                  {formData.project_status >= 10 &&
                  designation_id == 13 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                        className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 6 ? "box_sh" : ""
                          }`}
                      >
                        <p >Kick out</p>
                      </div>
                      }
                      key="6"
                    >
                      <ClientKickout/>
                    </Tabs.TabPane>
                  ) : (
                    ""
                  )}


                  {formData.project_status >= 10 &&
                    designation_id == 8 && kickoutStatus >= 7 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 7 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out TR Round</p>
                        </div>
                      }
                      key="7"
                    >

                      {
                        isTL ?

                          <TLKickoutTRRound />

                          :

                          <ATKickoutTRRound />

                      }


                    </Tabs.TabPane>

                  ) : formData.project_status >= 10 &&
                    designation_id == 7 && kickoutStatus >= 6 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 7 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out TR Round</p>
                        </div>
                      }
                      key="7"
                    >
                      <TRKickoutTRRound />
                    </Tabs.TabPane>
                  )
                    : formData.project_status >= 10 &&
                      designation_id == 13 && kickoutStatus >= 7 ? (

                      <Tabs.TabPane
                        tab={
                          <div
                            className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 7 ? "box_sh" : ""
                              }`}
                          >
                            <p >Kick out TR Round</p>
                          </div>
                        }
                        key="7"
                      >
                        <ClientKickoutTRRound />
                      </Tabs.TabPane>

                    )
                      :
                      ''
                  }

                  {formData.project_status >= 10 && designation_id == 8 && kickoutStatus >= 13 && !isCC ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 8 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out CC Round</p>
                        </div>
                      }
                      key="8"
                    >

                      {
                        isTL ?

                          <TLKickoutCCRound />

                          :
                          !isTL ?
                            <ATKickoutCCRound />
                            :
                            ''

                      }


                    </Tabs.TabPane>

                  ) : formData.project_status >= 10 && designation_id == 7 && kickoutStatus > 13 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 8 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out CC Round</p>
                        </div>
                      }
                      key="8"
                    >
                      <TRKickoutCCRound />
                    </Tabs.TabPane>
                  )
                    : formData.project_status >= 10 && designation_id == 13 && kickoutStatus > 13 ? (

                      <Tabs.TabPane
                        tab={
                          <div
                            className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 8 ? "box_sh" : ""
                              }`}
                          >
                            <p >Kick out CC Round</p>
                          </div>
                        }
                        key="8"
                      >
                        <ClientKickoutCCRound />
                      </Tabs.TabPane>

                    )
                      :
                      formData.project_status >= 10 && designation_id == 8 && kickoutStatus >= 13 && isCC ? (

                        <Tabs.TabPane
                          tab={
                            <div
                              className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 8 ? "box_sh" : ""
                                }`}
                            >
                              <p >Kick out CC Round</p>
                            </div>
                          }
                          key="8"
                        >
                          <CCKickoutCCRound />
                        </Tabs.TabPane>

                      )
                        :
                        ''
                  }



                  {formData.project_status >= 10 && designation_id == 8 && kickoutStatus >= 24 ? (
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 9 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick out RFI Round</p>
                        </div>
                      }
                      key="9"
                    >

                      {
                        isTL ?

                          <TLKickoutRFIRound />

                          :

                          <ATKickoutRFIRound />

                      }


                    </Tabs.TabPane>
                  )

                    :
                    formData.project_status >= 10 && designation_id == 3 && kickoutStatus >= 24 ? (

                      <Tabs.TabPane
                        tab={
                          <div
                            className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 9 ? "box_sh" : ""
                              }`}
                          >
                            <p >Kick out RFI Round</p>
                          </div>
                        }
                        key="9"
                      >
                        <TMKickoutRFIRound />
                      </Tabs.TabPane>

                    )
                      :
                      formData.project_status >= 10 && designation_id == 2 && kickoutStatus >= 24 ? (

                        <Tabs.TabPane
                          tab={
                            <div
                              className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 9 ? "box_sh" : ""
                                }`}
                            >
                              <p >Kick out RFI Round</p>
                            </div>
                          }
                          key="9"
                        >
                          <MDKickoutRFIRound />
                        </Tabs.TabPane>

                      )
                        :
                        formData.project_status >= 10 && designation_id == 11 && kickoutStatus >= 24 ? (

                          <Tabs.TabPane
                            tab={
                              <div
                                className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 9 ? "box_sh" : ""
                                  }`}
                              >
                                <p >Kick out RFI Round</p>
                              </div>
                            }
                            key="9"
                          >
                            <RegistrarKickoutRFIRound />
                          </Tabs.TabPane>

                        )
                          :
                          ''
                  }

                  {formData.project_status >= 11 && designation_id == 11 && kickoutStatus == 31 ? (

                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 11 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kick Registrar Action</p>
                        </div>
                      }
                      key="11"
                    >
                      {/* <MDKickoutRFIRound /> */}
                      <KickoutRegistrarDocs />
                    </Tabs.TabPane>

                  )
                    : ''}

                  {formData.project_status >= 11 && designation_id == 8 && kickoutStatus >= 32 && isTL ? (

                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0  py-2 fs-6 text-center ${activeTab == 10 ? "box_sh" : ""
                            }`}
                        >
                          <p >Kickout Final Action</p>
                        </div>
                      }
                      key="10"
                    >
                      {/* <MDKickoutRFIRound /> */}
                      <KickoutFinalAction />
                    </Tabs.TabPane>

                  )
                    : ''}

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${activeTab == 3 ? "box_sh" : ""
                          }`}
                      >
                        <p>Project Summary</p>
                      </div>
                    }
                    key="3"
                  >
                    <ActivityChain id={projectid} />
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 text-center ${activeTab == 13 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Team Change Summary</p>
                      </div>
                    }
                    key="13"
                  >
                    <div className="col-12 border-0 bg-white ">
                      <ProjectTeamChangeSummary id={projectid} />
                    </div>
                  </Tabs.TabPane>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default KickOutModule;
