import { Input, Table, Tabs, Tag, DatePicker, Select, Button, Tooltip as Tip } from "antd";
import React, { useEffect, useState } from "react";
import moment from 'moment';
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  API_HEADER,
  getDashboardData,
  getAllClients,
  getAllProposals,
  getCountryList,
  get_client_name_url,
  get_regions_url,
  get_scope_url,
  get_program_url,
  get_proposal_detail_url, 
  get_sectoralscope_url
} from "../../config";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  faFileCircleQuestion,
  faFileCircleCheck,
  faFileArrowDown,
  faUser,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LegendTable from "../LegendTable";
import ProposalNameWithDelay from "../Misc/ProposalNameWithDelay";
const { Option } = Select;
export default function PTDash({ callApi, openClientEdit }) {

  const mail_data = JSON.parse(localStorage.getItem("mail_reminder"));
  const [searchValue, setSearchValue] = useState("");
  let [status9, setStatus9] = useState(0);
  let [status0, setStatus0] = useState(0);
  let [status1, setStatus1] = useState(0);
  let [status2, setStatus2] = useState(0);
  let [status8, setStatus8] = useState(0);
  let [status7, setStatus7] = useState(0);

  let [proposalList, setProposalList] = useState([]);
  let [proposal_status, setProposalStatus] = useState(0);

  const [pagination1, setPagination1] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  let [proposalLoad, SetProposalLoad] = useState(false);

  const getDashData = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.dashboard;
      setStatus0(dashboard.status0);
      setStatus1(dashboard.status1);
      setStatus2(dashboard.status2);
      setStatus7(dashboard.status7);
      setStatus9(dashboard.status9);
    } catch (error) {
    }
  };

  const getProposalListData = async () => {
    try {
      let payload = {
        pt_status: proposal_status,
        page: pagination1.current,
        limit: pagination1.pageSize,
        country: country ? country : null,
        client_id: client_id ? client_id : null,
        fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        toDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        region: region ? region : null,
        scope: scope ? scope : null,
        program: program ? program : null,
        search: search ? search : null,
      };
      const response = await axios.post(
        `${getAllProposals}`,
        payload,
        API_HEADER
      );
      setProposalList(response.data.data);

      setPagination1((prevPagination) => ({
        ...prevPagination,
        total: response.data.count,
      }));

      SetProposalLoad(false);
    } catch (error) {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDashData();
      await Promise.all([
        getCountry(),
        getClientname(),
        getRegion(),
        getScope(),
        getProgram()
      ]);
    };

    fetchData().catch(console.error);
  }, []);

  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setCountry(null);
    setClient_id(null);
    setRegion(null);
    setScope(null);
    setProgram(null)
    setActiveKey(key);
    setFromDate("");
    setToDate("");

    setPagination1((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));

    if (key == 1) {
      setProposalStatus(0);
      SetProposalLoad(true);
    } else if (key == 2) {
      setProposalStatus(1);
      SetProposalLoad(true);
    } else if (key == 3) {
      setProposalStatus(2);
      SetProposalLoad(true);
    } else if (key == 4) {
      setProposalStatus(9);
      SetProposalLoad(true);
    } else if (key == 5) {
      setProposalStatus(7);
      SetProposalLoad(true);
    }
  };

  useEffect(() => {
    getProposalListData();
  }, [proposalLoad]);

  const handleTableChange1 = (pagination, filters, sorter) => {
    setPagination1(pagination);
    SetProposalLoad(true);
  };
  const [program, setProgram] = useState(null);
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [scope, setScope] = useState(null);
  const [client_id, setClient_id] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [scopeList, setScopeList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [clientname, setClientname] = useState([]);

  const [search, setSearch] = useState(null);

  const handleSearch = (value) => {
    setSearch(value);
    SetProposalLoad(true);
  };

  const getProgram = async () => {
    try {
      const result = await axios.get(`${get_program_url}`);
      setProgramList(result.data.data);
    } catch (error) {

    }
  };

  const getCountry = async () => {
    try {
      const result = await axios.get(`${getCountryList}`);
      setCountryList(result.data.data);
    } catch (error) {

    }
  };

  const getRegion = async () => {
    try {
      const result = await axios.get(`${get_regions_url}`);
      setRegionList(result.data.data);
    } catch (error) {

    }
  };
  const getScope = async () => {
    try {
      const result = await axios.get(`${get_sectoralscope_url}`);
      setScopeList(result.data.data);
    } catch (error) {

    }
  };
  const getClientname = async () => {
    try {
      const result = await axios.get(`${get_client_name_url}`);
      setClientname(result.data.data);
    } catch (error) {

    }
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  const handleClientNameSearch = (value) => {
    setClient_id(value);
    SetProposalLoad(true);
  };

  const handleCountrySearch = (value) => {
    setCountry(value);
    SetProposalLoad(true);
  };

  const handleRegionSearch = (value) => {
    setRegion(value);
    SetProposalLoad(true);
  };
  const handleScopeSearch = (value) => {
    setScope(value);
    SetProposalLoad(true);
  };
  const handleProgramSearch = (value) => {
    setProgram(value);
    SetProposalLoad(true);
  };

  const navigate = useNavigate();

  const editFormForClarification = async (record) => {
    const payload = {
      proposal_id: record.proposal_id,
    };
    const response = await axios.post(
      `${get_proposal_detail_url}`,
      payload,
      API_HEADER
    );
    const data = response.data.record;
    if (proposal_status == 0) {
      navigate("/projects", { state: { data } });
    } else if (proposal_status == 7) {
      navigate("/ptactions", { state: { data } });
    } else {
      navigate("/tmsactions", { state: { data } });
    }
  };


  const dateFormat = 'DD/MM/YYYY';
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const handleFromDateChange = (date) => {
    setFromDate(date);
    SetProposalLoad(true);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    SetProposalLoad(true);
  };
  const disabledFutureDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const handleSearchByDateRange = (value) => {
    const currentDate = moment();
    if (fromDate && fromDate.isAfter(currentDate)) {
      toast.error("From date cannot be a future date");
    } else if (toDate && toDate.isAfter(currentDate)) {
      toast.error("To date cannot be a future date");
    } else if (fromDate && toDate && fromDate.isAfter(toDate)) {
      toast.error("From date cannot be greater than to date");
    }
    else {
      SetProposalLoad(true);
    }

  }

  const columnsProposalTeamTab1 = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      width: "8%",
      dataIndex: "proposal_id",
      fixed: "left",
      render: (text, record, index) => {
        const pageIndex = (pagination1.current - 1) * pagination1.pageSize;
        return pageIndex + index + 1;
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EId
        </span>
      ),
      // width: 140,
      fixed: "left",
      render: (text, record) => {
        return (
          // <span className="text-capitalize  font14px ">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
                <span className="text-capitalize textcolorgreen font14px preserve-space">
                  {record.earthood_id}
                </span>
              </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold text-truncate">
          Project Name
        </span>
      ),
      width: "15%",
      render: (text, record) => (

        <ProposalNameWithDelay record={record}/>

      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: "10%",
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width: "15%",
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
            <span className="text-capitalize font14px text-ellipsis">
              {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width: "12%",
      // ellipsis: false,

      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
              <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
              </span>
            </Tip>

          );
        } else {
          return null
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: "10%",
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.country}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: "8%",
      render: (text, record) => (
        <a className="">
          {record.status === 0 || record.status === 3 || record.status === 5 ? (
            <EditOutlined
              onClick={() => editFormForClarification(record)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForClarification(record)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          )}
        </a>
      ),
    },
  ];

  const columnsProposalTeam = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "proposal_id",
      fixed: "left",
      width: '8%',
      render: (text, record, index) => {
        const pageIndex = (pagination1.current - 1) * pagination1.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
        Submission date
        </span>
      ),
      width: '12%',
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.created_at.slice(0, 10)}
          // </span>
          <Tip title={record.created_at.slice(0, 10)}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.created_at.slice(0, 10)}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.created_at.slice(0, 10) > record2.created_at.slice(0, 10)) ? 1 : (record1.created_at.slice(0, 10) === record2.created_at.slice(0, 10)) ? 0 : -1
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EId
        </span>
      ),
      // width: '20%',

      fixed: "left",
      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
          <span className="text-capitalize textcolorgreen font14px preserve-space ">
            {record.earthood_id}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold text-truncate">
          Project Name
        </span>
      ),
      width: '12%',

      render: (text, record) => (
     
        <ProposalNameWithDelay record={record}/>

      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width: '10%',
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width:'10%',
      render: (text, record) => {
        return (
          <Tip title={record.client_name}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.client_name}
                </span>
              </Tip>
          // <span className="text-capitalize font14px ">
          //   {record.client_name}
          // </span>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      },
      // className: 'custom-column',
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width:'10%',
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
                <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                {record.sector}
                </span>
              </Tip>
          );
        } else {
          return null
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width:'10%',
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.country}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: '8%',
      render: (text, record) => (
        <a className="">
           <EyeOutlined
              onClick={() => editFormForClarification(record)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          {/* {record.status === 0 || record.status === 3 || record.status === 5 ? (
            <EditOutlined
              onClick={() => editFormForClarification(record)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForClarification(record)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          )} */}
        </a>
      ),
    },
  ];

  const columnsProposalTeamTab3 = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      dataIndex: "proposal_id",
      fixed: "left",
      width: "8%",
      render: (text, record, index) => {
        const pageIndex = (pagination1.current - 1) * pagination1.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Received date
        </span>
      ),
      width: "12%",
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.tms_action_date.slice(0, 10)}
          // </span>
          <Tip title={record.tms_action_date.slice(0, 10)}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.tms_action_date.slice(0, 10)}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.tm_action_date.slice(0, 10) > record2.tm_action_date.slice(0, 10)) ? 1 : (record1.tm_action_date.slice(0, 10) === record2.tm_action_date.slice(0, 10)) ? 0 : -1
      }

    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EId
        </span>
      ),
      fixed: "left",
      // width:140,
      render: (text, record) => {
        return (
          <Tip title={record.earthood_id}>
          <span className="text-capitalize textcolorgreen font14px preserve-space">
            {record.earthood_id}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold text-truncate">
          Project Name
        </span>
      ),
      width:"12%",
      render: (text, record) => (
        <ProposalNameWithDelay record={record}/>
      ),
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width:"10%",
      render: (text, record) => {
        if (record.program_id) {
          return (
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width:"10%",
      render: (text, record) => {
        return (
            <Tip title={record.client_name}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.client_name}
            </span>
          </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width:"10%",
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.sector}
            </span>
          </Tip>
          );
        } else {
          return null
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      },
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width:"10%",
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.country}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: "8%",
      render: (text, record) => (
        <a className="">
          {record.status === 0 || record.status === 2 ? (
            <EditOutlined
              onClick={() => editFormForClarification(record)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          ) : (
            <EyeOutlined
              onClick={() => editFormForClarification(record)}
              style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
            />
          )}
        </a>
      ),
    },
  ];

  const columnRejectedProjects = [
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          S.No
        </span>
      ),
      width:"8%",
      render: (text, record) => {
        return (
          // <span className="text-capitalize font14px ">
          //   {record.proposal_id}
          // </span>
          <Tip title={record.proposal_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.proposal_id}
            </span>
          </Tip>
        );
      },
      fixed: "left",
   
      render: (text, record, index) => {
        const pageIndex = (pagination1.current - 1) * pagination1.pageSize;
        return pageIndex + index + 1;
      },
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Forwaded date
        </span>
      ),
      width: "12%",
      render: (text, record) => {
        return (
          // <span className="font14px">
          //   {record.pt_submit_date.slice(0, 10)}
          // </span>
          <Tip title={record.pt_submit_date.slice(0, 10)}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.pt_submit_date.slice(0, 10)}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.pt_submit_date.slice(0, 10) > record2.pt_submit_date.slice(0, 10)) ? 1 : (record1.pt_submit_date.slice(0, 10) === record2.pt_submit_date.slice(0, 10)) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          EId
        </span>
      ),
      // width:140,
      fixed: "left",
      render: (text, record) => {
        return (
          // <span className="text-capitalize font14px">
          //   {record.earthood_id}
          // </span>
          <Tip title={record.earthood_id}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.earthood_id}
          </span>
        </Tip>
        );
      },

      sorter: (record1, record2) => {
        return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
      },
    },

  
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Project Name
        </span>
      ),
      width:"12%",
      render: (text, record) => {
        return (
          <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Program
        </span>
      ),
      width:"10%",
      render: (text, record) => {
        if (record.program_id) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.program_id}
            // </span>
            <Tip title={record.program_id}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.program_id}
            </span>
          </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.program_id > record2.program_id) ? 1 : (record1.program_id === record2.program_id) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Client Name
        </span>
      ),
      width:"10%",
      render: (text, record) => {
        return (
                     <Tip title={record.client_name}>
                     <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                       {record.client_name}
                     </span>
                     </Tip>
        );
      },
      sorter: (record1, record2) => {
        return (record1.client_name > record2.client_name) ? 1 : (record1.client_name === record2.client_name) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Sectoral Scope
        </span>
      ),
      width:"10%",
      render: (text, record) => {
        if (record.sector) {
          return (
            <Tip title={record.sector}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.sector}
            </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.sector > record2.sector) ? 1 : (record1.sector === record2.sector) ? 0 : -1
      }
    },

    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Country
        </span>
      ),
      width: "10%",
      render: (text, record) => {
        if (record.country) {
          return (
            // <span className="text-capitalize textcolorgreen font14px">
            //   {record.country}
            // </span>
            <Tip title={record.country}>
            <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
              {record.country}
            </span>
            </Tip>
          );
        } else {
          return null;
        }
      },
      sorter: (record1, record2) => {
        return (record1.country > record2.country) ? 1 : (record1.country === record2.country) ? 0 : -1
      }
    },
    {
      title: (
        <span className="text-capitalize textcolumntitle font14px fw-bold">
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      fixed: "right",
      width: "8%",
      render: (text, record) => (
        <EyeOutlined
          onClick={() => editFormForClarification(record)}
          style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="1"
              centered
              activeKey={activeKey}
              onChange={handleTabChange}
            >
              
              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS  ${activeKey == 1 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Proposal Under Preparation
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {status0}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="1"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="row border-0">
                        <div className="col-12">
                          <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                            Proposal Under Preparation
                          </p>
                        </div>
                      </div>
                      <div className="m-3 bg-white border-0 rounded-top-3">

                        <div className="row gx-3">
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Client Name
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select client name"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleClientNameSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >

                              <Option value="">Select</Option>

                              {clientname.map((client, index) => (
                                <Option
                                  key={index}
                                  value={client.id}
                                  label={client.name}
                                >
                                  {client.name}
                                </Option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Country
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select country"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleCountrySearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {countryList.map((country, index) => (
                                <Option
                                  key={index}
                                  value={country.id}
                                  label={country.name}
                                >
                                  {country.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Scope
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select scope"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleScopeSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {scopeList.map((scope, index) => (
                                <Option
                                  key={index}
                                  value={scope.id}
                                  label={scope.scope}
                                >
                                  {scope.scope}
                                </Option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Program
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select program"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleProgramSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {programList.map((program, index) => (
                                <Option
                                  key={index}
                                  value={program.id}
                                  label={program.program_name}
                                >
                                  {program.program_name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          {/* <div className="col-sm-4 col-md-3 justify-content-end">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Search
                            </label>
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"

                            />
                          </div> */}
                        </div>


                      </div>

                      <Table
                        scroll={{ x: 1000, }}
                        columns={columnsProposalTeamTab1}
                        loading={proposalLoad}
                        dataSource={proposalList}
                        rowKey="proposal_id"
                        pagination={pagination1}
                        onChange={handleTableChange1}
                        bordered
                      />
                      {
                        proposalList != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              
              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${activeKey == 2 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Proposal Under Approval
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {status1}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="2"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="row border-0">
                        <div className="col-12">
                          <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                            Proposal Under Approval
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center bg-white  px-0 border-0 rounded-top-3">

                        <div className="row gx-3 align-items-center p-2">
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Client Name
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select client name"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleClientNameSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>
                              {clientname.map((client, index) => (
                                <Option
                                  key={index}
                                  value={client.id}
                                  label={client.name}
                                >
                                  {client.name}
                                </Option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Country
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select country"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleCountrySearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {countryList.map((country, index) => (
                                <Option
                                  key={index}
                                  value={country.id}
                                  label={country.name}
                                >
                                  {country.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Scope
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select scope"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleScopeSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {scopeList.map((scope, index) => (
                                <Option
                                  key={index}
                                  value={scope.id}
                                  label={scope.scope}
                                >
                                  {scope.scope}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Program
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select program"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleProgramSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {programList.map((program, index) => (
                                <Option
                                  key={index}
                                  value={program.id}
                                  label={program.program_name}
                                >
                                  {program.program_name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date From</label>
                              <DatePicker onChange={handleFromDateChange} placeholder="From Date" style={{ width: "100%", }} className="rounded-2" format={dateFormat} showTime={false} disabledDate={disabledFutureDate} />
                            </div>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date To</label>
                              <DatePicker onChange={handleToDateChange} placeholder="To Date" format={dateFormat} showTime={false} style={{ width: "100%", }} disabledDate={disabledFutureDate}
                                className="rounded-2" />
                            </div>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Search
                            </label>

                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"

                            />
                          </div>
                        </div>


                      </div>

                      <Table
                        scroll={{ x: 1000, }}
                        columns={columnsProposalTeam}
                        loading={proposalLoad}
                        dataSource={proposalList}
                        rowKey="proposal_id"
                        pagination={pagination1}
                        onChange={handleTableChange1}
                        bordered
                      />
                      {
                        proposalList != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
            
              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${activeKey == 3 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Under Modification
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {status2}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleQuestion}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="3"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="row border-0">
                        <div className="col-12">
                          <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                            Proposal Under Modification
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center px-0 border-0  rounded-top-3">

                        <div className="row gx-3 align-items-center p-2">
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Client Name
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select client name"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleClientNameSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {clientname.map((client, index) => (
                                <Option
                                  key={index}
                                  value={client.id}
                                  label={client.name}
                                >
                                  {client.name}
                                </Option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Country
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select country"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleCountrySearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {countryList.map((country, index) => (
                                <Option
                                  key={index}
                                  value={country.id}
                                  label={country.name}
                                >
                                  {country.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Scope
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select scope"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleScopeSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {scopeList.map((scope, index) => (
                                <Option
                                  key={index}
                                  value={scope.id}
                                  label={scope.scope}
                                >
                                  {scope.scope}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Program
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select program"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleProgramSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {programList.map((program, index) => (
                                <Option
                                  key={index}
                                  value={program.id}
                                  label={program.program_name}
                                >
                                  {program.program_name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="mt-3 col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date From </label>
                              <DatePicker
                                onChange={handleFromDateChange}
                                placeholder="From Date"
                                style={{ width: "100%", }}
                                className="rounded-2"
                                format={dateFormat}
                                showTime={false}
                                disabledDate={disabledFutureDate}
                                allowClear

                              />
                            </div>
                          </div>
                          <div className="mt-3 col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date To </label>
                              <DatePicker onChange={handleToDateChange} placeholder="To Date" format={dateFormat} showTime={false} style={{ width: "100%", }}
                                className="rounded-2" disabledDate={disabledFutureDate} allowClear
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Search
                            </label>
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="custom-search rounded-2"

                            />                        </div>
                        </div>


                      </div>

                      <Table
                        scroll={{ x: 1000, }}
                        columns={columnsProposalTeamTab3}
                        loading={proposalLoad}
                        dataSource={proposalList}
                        rowKey="proposal_id"
                        pagination={pagination1}
                        onChange={handleTableChange1}
                        bordered
                      />
                      {
                        proposalList != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

             
              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${activeKey == 4 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Submitted to Sales
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {status9}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faUser}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="4"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="row border-0">
                        <div className="col-12">
                          <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                          Submitted to Sales
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center px-0 border-0  rounded-top-3">

                        <div className="row gx-3 align-items-center p-2">
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Client Name
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select client name"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleClientNameSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {clientname.map((client, index) => (
                                <Option
                                  key={index}
                                  value={client.id}
                                  label={client.name}
                                >
                                  {client.name}
                                </Option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Country
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select country"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleCountrySearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {countryList.map((country, index) => (
                                <Option
                                  key={index}
                                  value={country.id}
                                  label={country.name}
                                >
                                  {country.name}
                                </Option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Scope
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select scope"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleScopeSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {scopeList.map((scope, index) => (
                                <Option
                                  key={index}
                                  value={scope.id}
                                  label={scope.scope}
                                >
                                  {scope.scope}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Program
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select program"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleProgramSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {programList.map((program, index) => (
                                <Option
                                  key={index}
                                  value={program.id}
                                  label={program.program_name}
                                >
                                  {program.program_name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="mt-3 col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date From </label>
                              <DatePicker onChange={handleFromDateChange} placeholder="From Date" style={{ width: "100%", }}
                                className="rounded-2" format={dateFormat} showTime={false} disabledDate={disabledFutureDate} />
                            </div>
                          </div>
                          <div className="mt-3 col-sm-4 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date To </label>
                              <DatePicker onChange={handleToDateChange} placeholder="To Date" format={dateFormat} showTime={false} style={{ width: "100%", }}
                                className="rounded-2" disabledDate={disabledFutureDate} />
                            </div>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Search
                            </label>
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"

                            />                        </div>
                        </div>


                      </div>

                      <Table
                        scroll={{ x: 1000, }}
                        columns={columnsProposalTeamTab3}
                        loading={proposalLoad}
                        dataSource={proposalList}
                        rowKey="proposal_id"
                        pagination={pagination1}
                        onChange={handleTableChange1}
                        bordered
                      />
                      {
                        proposalList != '' ?
                          (<>
                            <LegendTable />
                          </>) : ''
                      }
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

            
             
              <Tabs.TabPane
                tab={
                  <div className={`border-1 borderlightgreen rounded-2 p-2 mx-0 tabactivecolor  tab_dashboard_size5tabTMS ${activeKey == 5 ? 'box_tab' : ''}`}>
                    <p className="font14px textlightblack text-capitalize text-wrap ">
                      Rejected Proposals
                    </p>
                    <div className="paracenter">
                      <p className="textcolorblue stat_text">
                        {status7}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          size="xl"
                          className="iconcolor"
                        />
                      </p>
                    </div>
                  </div>
                }
                key="5"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 border-1 border border-light-subtle p-0 rounded-3">
                      <div className="row border-0">
                        <div className="col-12">
                          <p className="text-black text-capitalize  font20px fw-medium p-2 rounded-top-3">
                            Rejected Proposals
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center px-0  border-0 rounded-top-3">

                        <div className="row gx-3 align-items-center p-2">
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Client Name
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select client name"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleClientNameSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {clientname.map((client, index) => (
                                <Option
                                  key={index}
                                  value={client.id}
                                  label={client.name}
                                >
                                  {client.name}
                                </Option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Country
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select country"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleCountrySearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {countryList.map((country, index) => (
                                <Option
                                  key={index}
                                  value={country.id}
                                  label={country.name}
                                >
                                  {country.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Scope
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select scope"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleScopeSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {scopeList.map((scope, index) => (
                                <Option
                                  key={index}
                                  value={scope.id}
                                  label={scope.scope}
                                >
                                  {scope.scope}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Program
                            </label>
                            <Select
                              showSearch
                              allowClear
                              placeholder="Select program"
                              optionFilterProp="children"
                              filterOption={filterOption}
                              onChange={handleProgramSearch}
                              style={{ width: "100%", }}
                              className="rounded-2"
                            >
                              <Option value="">Select</Option>

                              {programList.map((program, index) => (
                                <Option
                                  key={index}
                                  value={program.id}
                                  label={program.program_name}
                                >
                                  {program.program_name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date From </label>
                              <DatePicker onChange={handleFromDateChange} placeholder="From Date" style={{ width: "100%", }}
                                className="rounded-2" format={dateFormat} showTime={false} disabledDate={disabledFutureDate} />
                            </div>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <div className="mb-3">
                              <label className='text-capitalize textcolumntitle fw-bold font12px'>Submission Date To </label>
                              <DatePicker onChange={handleToDateChange} placeholder="To Date" format={dateFormat} showTime={false} style={{ width: "100%", }}
                                className="rounded-2" disabledDate={disabledFutureDate} />
                            </div>
                          </div>
                          <div className="col-sm-4 mt-3 col-md-3">
                            <Button
                              className="py-1 px-2 mt-3 btn btn-success btn-sm rounded-2"
                              onClick={handleSearchByDateRange}
                            >
                              Search
                            </Button>
                          </div>
                          <div className="col-sm-4 col-md-3">
                            <label className="text-capitalize textcolumntitle fw-bold font12px">
                              Search
                            </label>
                            <Input.Search
                              allowClear
                              placeholder="Search By Project Name/EID"
                              onChange={(e) => setSearchValue(e.target.value)}
                              value={searchValue}
                              enterButton
                              onSearch={handleSearch}
                              style={{ width: "100%", }}
                              className="rounded-2 custom-search"

                            />                        </div>
                        </div>


                      </div>

                      <Table
                        scroll={{ x: 1000 }}
                        columns={columnRejectedProjects}
                        loading={proposalLoad}
                        dataSource={proposalList}
                        rowKey="proposal_id"
                        pagination={pagination1}
                        onChange={handleTableChange1}
                        bordered
                      />
                    
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>

            </Tabs>
          </div>
        </div>
      </div>



    </>
  );
}
