import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  BASE_DOCUMENT,
  get_KickoutInfo_url,
  tldocs_Kickout_url,
} from "../../config";
import { useParams } from "react-router-dom";
import { API_HEADER } from "../../config";
import FileUploaderComponent from "../../utlis/FileUploaderComponent";
import LoaderComponent from "../../utlis/LoderComponent";


const ATKickout = () => {

  const navigate = useNavigate();
  const { id } = useParams();

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const [kickoutStatus, setkickoutStatus] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkAT, setRemarkAT] = useState("");
  const [remarkClient, setRemarkClient] = useState('');
  const [member, setMember] = useState();
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [file7, setFile7] = useState('');

  const [fvrName, setFVRName] = useState(null)
  const [fvr, setFvr] = useState("")
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null)
  const [auditFinding, setAuditFinding] = useState("")
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");
  const [kickoutId, setKickoutId] = useState('')

  const [fileFVRSizeError, setFileFVRSizeError] = useState(false);
  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [fileRRSizeError, setFileRRSizeError] = useState(false);
  const [fileCpaSizeError, setFileCpaSizeError] = useState(false);
  const [fileAuditSizeError, setFileAuditSizeError] = useState(false);
  const [fileOtherSizeError, setFileOtherSizeError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );


      const { record } = response.data;
      const kickout = response.data.kickout;

      setFile1(kickout.fvr_report)
      setFile2(kickout.err_document)
      setFile3(kickout.pdd_document)
      setFile6(kickout.audit_finding)

      setkickoutStatus(record.kickout_status);
      setRemarkTL(kickout.tl_remarks);
      setRemarkAT((record.kickout_status == 1 || record.kickout_status == 3 || record.kickout_status == 5) ? kickout.at_remarks == "" : kickout.at_remarks);
      setRemarkClient(kickout.client_remarks);
      setMember(kickout.kickout_status)
      setKickoutId(record.kickout_id)




      let url1 = `${BASE_DOCUMENT}/projects/${kickout.id}/${kickout.fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.err_document}`;
      setERName(url2);
      setER(kickout.err_document);

      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.irr_document}`;
      setRRName(url5);
      setRR(kickout.irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.other_docs}`;
      setOtherName(url7);
      setOther(kickout.other_docs);

    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };

  const handleRemarksChange = (content) => {
    setRemarkAT(content);
  };

  // console.log("file1",file1)
  // console.log("file2",file2)
  // console.log("file3",file3)
  // console.log("file6",file6)

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if ((remarkAT == null || remarkAT == "<p><br></p>" || remarkAT == "") || (file1 == null || file1 == '') || (file2 == null || file2 == '') || (file3 == null || file3 == '') || (file6 == null || file6 == '')) {
      toast.error("Please upload the document and remarks.");
    } else {
      try {
        setIsSubmitting(true);

        const formDataToSend = new FormData();

        formDataToSend.append("project_id", id);
        formDataToSend.append("fvr_report", file1);
        formDataToSend.append("err_document", file2);
        formDataToSend.append("pdd_document", file3);
        formDataToSend.append("irr_document", file4);
        formDataToSend.append("cpa_document", file5);
        formDataToSend.append("audit_finding", file6);
        formDataToSend.append("other_docs[]", file7);
        formDataToSend.append("at_remarks", remarkAT);
        formDataToSend.append("kickout_status", member);
        formDataToSend.append("kickout_id", kickoutId)

        const response = await axios.post(
          `${tldocs_Kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (filePDDSizeError || fileERSizeError || fileOtherSizeError || fileAuditSizeError || fileFVRSizeError || fileRRSizeError || fileCpaSizeError) {
          toast.error(`File size must below ${dynamicMaxSize}MB`);
        } else {
          toast.success("Document Submitted Successfully");

          setRemarkTL("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      }
      finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);

      }
    }
  };



  return (
    <>
      {(kickoutStatus === 3 || kickoutStatus === 1 || kickoutStatus === 5) ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >

            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                    Upload Document
                  </th>
                  {
                    kickoutStatus === 3 || kickoutStatus === 5 ?

                      <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                        Uploaded Document
                      </th>
                      :
                      ''
                  }

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    FVR Report
                    <span style={{ color: "red" }}>*</span>

                  </td>

                  <FileUploaderComponent file={file1} setFile={setFile1} />

                  {
                    kickoutStatus === 3 || kickoutStatus === 5 ?
                      <td>
                        <a href={fvrName} target="_blank">{fvr}</a>
                      </td>
                      :
                      ""
                  }


                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>

                  </td>

                  <FileUploaderComponent file={file2} setFile={setFile2} />

                  {kickoutStatus === 3 || kickoutStatus === 5 ?
                    <td>
                      <a href={erName} target="_blank">{er}</a>
                    </td>
                    :
                    ''
                  }


                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    PDD/MR
                    <span style={{ color: "red" }}>*</span>

                  </td>

                  <FileUploaderComponent file={file3} setFile={setFile3} />

                  {
                    kickoutStatus === 3 || kickoutStatus === 5 ?
                      <td>
                        <a href={pddName} target="_blank">{pdd}</a>
                      </td>
                      :
                      ''
                  }



                </tr>

                <tr>
                  <td class="text-center">4</td>



                  <td className="text-center lightgreen p-3">
                    IRR

                  </td>

                  <FileUploaderComponent file={file4} setFile={setFile4} />


                  {
                    kickoutStatus === 3 || kickoutStatus === 5 ?
                      <td>
                        <a href={rrName}>{rr}</a>
                      </td>
                      :
                      ''
                  }


                </tr>

                <tr>
                  <td class="text-center">5</td>
                  <td className="text-center lightgreen p-3">
                    CPA

                  </td>

                  <FileUploaderComponent file={file5} setFile={setFile5} />

                  {
                    kickoutStatus === 3 || kickoutStatus === 5 ?
                      <td>
                        <a href={cpaName}>{cpa}</a>
                      </td>
                      :
                      ""
                  }


                </tr>

                <tr>
                  <td class="text-center">6</td>



                  <td className="text-center lightgreen p-3">
                    Audit Finding
                    <span style={{ color: "red" }}>*</span>

                  </td>

                  <FileUploaderComponent file={file6} setFile={setFile6} />

                  {
                    kickoutStatus === 3 || kickoutStatus === 5 ?
                      <td>
                        <a href={auditFindingName} target="_blank">{auditFinding}</a>
                      </td>
                      :
                      ''
                  }




                </tr>


                <tr>
                  <td class="text-center">7</td>


                  <td className="text-center lightgreen p-3">
                    Other Document

                  </td>

                  <FileUploaderComponent file={file7} setFile={setFile7} />


                  {
                    kickoutStatus === 3 || kickoutStatus === 5 ?
                      <td>
                        <a target="_blank" href={otherName}>
                          {other}
                        </a>
                      </td>
                      :
                      ''
                  }



                </tr>

              </tbody>
            </table>

            <div>

              <div className="col-5 my-4 ">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  required
                  onChange={(e) => {
                    setMember(e.target.value);
                  }}
                >
                  <option value=''>Select</option>

                  <option value={2}>Forward to TL</option>

                </select>
              </div>



              <p className="lightgreen fw-bold my-3 fs-6"> Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  onChange={handleRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>

              {/* {(kickoutStatus == 3 || kickoutStatus == 1) && remarkTL != null ?

              <div>
                <p className="lightgreen fw-bold my-3 fs-6">TL Remarks</p>
                <hr className="mb-3 lightgreen" />

                <div className="row mt-4">

                  <ReactQuill
                    theme="snow"
                    value={remarkTL}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkTL }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />

                </div>
              </div>
              :
              ''
            } */}

              {remarkTL &&
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks</p>
                  <hr className="mb-3 lightgreen" />

                  <div className="row mt-4">

                    <ReactQuill
                      theme="snow"
                      value={remarkTL}
                      name="remarks"
                      dangerouslySetInnerHTML={{ __html: remarkTL }}
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />

                  </div>
                </div>
              }

              {/* {remarkClient != null ?

              <div>
                <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                <hr className="mb-3 lightgreen" />

                <div className="row mt-4">

                  <ReactQuill
                    theme="snow"
                    value={remarkClient}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkClient }}
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />

                </div>
              </div>
              :
              ''
            } */}

              {remarkClient &&
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                  <hr className="mb-3 lightgreen" />

                  <div className="row mt-4">

                    <ReactQuill
                      theme="snow"
                      value={remarkClient}
                      name="remarks"
                      dangerouslySetInnerHTML={{ __html: remarkClient }}
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />

                  </div>
                </div>
              }
            </div>


            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>

          </form>
        </>



      ) :

        (

          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >

            <>


              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                      Uploaded Document
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      FVR Report
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={fvrName} target="_blank">{fvr}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      ER
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={erName} target="_blank">{er}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      PDD
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={pddName} target="_blank">{pdd}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">4</td>



                    <td className="text-center lightgreen p-3">
                      IRR
                    </td>




                    <td>
                      <a href={rrName}>{rr}</a>
                    </td>



                  </tr>

                  <tr>
                    <td class="text-center">5</td>



                    <td className="text-center lightgreen p-3">
                      CPA
                    </td>



                    <td>
                      <a href={cpaName}>{cpa}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">6</td>



                    <td className="text-center lightgreen p-3">
                      Audit Finding
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={auditFindingName} target="_blank">{auditFinding}</a>
                    </td>


                  </tr>


                  <tr>
                    <td class="text-center">7</td>


                    <td className="text-center lightgreen p-3">
                      Other Document
                    </td>






                    <td>
                      <a target="_blank" href={otherName}>
                        {other}
                      </a>
                    </td>


                  </tr>

                </tbody>
              </table>

              {kickoutStatus < 6 ?
                <div className="col-5 my-4 ">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    value={member}
                    disabled

                  >
                    <option value=''>Select</option>



                    <option value={2}>Forward to TL</option>
                    <option value={6}>Send to TR</option>



                  </select>
                </div>
                : ""}

              <p className="lightgreen fw-bold my-3 fs-6"> Team Member Remarks <span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  disabled
                  readOnly={true}

                  // dangerouslySetInnerHTML={{ __html: remarkAT }}
                  // onChange={handleRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>

              {remarkTL &&
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks</p>
                  <hr className="mb-3 lightgreen" />

                  <div className="row mt-4">

                    <ReactQuill
                      theme="snow"
                      value={remarkTL}
                      name="remarks"
                      dangerouslySetInnerHTML={{ __html: remarkTL }}
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />

                  </div>
                </div>
              }


              {remarkClient &&
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
                  <hr className="mb-3 lightgreen" />

                  <div className="row mt-4">

                    <ReactQuill
                      theme="snow"
                      value={remarkClient}
                      name="remarks"
                      dangerouslySetInnerHTML={{ __html: remarkClient }}
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />

                  </div>
                </div>
              }

            </>


          </form>
        )

      }
    </>
  );
};

export default ATKickout;





