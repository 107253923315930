import React from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

export default function AlternateContact({ records, addrecord, formdisabled, handleRecordChange, deleteRecord }) {
  return (
    <>
      <table className="table table-bordered table-hover table-responsive-sm">
        <thead>
          <tr>
            <th className="form-label lightgreen fs-6" style={{background:'#ABEBC6'}}>S.No.</th>
            <th className="form-label lightgreen fs-6" style={{background:'#ABEBC6'}}>Name
              <span style={{ color: "red" }}>*</span>
            </th>
            <th className="form-label lightgreen fs-6" style={{background:'#ABEBC6'}}>Email
              <span style={{ color: "red" }}>*</span>
            </th>
            <th className="form-label lightgreen fs-6" style={{background:'#ABEBC6'}}>Contact No.
              
            </th>
            <th className="form-label lightgreen fs-6" style={{background:'#ABEBC6'}}>Designation</th>

            {
              !formdisabled ? (

                <th style={{background:'#ABEBC6'}}>
                  <a className="">
                    <PlusOutlined
                      onClick={addrecord}
                      
                    />
                  </a>
                </th>
              ) : ""

            }

          </tr>
        </thead>
        <tbody>
          {records.map((record, index) => (
            <tr key={index}>
              <td>{index + 1}

              <input
                  type="hidden"
                  name="id"
                  className="form-control"
                  value={index + 1}
                  onChange={(e) => handleRecordChange(index, e)}
                  placeholder=""
                  required
                />

              </td>

              <td>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={record.name}
                  disabled={formdisabled}
                  onChange={(e) => handleRecordChange(index, e)}
                  placeholder=""
                  required
                />
                {record.name &&
                  (record.name.length < 3 || record.name.length > 30) ? (
                  <div className="text-danger">
                    Name must be between 3 and 30 characters long.
                  </div>
                ) : null}
              </td>

              <td>

                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={record.email}
                  disabled={formdisabled}
                  onChange={(e) => handleRecordChange(index, e)}
                  placeholder=""
                  required
                />
                {record.email &&
                  (record.email && !/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(record.email)) ? (
                  <span className="text-danger">Please enter a valid email address.</span>
                ) : null}
              </td>

             <td>
              <input
                type="text"
                name="mobile"             
                className="form-control"
                value={record.mobile}
                disabled={formdisabled}
                placeholder=""
                maxLength={13}
                onChange={(e) => {
                  const regex = /^[0-9]{0,13}$/; // Allow up to 10 digits
                  if (regex.test(e.target.value) || e.target.value === '') {
                    handleRecordChange(index, e);
                  }
                }}
               
              />
              {record.mobile && record.mobile.length > 13  && (
                <span className="text-danger">Mobile number should be 13 digits long.</span>
              )}
              </td>

              <td>
                <input
                  type="text"
                  name="designation"
                  className="form-control"
                  value={record.designation}
                  disabled={formdisabled}
                  onChange={(e) => handleRecordChange(index, e)}
                  placeholder=""
                  // required
                />
                {record.designation &&
                  (record.designation.length < 2 || record.designation.length > 30) ? (
                  <span className="text-danger">
                    Designation must be between 2 and 30 characters long.
                  </span>
                ) : null}
              </td>



              {
                !formdisabled ? (

                  <td>
                    <CloseOutlined onClick={() => deleteRecord(index)} />

                  </td>
                ) : ""

              }

            </tr>
          ))}

        </tbody>
      </table>
    </>
  );
}
