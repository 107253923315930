import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import { Tooltip as Tip } from "antd";

const ProjectNameWithDelay = ({record}) => {
    const mail_data = JSON.parse(localStorage.getItem("mail_reminder"));

    const proposalReceivedDateParts = record.created_at
        .split(" ")[0]
        .split("-");
    const proposalReceivedDate = new Date(
        `${proposalReceivedDateParts[2]}-${proposalReceivedDateParts[1]}-${proposalReceivedDateParts[0]}`
    );

    const today = new Date();
    const differenceInMilliseconds = today - proposalReceivedDate;
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    let projectNameStyle = { color: "green" };
    let delayDays = "";
    let redDot = false;

    if ((differenceInDays == mail_data.mail_days_warning) || (differenceInDays > mail_data.mail_days_warning && differenceInDays <= mail_data.mail_days_danger)) {
        redDot = true;
    } else if (differenceInDays > mail_data.mail_days_danger) {
        projectNameStyle = { color: "red" };
        delayDays = differenceInDays - mail_data.mail_days_danger;
        redDot = false;
    }
    return (
        <span className="text-capitalize font14px" style={projectNameStyle}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {redDot ? (
              <span>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="2xs"
                  style={{ color: "red" }}
                />
              </span>
            ) : (
              ""
            )}
            <Tip title={record.project_name}>
          <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
            {record.project_name}
          </span>
        </Tip>
          </div>
          <div>
            {delayDays ? ` (${delayDays} days)` : ""}
          </div>
        </span>
    )
}

export default ProjectNameWithDelay
