import React, { useState, useEffect } from "react";
import "../../src/App.css";
import { useNavigate } from "react-router-dom";
import ATProgramWiseProjectsGraph from "../Components/Graphs/atDashboardGraph/ATProgramWiseProjectGraph";
import ATProjectStageWiseGraph from "../Components/Graphs/atDashboardGraph/ATProjectStageWiseGraph";
import ATTLWorkloadGraph from "../Components/Graphs/atDashboardGraph/ATTLWorkloadGraph";
import ATValidatorWorkloadGraph from "../Components/Graphs/atDashboardGraph/ATValidatorVerifierGraph";
import ATTraineeWorkloadGraph from "../Components/Graphs/atDashboardGraph/ATTraineeWorkloadGraph";
import ATProjectMovingDateWiseGraph from "../Components/Graphs/atDashboardGraph/ATProjectMoving";
import { DatePicker, Spin } from "antd";
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'


const ATDashboardGraph = () => {
 
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/dashboard");
  };

  const dateFormat = "DD/MM/YYYY";

  const [search,setSearch]=useState(false);

  const [startDate,setstartDate]=useState(null);
  const [endDate,setendDate]=useState(null);
  const [loader,setLoader]=useState(false);

  const handleSearchByColumn = (value) => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        alert("Start Date cannot be after End Date.");
        setendDate(null);
        setstartDate(null);
        return;
      }
    }
    setSearch(!search);
  };  

  const resetFilters = () => {
    setendDate(null);
    setstartDate(null);
    setSearch(!search);
  }; 

  const handleDownload = async () => {
    setLoader(true)

    const pdf = new jsPDF();
    const sections = document.querySelectorAll('.page-break'); // Use class to target sections

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const canvas = await html2canvas(section);
      const imgData = canvas.toDataURL('image/png');
      
      if (i > 0) {
        pdf.addPage(); // Add a new page for subsequent sections
      }
      
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed
    }
    
    pdf.save('document.pdf');
    setLoader(false)

  };

  return (
    <>
  
  <Spin spinning={loader}>

      <div className="content dashboardcolor">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12 my-4">
              <div className="mx-4 my-3  d-flex">
                <h3 className="font-weight-bold">Project Dashboard</h3>
                <button
                  type="button"
                  class="btn btn-outline-success ml-auto"
                  onClick={handleClick}
                >
                  Home
                </button>

                <button className="mx-4 btn btn-primary" onClick={handleDownload}>Download</button>

              </div>
            </div>
          </div>

          <div className="row mx-4">

            <div className="col-sm-3 col-md-2">
            <div className="mb-3">
               <label className="text-capitalize textcolumntitle  fw-bold font12px">
                   From Date
               </label>
                 <DatePicker
                      onChange={(date, dateString) => setstartDate(date)}
                      placeholder="From Date"
                      style={{ width: "100%" }}
                      className="rounded-2"
                      format={dateFormat}
                      showTime={false}
                      value={startDate}
                      disabledDate={(current) => current && current > moment().endOf('day')}
                 />
            </div>
            
            </div>

              <div className="col-sm-3 col-md-2">
              <div className="mb-3">
                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                  To Date
                </label>
              <DatePicker
                   onChange={(date, dateString) => setendDate(date)}
                   placeholder="To Date"
                   style={{ width: "100%" }}
                   className="rounded-2"
                   format={dateFormat}
                   showTime={false}
                   value={endDate}
                   disabledDate={(current) => current && current > moment().endOf('day')}
              />
              </div>
              
              </div>

              <div className="col-sm-3 col-md-2 mt-4">

                        <button
                          className="btn btn-success btn-md rounded-2 me-2"
                          onClick={handleSearchByColumn}
                        >
                          Search
                        </button>

                        <button
                          className="btn btn-danger btn-md rounded-2"
                          onClick={resetFilters}
                        >
                          Reset
                        </button>

              </div>

          </div>

          <div className="page-break">

          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12 col-sm-12  my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Program Wise Projects</h4>
                 <ATProgramWiseProjectsGraph  search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>


          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-4 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Projects in Different Stages</h4>
                    <ATProjectStageWiseGraph  search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>


          <div className="row mx-4">
            <div className="col-12 col-md-6 col-lg-6  col-sm-12 my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Team Leader WorkLoad</h4>
                  <ATTLWorkloadGraph  search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6 col-sm-12 my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Validator/Verifier Workload</h4>
                  <ATValidatorWorkloadGraph  search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>



            <div className="row mx-4">
            <div className="col-12 col-md-6 col-lg-6  col-sm-12 my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Trainee Workload</h4>
                  <ATTraineeWorkloadGraph  search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-6  col-sm-12 my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Project Moving Wise</h4>
                    <ATProjectMovingDateWiseGraph/>
                  </div>
              </div>
            </div>
            </div>
          
          </div>
          
        </div>
      </div>
    
      </Spin>

    </>

  );
};

export default ATDashboardGraph;
