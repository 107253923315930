import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API_HEADER, region_wise_projects_url } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function RegionWiseProjectGraph({search,startDate,endDate}) {
    const [projectOngoing, setProjectOngoing] = useState([]);
    const [projectKickout, setProjectKickout] = useState([]);
    const [projectNotStarted, setProjectNotStarted] = useState([]);
    const [projectCompleted, setProjectCompleted] = useState([]);
    const [regionName, setRegionName] = useState([]);
    const [regionWiseData, setRegionWiseData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const projectongoing = [];
        const projectkickout = [];
        const projectnotstarted = [];
        const projectcompleted = [];
        const regionname = [];

        const getRegionWiseProjects = async () => {
            
            const payload = { 
                "fy": "",
                'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
                'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
            };
    
            const reqData = await axios.post(`${region_wise_projects_url}`, payload,API_HEADER);
            const myRegionwiseData = reqData.data.data;

            for (let i = 0; i < myRegionwiseData.length; i++) {
                projectongoing.push(myRegionwiseData[i].data.project_ongoing);
                projectkickout.push(myRegionwiseData[i].data.project_kickout);
                projectnotstarted.push(myRegionwiseData[i].data.project_notstarted);
                projectcompleted.push(myRegionwiseData[i].data.project_completed);
                regionname.push(myRegionwiseData[i].description);
            }

            setProjectOngoing(projectongoing);
            setProjectKickout(projectkickout);
            setProjectNotStarted(projectnotstarted);
            setProjectCompleted(projectcompleted);
            setRegionName(regionname);
            setRegionWiseData(myRegionwiseData);

        };

        getRegionWiseProjects();
    }, [search]);

    const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
        const region_status = w.globals.seriesNames[seriesIndex];
        const seriesName = w.globals.labels[dataPointIndex];
        const matchedRegion = regionWiseData.find((region) => region.description === seriesName);

        const title = w.globals.labels[dataPointIndex];

        const region_id = matchedRegion ? matchedRegion.region_id : null;

        if (region_id) {
            navigate('/programwiseprojecttable', { state: { title,region_status, region_id, seriesName } });
        }
    };

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Chart
                    type="bar"
                    width={"100%"}
                    height={400}
                    series={[
                        {
                            name: "Project Ongoing",
                            data: projectOngoing,
                            color: '#E74C3C'
                        },
                        {
                            name: "Project Completed",
                            data: projectCompleted,
                            color: '#7DCEA0'
                        },
                        {
                            name: "Project Kickout",
                            data: projectKickout,
                            color: '#1A5276'
                        },
                        {
                            name: "Project Not Started",
                            data: projectNotStarted,
                            color: '#178ab7'
                        },
                    ]}
                    options={{
                        chart: {
                            toolbar: {
                                show: false,  // This will hide the icons on the top right corner
                              },
                            stacked: false,
                            events: {
                                dataPointSelection: handleChartClick,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '40%',
                                dataLabels: {
                                    total: {
                                        enabled: true,
                                        style: {
                                            fontSize: '11px',
                                            fontWeight: 900
                                        },
                                    }
                                }
                            }
                        },
                        stroke: {
                            width: 1,
                        },
                        xaxis: {
                            title: {
                                text: "Regions",
                                style: {
                                    fontSize: '16px',
                                    fontWeight: 'bold'
                                }
                            },
                            categories: regionName
                        },
                        yaxis: {
                            title: {
                                text: "Number of Projects",
                                style: { fontSize: '16px', fontWeight: 'bold' }
                            },
                            labels: {
                                style: { fontSize: "15px" },
                            }
                        },
                        legend: {
                            position: 'bottom',
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        grid: {
                            show: true,
                            xaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            borderColor: '#f1f1f1',
                            row: {
                                colors: ['transparent'],
                                opacity: 1,
                            },
                        },
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default RegionWiseProjectGraph;
