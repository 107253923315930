import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER, commonproject_graphs_url } from "../../config";
import axios from "axios";

function DealLostchart()
{
   const [dealLostReason, setDealLostReason]= useState([]);
   const [dealLost, setDealLost]= useState([]);

   useEffect( ()=>{
       const lostDealReason=[];
       const lostDeal=[];

       const getlostDealdata= async()=>{
        
       const reqData= await axios.get(`${commonproject_graphs_url}`, API_HEADER);
    
       const mydeallost = reqData.data.deal_lost_reason_wise;
       
       for(let i=0; i< mydeallost.length; i++)
       {
        lostDealReason.push(mydeallost[i].deal_lost_reason);
        lostDeal.push((mydeallost[i].deal_lost));
       }
       setDealLostReason(lostDealReason);
       setDealLost(lostDeal);
       }

    getlostDealdata();

   },[]);

    return(
        <React.Fragment>
            <div className="container-fluid">
               
                <Chart 
                type="pie"
                width={"100%"}
                height={300}

                series={ dealLost }                

                options={{
                       
                       noData:{text:"Empty Data"},                        
                      
                      labels: dealLostReason,      
                      colors:'#78938a'  ,
                      legend:{
                        position: 'bottom'
                    },          

                 }}
                >
                </Chart>
            </div>
        </React.Fragment>
    );
}
export default DealLostchart;