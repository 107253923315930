import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_DOCUMENT } from "../config";
import { get_project_details_url, tl_remotesitevisit_url } from "../config";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Alert } from "antd";
import { API_HEADER } from "../config";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";



const ATSiteVisit = ({ visitType }) => {
  const { id } = useParams();
  //   const { isTL } = props;
  const sitevisittype = { visitType }.visitType;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const [remarkSbu, setRemarkSbu] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkAT, setRemarkAT] = useState("");

  const [action, setAction] = useState("");
  const [error, setError] = useState("");
  const [program, setProgram] = useState([]);
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [fileAuditPlanSizeError, setFileAuditPlanSizeError] = useState(false);
  const [fileAuditSizeError, setFileAuditSizeError] = useState(false);
  const [fileRTFSizeError, setFileRTFSizeError] = useState(false);
  const [fileNovsSizeError, setFileNovsSizeError] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [auditPlanName, setAuditPlanName] = useState(null);
  const [auditPlan, setAuditPlan] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null);
  const [auditFinding, setAuditFinding] = useState("");
  const [rtfDocumentName, setRTFDocumentName] = useState(null);
  const [rtfDocument, setRTFDocument] = useState("");
  const [novsDocumentName, setNOVSDocumentName] = useState(null);
  const [novsDocument, setNOVSDocument] = useState("");
  const [informed_registry, setInformed_registry] = useState(0);
  const [remarkError, setRemarkError] = useState(""); // State for error message

  const [siteVisitMember1, setSiteVisitMember1] = useState("");
  const [siteVisitMember2, setSiteVisitMember2] = useState("");
  const [siteVisitMember3, setSiteVisitMember3] = useState("");
  const [siteVisitMember4, setSiteVisitMember4] = useState("");
  const [siteVisitDateFrom, setSiteVisitDateFrom] = useState("");
  const [siteVisitDateTo, setSiteVisitDateTo] = useState("");
  const [siteVisitType, setSiteVisitType] = useState("");
  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const [formData, setFormData] = useState({
    member_name1: "",
    member_name2: "",
    member_name3: "",
    member_name4: "",
    visit_date_from: "",
    visit_date_to: "",
    informed_registry: "",
  });

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      setFormData({
        ...formData,
        member_name1: record.site_visit_member1,
        member_name2: record.site_visit_member2,
        member_name3: record.site_visit_member3,
        member_name4: record.site_visit_member4,
        visit_date_from: record.site_visit_date_from,
        visit_date_to: record.site_visit_date_to,
        informed_registry: record.informed_registry,
      });
      const getUniqueUsers = (teams) => {
        const uniqueUsers = [];
        const userIds = new Set();

        teams.forEach((team) => {
          if (!userIds.has(team.user_id) && team.user_id !== null && team.user_id !== 0) {
            userIds.add(team.user_id);
            uniqueUsers.push(team);
          }
        });

        return uniqueUsers;
      };

      const { teams } = response.data;

      const uniqueTeams = getUniqueUsers(teams);

      setTeamList(uniqueTeams);
      setProgram(record.program);
      setSiteVisitStatus(record.site_visit_status);
      setRemarkAT((record.site_visit_status == 7 || record.site_visit_status == 5 || record.site_visit_status == 9 || record.site_visit_status == 11) ? record.sbu_at_remarks = "" : record.sbu_at_remarks)
      setRemarkTL(record.sbu_tl_remarks)
      setRemarkSbu(record.sbu_remarks);
      setInformed_registry(record.informed_registry);
      setAction(record.site_visit_status);
      setSiteVisitMember1(record.site_visit_member1);
      setSiteVisitMember2(record.site_visit_member2);
      setSiteVisitMember3(record.site_visit_member3);
      setSiteVisitMember4(record.site_visit_member4);
      setSiteVisitDateFrom(record.site_visit_date_from);
      setSiteVisitDateTo(record.site_visit_date_to);
      setSiteVisitType(record.site_visit_type);
      setFile1(record.site_audit_plan)
      setFile4(record.site_novs_document)

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_audit_plan}`;
      setAuditPlanName(url1);
      setAuditPlan(record.site_audit_plan);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_audit_finding}`;
      setAuditFindingName(url2);
      setAuditFinding(record.site_audit_finding);

      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_rtf_document}`;
      setRTFDocumentName(url3);
      setRTFDocument(record.site_rtf_document);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_novs_document}`;
      setNOVSDocumentName(url4);
      setNOVSDocument(record.site_novs_document);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };


  const handleRemarksChange = (content) => {
    setRemarkAT(content);
    if (!content.trim()) {
      setRemarkError("TL Remarks is required.");
    } else {
      setRemarkError("");
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    const year = today.getFullYear();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };



  const handleToDateChange = (e) => {
    const value = e.target.value;
    setSiteVisitDateTo(value);
  }
  const handleFromDateChange = (e) => {
    const value = e.target.value;
    setSiteVisitDateFrom(value);
  }

  const handleMember1Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name1: selectedValue,
      member_name2:
        formData.member_name2 === selectedValue ? "" : formData.member_name2,
    });
  };

  const handleMember2Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name2: selectedValue,
      member_name1:
        formData.member_name1 === selectedValue ? "" : formData.member_name1,
    });
  };

  const handleMember3Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name3: selectedValue,
    });
  };

  const handleMember4Change = (e) => {
    const selectedValue = e.target.value;
    setFormData({
      ...formData,
      member_name4: selectedValue,
    });
  };

  const navigate = useNavigate();



  const handleSubmitSiteVisit = async (event) => {

    event.preventDefault();
    setIsSubmitting(false);

    if ((remarkAT == null || remarkAT == "<p><br></p>" || remarkAT == "") || (file1 == null || file1 == '')) {
      toast.error("Please upload the document and remarks.");
    } else {
      try {
        // setLoading(true); // Show loader
        setIsSubmitting(true);

        const formDataToSend = new FormData();

        formDataToSend.append("project_id", id);

        formDataToSend.append("site_visit_member1", formData.member_name1);
        formDataToSend.append("site_visit_member2", formData.member_name2);
        formDataToSend.append("site_visit_member3", formData.member_name3);
        formDataToSend.append("site_visit_member4", formData.member_name4);
        formDataToSend.append("site_visit_date_from", siteVisitDateFrom);
        formDataToSend.append("site_visit_date_to", siteVisitDateTo);
        formDataToSend.append("site_audit_plan", file1);
        formDataToSend.append("site_audit_finding", file2);
        formDataToSend.append("site_rtf_document", file3);
        formDataToSend.append("site_novs_document", file4);
        formDataToSend.append("sbu_at_remarks", remarkAT);
        formDataToSend.append("site_visit_status", action);

        if (siteVisitStatus >= 0 && siteVisitStatus < 5) {
          formDataToSend.append("site_visit_type", sitevisittype);
        }
        else {
          formDataToSend.append("site_visit_type", siteVisitType);
        }

        const response = await axios.post(
          `${tl_remotesitevisit_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (
          fileAuditPlanSizeError ||
          fileAuditSizeError ||
          fileRTFSizeError ||
          fileNovsSizeError
        ) {
          toast.error(`File size must below ${dynamicMaxSize}MB`);
        } else if (error) {
          toast.error("Invalid Dates");
        } else {
          toast.success("Document Submitted Successfully");
          setFile1(null);
          setFile2(null);
          setFile3(null);
          setFile4(null);
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Please fill all the mandatory fields");
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(true);

      }
    }
  };

  return (
    <>
      {siteVisitStatus == 7 || siteVisitStatus == 0 || siteVisitStatus == 9 || siteVisitStatus == 11 ? (
        <>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
          <form
            method="post"
            onSubmit={handleSubmitSiteVisit}
            encType="multipart/form-data"
          >
            {siteVisitStatus > 0 && siteVisitType == 1 ? (
              <h4 className="head-center my-3">Physical Site Visit</h4>
            ) : siteVisitStatus > 0 && siteVisitType == 2 ? (
              <h4 className="head-center my-3">Remote Site Visit </h4>
            ) : (
              ""
            )}
            <div className="container">
              <div className="row mb-2 mt-4 ">
                <div className="col-6 mb-3">
                  <label
                    htmlFor="visit Date"
                    className="form-label lightgreen fs-6"
                  >
                    Site Visit Date From<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="visitdatefrom"
                    required
                    name="visit_date_from"
                    value={siteVisitDateFrom}
                    onChange={handleFromDateChange}
                    min={getCurrentDate()} // Set the min attribute to disable past dates
                  />
                </div>
                <div className="col-6 mb-3">
                  <label
                    htmlFor="visit Date"
                    className="form-label lightgreen fs-6"
                  >
                    Site Visit Date To<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="visitdateto"
                    required
                    name="visit_date_to"
                    value={siteVisitDateTo}
                    // onChange={handleToDateChange}
                    // min={getCurrentDate()} // Set the min attribute to disable past dates

                    onChange={siteVisitDateFrom ? handleToDateChange : undefined} // Only allow changing when startDate is set
                    min={siteVisitDateFrom ? siteVisitDateFrom : undefined} // Set min to startDate if startDate is set
                    disabled={!siteVisitDateFrom}
                  />
                  {/* {error && <div className="text-danger">{error}</div>} */}
                </div>
              </div>

              <div className="row mb-2 ">
                <div className="col-6 mb-3">
                  <label htmlFor="member1" className="form-label lightgreen fs-6">
                    Member 1<span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member1"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member1"
                    // value={siteVisitMember1}
                    value={formData.member_name1}
                    onChange={handleMember1Change}
                    required
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option
                            key={option.id}
                            value={option.user_id}
                            disabled={option.user_id === formData.member_name2}
                          >
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>
                <div className="col-6  mb-3">
                  <label htmlFor="member2" className="form-label lightgreen fs-6">
                    Member 2
                  </label>

                  <select
                    id="member2"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member2"
                    // value={siteVisitMember2}
                    value={formData.member_name2}
                    onChange={handleMember2Change}
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option
                            key={option.id}
                            value={option.user_id}
                            disabled={option.user_id === formData.member_name1}
                          >
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>

                <div className="col-6  mb-3">
                  <label htmlFor="member3" className="form-label lightgreen fs-6">
                    Member 3
                  </label>

                  <select
                    id="member3"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member3"
                    // value={siteVisitMember2}
                    value={formData.member_name3}
                    onChange={handleMember3Change}
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option key={option.id} value={option.user_id}>
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>

                <div className="col-6  mb-3">
                  <label htmlFor="member4" className="form-label lightgreen fs-6">
                    Member 4
                  </label>

                  <select
                    id="member4"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member4"
                    // value={siteVisitMember2}
                    value={formData.member_name4}
                    onChange={handleMember4Change}
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option key={option.id} value={option.user_id}>
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>


              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color ">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                      Document Name
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                      Upload Document
                    </th>
                    {siteVisitStatus > 7 ? (
                      <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                        Uploaded Document
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>
                    {/* {siteVisitStatus == 7 ? (
                    <td className="text-center lightgreen p-3">Audit Plan</td>
                  ) : (
                    <td className="text-center lightgreen p-3">
                      Audit Plan
                      <span style={{ color: "red" }}>*</span>
                    </td>
                  )} */}

                    <td className="text-center lightgreen p-3">
                      Audit Plan
                      <span style={{ color: "red" }}>*</span>
                    </td>


                    {/* {siteVisitStatus == 7 ? (
                    
                    <FileUploaderComponent file={file1} setFile={setFile1} />

                  ) : (
                    
                    <FileUploaderComponent file={file1} setFile={setFile1} />

                  )} */}
                    <FileUploaderComponent file={file1} setFile={setFile1} />



                    {siteVisitStatus > 7 ? (
                      <td>
                        <a href={auditPlanName} target="_blank">
                          {auditPlan}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">Audit Finding</td>



                    <FileUploaderComponent file={file2} setFile={setFile2} />

                    {siteVisitStatus > 7 ? (
                      <td>
                        <a href={auditFindingName} target="_blank">
                          {auditFinding}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      TRF (Travel Request Form)
                    </td>


                    <FileUploaderComponent file={file3} setFile={setFile3} />


                    {siteVisitStatus > 7 ? (
                      <td>
                        <a href={rtfDocumentName} target="blank">
                          {rtfDocument}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <td class="text-center">4</td>
                    {/* {program == "2" && siteVisitStatus != 7 ? (
                    <td className="text-center lightgreen p-3">
                      NOVS
                      <span style={{ color: "red" }}>*</span>
                    </td>
                  ) : (
                    <td className="text-center lightgreen p-3">NOVS</td>
                  )} */}

                    <td className="text-center lightgreen p-3">
                      NOVS
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>


                    {/* {program == "2" && siteVisitStatus != 7 ? (
                   

                    <FileUploaderComponent file={file4} setFile={setFile4} />

                  ) : (
                   

                    <FileUploaderComponent file={file4} setFile={setFile4} />

                  )} */}
                    <FileUploaderComponent file={file4} setFile={setFile4} />



                    {siteVisitStatus > 7 ? (
                      <td>
                        <a href={novsDocumentName} target="_blank">
                          {novsDocument}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                </tbody>
              </table>

              <div className="row " style={{ width: "80vw" }}>
                <div className="col-3 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    onChange={(e) => {
                      setAction(e.target.value);
                    }}
                    required
                    style={{ width: "200px" }}
                    value={action}
                  >
                    <option value=''>Select</option>


                    <option value={8}>Forward to TL</option>

                  </select>
                </div>
              </div>

              <p className="lightgreen fw-bold fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>

              <hr className="mb-4 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  onChange={handleRemarksChange}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>

              {remarkTL &&
                <div>
                  <p className="lightgreen fw-bold fs-6">
                    Team Leader Remarks
                  </p>

                  <hr className="mb-4 lightgreen" />
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={remarkTL}
                      name="remarks"
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              }

              {remarkSbu &&
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">SBU Remarks</p>
                  <hr className="mb-3 lightgreen" />

                  <div className="row mt-4">
                    <span>
                      <Alert
                        className="col-12"
                        message="Clarification Required"
                        banner
                      />
                    </span>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <ReactQuill
                        theme="snow"
                        value={remarkSbu}
                        name="remarks"
                        readOnly={true}
                        dangerouslySetInnerHTML={{ __html: remarkSbu }}
                        style={{
                          height: "20vh",
                          marginBottom: "2rem",
                        }}
                        className="mb-5"
                      />
                    </div>
                  </div>
                </div>}


              <div className="row">
                <div className="d-flex mt-4 justify-content-end col-12">
                  <button
                    className="btn fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {loading && <div className="loader"></div>}
          </form>
        </>
      )

        :

        (
          <form
            method="post"
            onSubmit={handleSubmitSiteVisit}
            encType="multipart/form-data"
          >
            {siteVisitStatus > 0 && siteVisitType == 1 ? (
              <h4 className=" my-3 head-center">Physical Site Visit</h4>
            ) : siteVisitStatus > 0 && siteVisitType == 2 ? (
              <h4 className=" my-3 head-center">Remote Site Visit </h4>
            ) : (
              ""
            )}
            <div className="container">
              <div className="row mb-2">
                <div className="col-6 mb-3">
                  <label
                    htmlFor="visit Date"
                    className="form-label lightgreen fs-6"
                  >
                    Site Visit Date From<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="visitdatefrom"
                    disabled
                    name="visit_date_from"
                    value={siteVisitDateFrom}
                  // onChange={handleFromDateChange}
                  />
                </div>
                <div className="col-6 mb-3">
                  <label
                    htmlFor="visit Date"
                    className="form-label lightgreen fs-6"
                  >
                    Site Visit Date To<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="visitdateto"
                    disabled
                    name="visit_date_to"
                    value={siteVisitDateTo}
                  // onChange={handleToDateChange}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6 mb-3">
                  <label htmlFor="member1" className="form-label lightgreen fs-6">
                    Member 1<span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member1"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member1"
                    disabled
                    value={siteVisitMember1}
                    onChange={handleMember1Change}
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option
                            key={option.id}
                            value={option.user_id}
                            disabled={option.user_id === formData.member_name2}
                          >
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>
                <div className="col-6  mb-3">
                  <label htmlFor="member2" className="form-label lightgreen fs-6">
                    Member 2
                  </label>

                  <select
                    id="member2"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member2"
                    disabled
                    value={siteVisitMember2}
                    onChange={handleMember2Change}
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option
                            key={option.id}
                            value={option.user_id}
                            disabled={option.user_id === formData.member_name1}
                          >
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>

                <div className="col-6  mb-3">
                  <label htmlFor="member3" className="form-label lightgreen fs-6">
                    Member 3
                  </label>

                  <select
                    id="member3"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member3"
                    // value={siteVisitMember2}
                    disabled
                    value={formData.member_name3}
                    onChange={handleMember3Change}
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option
                            key={option.id}
                            value={option.user_id}
                            disabled={option.user_id === formData.member_name1}
                          >
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>

                <div className="col-6  mb-3">
                  <label htmlFor="member2" className="form-label lightgreen fs-6">
                    Member 4
                  </label>

                  <select
                    id="member4"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="member4"
                    disabled
                    // value={siteVisitMember2}
                    value={formData.member_name4}
                    onChange={handleMember4Change}
                  >
                    <option value={""}>Select</option>
                    {teamList.map(
                      (option) =>
                        option.user_id !== null && (
                          <option
                            key={option.id}
                            value={option.user_id}
                            disabled={option.user_id === formData.member_name1}
                          >
                            {option.user_name?.name}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>


              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                      Uploaded Document
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>
                    <td className="text-center lightgreen p-3">
                      Audit Plan
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={auditPlanName} target="_blank">
                        {auditPlan}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">Audit Finding</td>

                    <td>
                      <a href={auditFindingName} target="_blank">
                        {auditFinding}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      TRF (Travel Request Form)
                    </td>

                    <td>
                      <a href={rtfDocumentName} target="_blank">
                        {rtfDocument}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">4</td>
                    {/* {program == "2" ? (
                      <td className="text-center lightgreen p-3">
                        NOVS
                        <span style={{ color: "red" }}>*</span>
                      </td>
                    ) : (
                      <td className="text-center lightgreen p-3">NOVS</td>
                    )} */}
                    <td className="text-center lightgreen p-3">
                      NOVS
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </td>
                    <td>
                      <a href={novsDocumentName} target="_blank">
                        {novsDocument}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="row " style={{ width: "80vw" }}>
                <div className="col-3 my-4">
                  <label
                    htmlFor="Member"
                    className="form-label lightgreen mb-4 fs-6"
                  >
                    Send To
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    id="member"
                    className="form-select borderlightgreen form-select-sm"
                    aria-label="Default select example"
                    name="status"
                    disabled
                    style={{ width: "200px" }}
                    value={action}
                  >
                    <option value=''>Select</option>
                    <option value={8}>Forward to TL</option>
                    <option value={12}>Submit Site Visit</option>
                  </select>
                </div>
              </div>


              <p className="lightgreen fw-bold fs-6">
                Team Member Remarks<span style={{ color: "red" }}>*</span>
              </p>

              <hr className="mb-4 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  readOnly={true}

                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>

              {remarkTL &&
                <div>
                  <p className="lightgreen fw-bold fs-6">
                    Team Leader Remarks
                  </p>

                  <hr className="mb-4 lightgreen" />
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={remarkTL}
                      name="remarks"
                      readOnly={true}
                      style={{
                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>
                </div>
              }

              {remarkSbu &&
                <div>
                  <p className="lightgreen fw-bold my-3 fs-6">SBU Remarks</p>
                  <hr className="mb-3 lightgreen" />
                  <div className="row mt-4 mb-4">
                    <span >
                      {siteVisitStatus === 12 ? (
                        <Alert className="col-12" message="Approved" type="success" showIcon />
                      ) : siteVisitStatus === 11 ? (
                        <Alert className="col-12" message="Clarification Required" banner />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <ReactQuill
                        theme="snow"
                        value={remarkSbu}
                        name="remarks"
                        readOnly={true}
                        dangerouslySetInnerHTML={{ __html: remarkSbu }}
                        style={{
                          height: "20vh",
                          marginBottom: "2rem",
                        }}
                        className="mb-5"
                      />
                    </div>
                  </div>
                </div>}
            </div>
            {loading && <div className="loader"></div>}
          </form>
        )}
    </>
  );
};

export default ATSiteVisit;
