
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, md_kickout_action_url } from "../../../config";
import { get_KickoutInfo_url } from "../../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../../../config";
import LoaderComponent from "../../../utlis/LoderComponent";





const MdKickoutRFIRound = (props) => {

  const { id } = useParams();
  const navigate = useNavigate();


  const [kickoutStatus, setkickoutStatus] = useState("");
  const [kickoutId, setKickoutId] = useState('');
  const [remarkMD, setRemarkMD] = useState("");
  const [remarkTM, setRemarkTM] = useState("");
  const [action, setAction] = useState("");
  const [fvrName, setFVRName] = useState(null)
  const [fvr, setFvr] = useState("")
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null)
  const [auditFinding, setAuditFinding] = useState("")
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");
  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);



  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { kickout } = response.data;

      setKickoutId(record.kickout_id)
      setkickoutStatus(record.kickout_status);

      setRemarkMD(kickout.rfi_mdremarks);
      setRemarkMD((kickout.kickout_status == 29) ? kickout.rfi_mdremarks = "" : kickout.rfi_mdremarks)
      setRemarkTM(kickout.rfi_tmremarks);

      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.rfi_fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_err_document}`;
      setERName(url2);
      setER(kickout.rfi_err_document);


      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.rfi_pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.rfi_cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_irr_document}`;
      setRRName(url5);
      setRR(kickout.rfi_irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.rfi_audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_other_docs}`;
      setOtherName(url7);
      setOther(kickout.rfi_other_docs);


    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };

  const handleRemarksChange = (content) => {
    setRemarkMD(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };


  const handleSubmitAction = async (e) => {
    e.preventDefault();
    setIsSubmitting(false);


    if ((remarkMD == null || remarkMD == '<p><br></p>' || remarkMD == '') && action) {
      toast.error('Please Add Some Remarks')
    }
    else {

      try {

        // setLoading(true);
        setIsSubmitting(true);


        let payload = {
          project_id: id,
          kickout_status: action,
          rfi_mdremarks: remarkMD,
          kickout_id: kickoutId
        };
        const response = await axios.post(
          `${md_kickout_action_url}`,
          payload,
          API_HEADER
        );
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Document Submitted Successfully");
          navigate("/dashboard");
          setRemarkMD("");
          setAction("");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      } finally {
        // setLoading(false);
        setIsSubmitting(false);

      }

    }
  };

  return (
    <>
      <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

      <form
        method="post"
        encType="json/form-data"
        onSubmit={handleSubmitAction}
      >
        <table className="table table-bordered  table-hover">
          <thead>
            <tr>
              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color ">
                S.No
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Document Name
              </th>

              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Uploaded Document
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">1</td>

              <td className="text-center lightgreen p-3">
                FVR Report
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={fvrName} target="_blank">{fvr}</a>
              </td>

            </tr>

            <tr>
              <td class="text-center">2</td>

              <td className="text-center lightgreen p-3">
                ER
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={erName} target="_blank">{er}</a>
              </td>

            </tr>

            <tr>
              <td class="text-center">3</td>

              <td className="text-center lightgreen p-3">
                PDD/MR
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={pddName} target="_blank">{pdd}</a>
              </td>

            </tr>

            <tr>
              <td class="text-center">4</td>

              <td className="text-center lightgreen p-3">
                IRR
              </td>

              <td>
                <a href={rrName}>{rr}</a>
              </td>

            </tr>

            <tr>
              <td class="text-center">5</td>

              <td className="text-center lightgreen p-3">
                CPA
              </td>

              <td>
                <a href={cpaName}>{cpa}</a>
              </td>


            </tr>

            <tr>
              <td class="text-center">6</td>

              <td className="text-center lightgreen p-3">
                Audit Finding
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={auditFindingName} target="_blank">{auditFinding}</a>
              </td>
            </tr>


            <tr>
              <td class="text-center">7</td>


              <td className="text-center lightgreen p-3">
                Other Document
              </td>

              <td>
                <a target="_blank" href={otherName}>
                  {other}
                </a>
              </td>

            </tr>

          </tbody>
        </table>
        {kickoutStatus === 29 ? (
          <>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTM}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">MD Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="approve"
                    name="mdremark"
                    value={31}
                    onChange={handleActionChange}
                    className=" text-success"
                  />
                  <label className="text-success mx-1" htmlFor="approve">
                    Approved
                  </label>
                </span>

                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="clarification_required"
                    name="mdremark"
                    value={30}
                    onChange={handleActionChange}
                    className="text-danger "
                  />
                  <label
                    className="text-danger mx-1"
                    htmlFor="clarification_required"
                  >
                    Clarification Required
                  </label>
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkMD}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkMD }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              <div className="container  d-flex justify-content-end pr-0">
                <button
                  className="btn my-4 px-3 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div >
                <ReactQuill
                  theme="snow"
                  value={remarkTM}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">MD Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">
                <span>
                  {kickoutStatus === 31 ? (
                    <Alert
                      className="col-12"
                      message="Approved"
                      type="success"
                      showIcon
                    />
                  ) : kickoutStatus === 30 ? (
                    <Alert
                      className="col-12"
                      message="Clarification Required"
                      banner
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkMD}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkMD }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {loading && <div className="loader"></div>}
      </form>
    </>
  );
};

export default MdKickoutRFIRound;
