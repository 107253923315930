import React from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { API_HEADER, get_project_details_url, get_remote_sitevisit_reasons_url, tm_support_remotesitevisit_url, BASE_DOCUMENT } from "../../config";
import { useState, useEffect } from 'react';
import { Alert } from "antd";
import LoaderComponent from "../../utlis/LoderComponent";


const RemoteSiteVisitTM = () => {

    const { id } = useParams();

    const [siteVisitStatus, setSiteVisitStatus] = useState("")
    const [remoteSiteVisitRemarks, setRemoteSiteVisitRemarks] = useState("");
    const [remoteSiteVisitReasons, setRemoteSiteVisitReasons] = useState("");
    const [myRemoteSiteVisitReasons, setMyRemoteSiteVisitReasons] = useState([]);
    const [remarkTM, setRemarkTM] = useState("")
    const [action, setAction] = useState("");
    const [remarkTMS, setRemarkTMS] = useState("")
    const [siteVisitDateFrom, setSiteVisitDateFrom] = useState("")
    const [siteVisitDateTo, setSiteVisitDateTo] = useState("")
    const [error, setError] = useState('')
    const [siteVisitType, setSiteVisitType] = useState('')
    const [loading, setLoading] = useState(false);


    const [osbExemptionName, setosbExemptionName] = useState(null);
    const [osbExemption, setosbExemption] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);


    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${ids}`,
                API_HEADER
            );
            const { record } = response.data;

            setRemarkTMS(record.tms_remote_remarks)
            setRemarkTM(record.tm_remote_remarks)
            setRemoteSiteVisitRemarks(record.sbu_tl_remarks)
            setSiteVisitStatus(record.site_visit_status)
            setSiteVisitDateFrom(record.site_visit_date_from)
            setSiteVisitDateTo(record.site_visit_date_to)
            setRemoteSiteVisitReasons(record.remote_visit_reason)
            setSiteVisitType(record.site_visit_type)

            let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.osb_exemption}`;
            setosbExemptionName(url1);
            setosbExemption(record.osb_exemption);

        } catch (error) { }
    };

    useEffect(() => {
        fetchData(id);
    }, [id]);

    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };


    const handleRemarksChange = (content) => {
        setRemarkTM(content);
    };


    const handleActionChange = (e) => {
        setAction(e.target.value);
    };

    useEffect(() => {
        const fetchRemoteSiteVisitReasonsData = async () => {
            try {
                const responseremotesitevisit = await axios.get(`${get_remote_sitevisit_reasons_url}`);
                setMyRemoteSiteVisitReasons(responseremotesitevisit.data.data);
            } catch (error) {
            }
        };

        fetchRemoteSiteVisitReasonsData();
    }, []);


    const navigate = useNavigate();

    const handleSubmitAction = async (e) => {
        e.preventDefault()
        setIsSubmitting(false);

        if ((remarkTM == null || remarkTM == '<p><br></p>' || remarkTM == '') && action) {
            toast.error('Please Add Some Remarks')
        }
        else {
            try {
                // setLoading(true); // Show loader
                setIsSubmitting(true);

                let payload = {
                    project_id: id,
                    site_visit_status: action,
                    tm_remote_remarks: remarkTM,
                };
                const response = await axios.post(
                    `${tm_support_remotesitevisit_url}`,
                    payload,
                    API_HEADER
                );
                if (!response.data.status) {
                    toast.error(response.data.message);
                }
                else {
                    toast.success("Document Submitted Successfully");
                    navigate("/dashboard");
                    setRemarkTM("")
                    setAction("")
                }

            } catch (error) {
                console.error("Error:", error);
                toast.error(error);
            }
            finally {
                // setLoading(false);
                setIsSubmitting(false);

            }
        }

    };

    const getCurrentDate = () => {
        const today = new Date();
        let month = today.getMonth() + 1;
        let day = today.getDate() + 1;
        const year = today.getFullYear();

        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }

        return `${year}-${month}-${day}`;
    };



    const validateDates = (dateFrom, dateTo) => {
        if (dateTo && dateFrom && dateTo <= dateFrom) {
            setError("Visit date To' must be greater than or equal to 'From Visit Date");
        } else {
            setError('');
        }
    };


    const handleFromDateChange = (e) => {

        const value = e.target.value;
        const selectedDate = new Date(value);
        const currentDate = new Date();
        if (selectedDate > currentDate) {
            setSiteVisitDateFrom(value)
            validateDates(value, siteVisitDateTo);

        } else {
            setError("Selected date must be greater than the current date.");
        }
    };

    const handleToDateChange = (e) => {
        const value = e.target.value;
        const selectedDate = new Date(value);
        const currentDate = new Date();
        const fromDate = new Date(siteVisitDateFrom);
        if (selectedDate > currentDate && selectedDate > fromDate) {
            setSiteVisitDateTo(value)
            validateDates(siteVisitDateFrom, value);
        } else {
            setError("Visit date To must be greater than or equal to From Visit Date");
        }
    };


    return (

        <>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

            <div className="container">

                {siteVisitStatus > 0 && siteVisitType == 1 ? (
                    <h4 className="my-3 head-center">Physical Site Visit</h4>
                ) : siteVisitStatus > 0 && siteVisitType == 2 ? (
                    <h4 className="my-3 head-center">Remote Site Visit </h4>
                ) : (
                    ""
                )}


                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mb-3 my-4">
                        <label htmlFor="deallostreason" className="form-label lightgreen fs-6">
                            Reasons For Remote Site Visit<span style={{ color: "red" }}>*</span>
                        </label>

                        <textarea rows="4" cols="50"
                            type="text"
                            className={`form-control borderlightgreen`}
                            id="remote_site_visit_reasons"
                            required
                            name="remote_site_visit_reasons"
                            value={remoteSiteVisitReasons}
                            // onChange={handleSiteVisitReason}
                            disabled
                        />

                    </div>


                    <div className="col-lg-6 col-md-6 col-12 mb-3 my-4">
                        <label
                            htmlFor="remotesitevisitreasons"
                            className="form-label lightgreen fs-6"
                        >
                            OSV Exemption
                            <span style={{ color: "red" }}>*</span>
                        </label>

                        <div>
                            <a href={osbExemptionName} target="_blank">
                                {osbExemption}
                            </a>
                        </div>


                    </div>
                </div>

                <div className="row mb-2 mt-4 ">
                    <div className="col-6 mb-3">
                        <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                            Site Visit Date From<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="date"
                            className={`form-control borderlightgreen`}
                            id="visitdatefrom"
                            required
                            disabled
                            name="visit_date_from"
                            value={siteVisitDateFrom}
                            onChange={handleFromDateChange}
                            min={getCurrentDate()}
                        />

                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                            Site Visit Date To<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="date"
                            className={`form-control borderlightgreen`}
                            id="visitdateto"
                            required
                            disabled
                            name="visit_date_to"
                            value={siteVisitDateTo}
                            onChange={handleToDateChange}
                            min={getCurrentDate()}
                        />
                        {error && <div className="text-danger">{error}</div>}
                    </div>

                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <label htmlFor="remotesitevisitremarkstl" className="form-label lightgreen fs-6">
                            Team Leader Remarks
                        </label>

                        <ReactQuill
                            theme="snow"
                            value={remoteSiteVisitRemarks}
                            name="remotesitevisitremarkstl"
                            readOnly={true}
                            dangerouslySetInnerHTML={{ __html: remoteSiteVisitRemarks }}

                            style={{
                                height: "20vh",
                                marginBottom: "2rem",
                            }}
                            className="mb-5"
                        />

                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <label htmlFor="remotesitevisitremarkstms" className="form-label lightgreen fs-6">
                            TM Support Remarks
                        </label>
                        <hr />
                        <div className="row mt-4 mb-4">
                            <span >
                                {siteVisitStatus === 5 ? (
                                    <Alert className="col-12" message="Approved" type="success" showIcon />
                                ) : siteVisitStatus === 4 ? (
                                    <Alert className="col-12" message="Clarification Required" banner />
                                ) : (
                                    ''
                                )}
                            </span>
                        </div>

                        <div className="row mt-4 mb-4">
                            <ReactQuill
                                theme="snow"
                                value={remarkTMS}
                                name="remotesitevisitremarkstms"
                                readOnly={true}
                                dangerouslySetInnerHTML={{ __html: remarkTMS }}


                                style={{
                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>
                </div>



                {siteVisitStatus == 5 ?

                    <div className='row'>
                        <div className="col-12">
                            <p className="lightgreen fw-bold mt-4 fs-6">
                                TM Remarks<span className="text-danger">*</span>
                            </p>
                            <hr className="mb-3 lightgreen" />
                            <div className="row mt-4">
                                <span className="col-10 col-lg-4 col-md-4">
                                    <input
                                        type="radio"
                                        id="approve"
                                        name="tm_clarif"
                                        value={7}
                                        onChange={handleActionChange}
                                        className=" text-success"
                                    />
                                    <label className="text-success mx-1" htmlFor="approve">Approved</label>
                                </span>

                                <span className="col-10 col-lg-4 col-md-4">
                                    <input
                                        type="radio"
                                        id="clarification_required"
                                        name="tm_clarif"
                                        value={6}
                                        onChange={handleActionChange}
                                        className="text-primary "
                                    />
                                    <label className="text-primary mx-1" htmlFor="clarification_required">
                                        Clarification Required
                                    </label>
                                </span>

                            </div>
                            <div className="row mt-4">
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        value={remarkTM}
                                        name="remarkSbu"
                                        onChange={handleRemarksChange}
                                        style={{
                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>

                            </div>


                            <div className="container my-2 d-flex justify-content-end pr-0">
                                <button className="btn my-4 px-3 fs-5"
                                    style={{
                                        backgroundColor: "#07b6af",
                                        color: "white",
                                    }}
                                    onClick={handleSubmitAction}
                                    type="submit">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                    :

                    <div className='row'>
                        <div className="col-12">
                            <p className="lightgreen fw-bold my-3 fs-6">
                                TM Remarks
                            </p>
                            <hr className="mb-3 lightgreen" />


                            <div className="row mt-4 mb-4">
                                <span >
                                    {siteVisitStatus === 7 ? (
                                        <Alert className="col-12" message="Approved" type="success" showIcon />
                                    ) : siteVisitStatus === 6 ? (
                                        <Alert className="col-12" message="Clarification Required" banner />
                                    ) :
                                        ''
                                    }
                                </span>

                            </div>
                            <div className="row mt-4">

                                <ReactQuill
                                    theme="snow"
                                    value={remarkTM}
                                    name="remotesitevisitremarkstms"
                                    readOnly={true}
                                    dangerouslySetInnerHTML={{ __html: remarkTM }}
                                    // onChange={handleRemarksChange}
                                    style={{
                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>

    )
}

export default RemoteSiteVisitTM