import React from 'react';
import { Progress } from 'antd';

const LoaderComponent = ({ isSubmitting, percent }) => {
  if (!isSubmitting) return null;

  return (
    <div className="overlay">
      <Progress type="circle" percent={percent} />
    </div>
  );
};

export default LoaderComponent;
