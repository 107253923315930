import React, { useEffect, useState } from "react";
const ExcelJS = require("exceljs");

const ExcelFile = () => {
  const [data, setData] = useState([]);
  useEffect(() => {

  }, []);
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.mergeCells("A1:F2");
    const titleRow = sheet.getRow(1);
    titleRow.getCell(1).value = "My Excel Sheet";
    titleRow.getCell(1).font = {
      bold: true,
      size: 18,
    };
   
    titleRow.alignment = { horizontal: "center" };
    const headingRow = sheet.getRow(3);
    headingRow.getCell(1).value = "ID";
    headingRow.getCell(1).font = {
      bold: true,
      size: 14,
    };
    headingRow.getCell(2).value = "Title";
    headingRow.getCell(2).font = {
      bold: true,
      size: 14,
    };
    headingRow.getCell(3).value = "Brand";
    headingRow.getCell(3).font = {
      bold: true,
      size: 14,
    };
    headingRow.getCell(4).value = "Category";
    headingRow.getCell(4).font = {
      bold: true,
      size: 14,
    };
    headingRow.getCell(5).value = "Price";
    headingRow.getCell(5).font = {
      bold: true,
      size: 14,
    };
    headingRow.getCell(6).value = "Rating";
    headingRow.getCell(6).font = {
      bold: true,
      size: 14,
    };

    sheet.columns = [
    
      {
        header: "Id",
        key: "id",
        width: 10,
      },
      {
        header: "Title",
        key: "title",
        width: 35,
      },
      {
        header: "Brand",
        key: "brand",
        width: 25,
      },
      {
        header: "Category",
        key: "category",
        width: 20,
      },
      {
        header: "Price",
        key: "price",
        width: 10,
      },
      {
        header: "Project Details",
        key: "rating",
        width: 10,
      },
     
    ];
        data?.products?.map((product, index) => {
            const rowNumber = index + 4;
            sheet.addRow({
              id: product?.id,
              title: product?.title,
              brand: product?.brand,
              category: product?.category,
              price: product?.price,
              rating: product?.rating,
            });
          });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "download.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <div style={{ padding: "30px" }}>
      <button
        className="btn btn-primary float-end mt-2 mb-2"
        onClick={exportExcelFile}
      >
        Export
      </button>
      <h3>Table Data:</h3>
      <table className="table table-bordered">
        <thead style={{ background: "yellow" }}>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Title</th>
            <th scope="col">Brand</th>
            <th scope="col">Category</th>
            <th scope="col">Price</th>
            <th scope="col">Rating</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data?.products) &&
            data?.products?.map((row) => (
              <tr>
                <td>{row?.id}</td>
                <td>{row?.title}</td>
                <td>{row?.brand}</td>
                <td>{row?.category}</td>
                <td>${row?.price}</td>
                <td>{row?.rating}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default ExcelFile;