import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
const ThankPage = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center d-flex align-items-center mx-auto" style={{height:'100vh'}}>
        <div className="col-lg-6 col-md-12 col-12 text-center">
          <FontAwesomeIcon icon={faCircleCheck} size='2xl'  style={{color: "#63E6BE",}} className=' display-1 text-center mb-3' />
          <h3 className='text-center text-capitalize mx-auto text-earthood display-lg-3 display-md-4 display-6 fw-bolder mb-3'>thanks for submitting !</h3>
          <p className='text-center text-capitalize text-secondary mx-auto text-wrap'>you will receive an email with further instructions soon</p>
        </div>
      </div>
    </div>
  )
}
export default ThankPage