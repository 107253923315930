import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Form, Tabs, Select, Upload, message, Input, Alert, Spin } from "antd";
import axios from "axios";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { site_finding_url, get_project_details_url, API_HEADER, BASE_DOCUMENT, client_sitefinding_url } from "../config";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";



const fileTypes = [
    "JPG",
    "JPEG",
    "PDF",
    "RAR",
    "XLS",
    "XLSX",
    "DOC",
    "DOCX",
    "ZIP",
    "XLSM"
];

const ClientSiteFinding = (props) => {

    const { id } = useParams();
    const { isTL } = props;

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size

    const navigate = useNavigate();

    const designation_id = sessionStorage.getItem("designation_id");
    const [projectid, setProjectId] = useState(null);


    const [remark, setRemark] = useState("");
    const [clientRemark, setClientRemark] = useState("");
    const [member, setMember] = useState();
    const [fileSiteFindingSizeError, setFileSiteFindingSizeError] = useState(false);
    const [filesitefinding, setFileSiteFinding] = useState('')
    const [siteVisitFindingClient, setSiteVisitFindingClient] = useState("");
    const [siteVisitFindingClientName, setSiteVisitFindingClientName] = useState(null);
    const [siteVisitFindingTl, setSiteVisitFindingTl] = useState("");
    const [siteVisitFindingTlName, setSiteVisitFindingTlName] = useState(null);
    const [siteFindingStatus, setSiteFindingStatus] = useState(null);


    const [filePDDSizeError, setFilePDDSizeError] = useState(false);
    const [fileERSizeError, setFileERSizeError] = useState(false);
    const [fileMRSizeError, setFileMRSizeError] = useState(false);
    const [fileIRRSizeError, setFileIRRSizeError] = useState(false);
    const [fileOtherSizeError, setFileOtherSizeError] = useState(false);
    const [fileCPASizeError, setFileCPASizeError] = useState(false);
    const [file3, setFile3] = useState('');
    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file4, setFile4] = useState('');
    const [file5, setFile5] = useState('');
    const [file6, setFile6] = useState('');

    const [pddName, setPddName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setErName] = useState(null);
    const [er, setEr] = useState("");
    const [mrName, setMrName] = useState(null);
    const [mr, setMr] = useState("");
    const [cpaName, setcpaName] = useState(null);
    const [cpa, setcpa] = useState("");

    const [irrName, setirrName] = useState(null);
    const [irr, setIrr] = useState("");
    const [otherName, setOtherName] = useState(null);
    const [other, setOther] = useState("");
    const [scope, setScope] = useState('')


    const [formData, setFormData] = useState({});
    const [rowCount, setRowCount] = useState(1);
    const [files, setFiles] = useState([]);
    const [multifileSizeError, setMultiFileSizeError] = useState(false);
    const [dynamicname, setDynamicName] = useState([]);
    const [dynamic, setDynamic] = useState([]);
    const [Findingfile, setFindingFile] = useState(null);

    const [loading, setLoading] = useState(false);

    const [siteFindingRemark, setSiteFindingRemark] = useState(null)
    const [fileErrors, setFileErrors] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);



    const fetchFindingData = async (id) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${id}`,
                API_HEADER
            );
            const { record } = response.data;


            if (response && response.data && response.data.record) {
                const { record } = response.data;

                setFormData({
                    evidence_name: record.evidences || [],
                    files: record.evidences,

                });



                setSiteFindingStatus(record.site_finding_status)
                setSiteFindingRemark(record.site_finding_client_remarks)
                setFileSiteFinding(record.site_finding_client_document)
                setProjectId(record.id);
                setScope(record.scope_name.sector_name)
                setRemark(record.site_finding_tl_remarks)
                // setClientRemark(record.site_finding_client_remarks)
                setClientRemark((record.site_finding_status == 4) ? record.site_finding_client_remarks = "" : record.site_finding_client_remarks)

                setRowCount(record.evidences ? (record.evidences.length == 0 ? 1 : record.evidences.length) : 1);
                setFindingFile(record.finding_document);


                let findingUrlclient = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_finding_client_document}`;
                setSiteVisitFindingClientName(findingUrlclient);
                setSiteVisitFindingClient(record.site_finding_client_document);

                let findingUrltl = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_finding_document}`;
                setSiteVisitFindingTlName(findingUrltl);
                setSiteVisitFindingTl(record.site_finding_document);



                let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;
                setPddName(url4);
                setPdd(record.pdd_documents);

                let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;
                setErName(url5);
                setEr(record.er_documents);

                let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;
                setMrName(url6);
                setMr(record.mr_documents);

                let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
                setirrName(url7);
                setIrr(record.irr_documents);

                let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
                setcpaName(url8);
                setcpa(record.cpa_documents);

                let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.other_documents}`;
                setOtherName(url9);
                setOther(record.other_documents);



                record.evidences.map((item, i) => {

                    setFiles(item.name);
                });
                setFiles(record.evidences);



                const dynamicUrl = record.evidences.map(
                    (evidence) => `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`
                );

                setDynamicName(dynamicUrl);

                const dName = record.evidences.map((evidence) => evidence.name);
                setDynamic(dName);


            }
        } catch (error) {
        }
    }


    useEffect(() => {
        fetchFindingData(id);
    }, [id]);


    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };

    const handleRemarksChange = (content) => {
        setClientRemark(content);
    };


    const addRow = () => {
        setRowCount((prevCount) => prevCount + 1);
    };

    const deleteRow = (index) => {
        setFormData((prevFormData) => {
            const newEvidenceName = [...prevFormData.evidence_name];
            newEvidenceName.splice(index, 1);
            return { ...prevFormData, evidence_name: newEvidenceName };
        });

        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });

        setRowCount((prevCount) => prevCount - 1);
    };

    const handleEvidenceChange = (index, value) => {
        setFormData((prevFormData) => {
            const newEvidenceName = [...prevFormData.evidence_name];
            newEvidenceName[index] = { ...newEvidenceName[index], remarks: value };
            return { ...prevFormData, evidence_name: newEvidenceName };
        });
    };

    const handleMultiSizeError = (index) => {
        setFileErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = true;
            return newErrors;
        });
    };

    const handleFile = (file, index) => {
        setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index] = { ...file, index };
            return newFiles;
        });

        // Clear error if file is within size limit
        if (file.size <= dynamicMaxSize) {
            setFileErrors((prevErrors) => {
                const newErrors = [...prevErrors];
                newErrors[index] = false;
                return newErrors;
            });
        }
        else {
            setFileErrors([])
        }
    };


    const handleSubmitClientSiteVisitFinding = async (e) => {
        e.preventDefault();
        setIsSubmitting(false);

        // if (clientRemark == null || clientRemark == '<p><br></p>' || clientRemark == '') {
        //     toast.error('Please Add Some Remarks')
        // }
        // else {
        if ((clientRemark == null || clientRemark == "<p><br></p>" || clientRemark == "") || (filesitefinding == null || filesitefinding == '')) {
            toast.error("Please upload the document and remarks.");
        } else {

            try {
                // setLoading(true); // Show loader
                setIsSubmitting(true);

                const formDataToSend = new FormData();
                formDataToSend.append("project_id", projectid);
                formDataToSend.append("site_finding_status", member);
                formDataToSend.append("site_finding_client_document", filesitefinding);
                formDataToSend.append("site_finding_client_remarks", clientRemark);
                formDataToSend.append("pdd_doc", file3);
                formDataToSend.append("er_doc", file1);
                formDataToSend.append("mr_doc", file2);
                formDataToSend.append("cpa_doc", file4);
                formDataToSend.append("irr_doc", file5);
                formDataToSend.append("other_documents", file6);



                formData.evidence_name.forEach((evidence, index) => {
                    formDataToSend.append(
                        `evidences[${index}][evidence_name]`,
                        evidence.remarks
                    );
                });

                files.forEach((file, index) => {
                    formDataToSend.append(`evidences[${index}][evidence_file]`, file[0]);
                });





                const response = await axios.post(
                    `${client_sitefinding_url}`,
                    formDataToSend,
                    CONFIG_Token2
                );
                if (!response.data.status) {
                    toast.error(response.data.message);
                } else if (fileSiteFindingSizeError) {
                    toast.error("File size must below 2MB");
                } else {
                    toast.success("Remarks Submitted Successfully");
                    setClientRemark("");
                    setFileSiteFinding(null);
                    setMember("");
                    navigate("/dashboard");
                }
            } catch (error) {
                toast.error("Documents and Remarks are mandatory");
            } finally {
                // setLoading(false); // Hide loader
                setIsSubmitting(false);

            }
        }
    };


    return (
        <>
            {
                siteFindingStatus === 4 ?
                    <>
                        <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
                        <form
                            method="post"
                            onSubmit={handleSubmitClientSiteVisitFinding}
                            encType="multipart/form-data"
                        >

                            <table className="table table-bordered table-hover table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                            Document Name
                                        </th>

                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color" >
                                            Upload Document
                                        </th>


                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            Uploaded Document
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        {/* {
                                        scope == 'VAL' ?
                                            <td className="text-center lightgreen p-3">
                                                PDD Document
                                                <span style={{ color: "red" }}>*</span>
                                            </td>
                                            :

                                            <td className="text-center lightgreen p-3">
                                                PDD Document
                                            </td>

                                    } */}
                                        <td className="text-center lightgreen p-3">
                                            PDD Document
                                            {scope == "VAL" ?
                                                <span style={{ color: "red" }}>*</span>
                                                : ''
                                            }
                                        </td>


                                        {/* {
                                        scope == 'VAL' ?
                                          

                                            <FileUploaderComponent file={file3} setFile={setFile3} />


                                            :
                                           

                                            <FileUploaderComponent file={file3} setFile={setFile3} />

                                    } */}
                                        <FileUploaderComponent file={file3} setFile={setFile3} />




                                        <td>
                                            <a target="_blank" href={pddName}>
                                                {pdd}
                                            </a>
                                        </td>

                                    </tr>



                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            ER Document
                                            {scope == "VER" || scope == "VAL" ?
                                                <span style={{ color: "red" }}>*</span>
                                                : ''
                                            }
                                        </td>


                                        <FileUploaderComponent file={file1} setFile={setFile1} />


                                        <td>
                                            <a target="_blank" href={erName}>
                                                {er}
                                            </a>
                                        </td>

                                    </tr>

                                    <tr>

                                        {/* {
                                        scope == "VER" ?
                                            <td className="text-center lightgreen p-3">
                                                MR Document
                                                <span style={{ color: "red" }}>*</span>
                                            </td>
                                            :
                                            <td className="text-center lightgreen p-3">
                                                MR Document

                                            </td>

                                    } */}
                                        <td className="text-center lightgreen p-3">
                                            MR Document
                                            {scope == "VER" ?
                                                <span style={{ color: "red" }}>*</span>
                                                : ''
                                            }

                                        </td>


                                        {/* {
                                        scope == "VER" ?
                                           
                                            <FileUploaderComponent file={file2} setFile={setFile2} />

                                            :
                                           
                                            <FileUploaderComponent file={file2} setFile={setFile2} />

                                    } */}
                                        <FileUploaderComponent file={file2} setFile={setFile2} />


                                        <td>
                                            <a target="_blank" href={mrName}>
                                                {mr}
                                            </a>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            CPA Document

                                        </td>

                                        <FileUploaderComponent file={file4} setFile={setFile4} />



                                        <td>
                                            <a target="_blank" href={cpaName}>
                                                {cpa}
                                            </a>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            IRR Document

                                        </td>




                                        <FileUploaderComponent file={file5} setFile={setFile5} />


                                        <td>
                                            <a target="_blank" href={irrName}>
                                                {irr}
                                            </a>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            Other Document

                                        </td>




                                        <FileUploaderComponent file={file6} setFile={setFile6} />



                                        <td>
                                            <a target="_blank" href={otherName}>
                                                {other}
                                            </a>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                            <table className="table table-bordered table-hover table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th className="form-label lightgreen fs-6 text-center table-heading-color">
                                            S.No.
                                        </th>

                                        <th className="form-label lightgreen fs-6 text-center table-heading-color">
                                            Evidence Name

                                        </th>

                                        <th className="form-label lightgreen fs-6 text-center table-heading-color">
                                            Upload File

                                        </th>

                                        <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                            Uploaded Document
                                        </th>

                                        <th className="table-heading-color">
                                            <PlusOutlined onClick={addRow} />
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {[...Array(rowCount)].map((_, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="evidence_name"
                                                    className="form-control"
                                                    // disabled={formdisabled}
                                                    placeholder="evidence name"

                                                    value={
                                                        formData.evidence_name[index]
                                                            ? formData.evidence_name[index].remarks
                                                            : ""
                                                    }
                                                    onChange={(event) =>
                                                        handleEvidenceChange(
                                                            index,
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </td>


                                            <td>
                                                <FileUploader
                                                    handleChange={(file) => handleFile(file, index)}
                                                    name="f23_doc"
                                                    multiple={true}
                                                    maxSize={dynamicMaxSize}
                                                    types={fileTypes}
                                                    onSizeError={() => handleMultiSizeError(index)}
                                                />



                                                {fileErrors[index] ? (
                                                    <span className="text-danger">
                                                        File size greater than {dynamicMaxSize} mb is not
                                                        allowed
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {files[index] && files[index][0]?.name && (
                                                            <span>{`File name: ${files[index][0]?.name}`}</span>
                                                        )}
                                                    </span>
                                                )}

                                            </td>

                                            {formData.evidence_name[index]?.name ? (
                                                <>
                                                    <td>
                                                        <a target="_blank" href={dynamicname[index]}>
                                                            {dynamic[index]}
                                                        </a>
                                                    </td>
                                                </>
                                            ) : (
                                                <td></td>
                                            )}

                                            <td><CloseOutlined
                                                onClick={() => deleteRow(index)}
                                            /></td>


                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                            <div className="row">
                                <div className="col-12 d-flex">
                                    <div className="col-6 my-4">
                                        <label className="form-label lightgreen fs-6" htmlFor="remarks">
                                            Upload Response to Findings<span style={{ color: "red" }}>*</span>
                                        </label>


                                        <FileUploaderComponent file={filesitefinding} setFile={setFileSiteFinding} />

                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-12 d-flex">
                                    <div className='col-6' style={{ marginTop: "25px" }}>
                                        <p className="form-label lightgreen text-bold fs-6">Uploaded Findings</p>
                                        <span>
                                            <a target="_blank" href={siteVisitFindingTlName}>
                                                {siteVisitFindingTl}
                                            </a>
                                        </span>

                                    </div>

                                    {
                                        siteVisitFindingClient == null ? "" :

                                            <div className='col-6' style={{ marginTop: "25px" }}>
                                                <p className="form-label lightgreen text-bold fs-6">Client Uploaded Findings</p>
                                                <span  >
                                                    <a target="_blank" href={siteVisitFindingClientName}>
                                                        {siteVisitFindingClient}
                                                    </a>
                                                </span>

                                            </div>

                                    }

                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 ">
                                    <label className="form-label lightgreen fs-6 mb-3">
                                        Team Leader Remarks{" "}
                                    </label>
                                    <ReactQuill
                                        theme="snow"
                                        value={remark}
                                        name="remarks"
                                        readOnly={true}
                                        dangerouslySetInnerHTML={{ __html: remark }}
                                        onChange={handleRemarksChange}
                                        style={{

                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>
                            <div className="row mt-4 mb-4">
                                <div className="col-12 ">
                                    <label className="form-label lightgreen fs-6 mb-2">
                                        Client  Remarks{" "}
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <ReactQuill
                                        theme="snow"
                                        value={clientRemark}
                                        name="remarks"
                                        dangerouslySetInnerHTML={{ __html: remark }}
                                        onChange={handleRemarksChange}
                                        style={{
                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>
                            <div className="container my-3 pr-0">
                                <div className="row">
                                    <div className="d-flex justify-content-end  col-12">
                                        <button
                                            className="btn px-3 fs-5"
                                            style={{
                                                backgroundColor: "#07b6af",
                                                color: "white",
                                            }}
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>

                                </div>
                            </div>
                            {loading && <div className="loader"></div>}
                        </form >
                    </>



                    :
                    (
                        <form
                            method="post"
                            onSubmit={handleSubmitClientSiteVisitFinding}
                            encType="multipart/form-data"
                        >

                            <table className="table table-bordered table-hover table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th className="text-center lightgreen p-3 fw-bolder fs-6" style={{ background: '#ABEBC6' }}>
                                            Document Name
                                        </th>



                                        <th className="text-center lightgreen p-3 fw-bolder fs-6" style={{ background: '#ABEBC6' }}>

                                            Uploaded Document
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            PDD Document
                                            {scope == "VAL" ?
                                                <span style={{ color: "red" }}>*</span>
                                                : ''
                                            }
                                        </td>



                                        <td>
                                            <a target="_blank" href={pddName}>
                                                {pdd}
                                            </a>
                                        </td>
                                    </tr>



                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            MR Document
                                            {scope == "VER" ?
                                                <span style={{ color: "red" }}>*</span>
                                                : ''
                                            }

                                        </td>


                                        <td>
                                            <a target="_blank" href={erName}>
                                                {er}
                                            </a>
                                        </td>

                                    </tr>
                                    <tr>

                                        <td className="text-center lightgreen p-3">
                                            MR Document
                                            {scope == "VER" ?
                                                <span style={{ color: "red" }}>*</span>
                                                : ''
                                            }

                                        </td>


                                        <td>
                                            <a target="_blank" href={mrName}>
                                                {mr}
                                            </a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            CPA Document
                                            <span style={{ color: "red" }}></span>
                                        </td>


                                        <td>
                                            <a target="_blank" href={cpaName}>
                                                {cpa}
                                            </a>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            IRR Document
                                            <span style={{ color: "red" }}></span>
                                        </td>


                                        <td>
                                            <a target="_blank" href={irrName}>
                                                {irr}
                                            </a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-center lightgreen p-3">
                                            Other Document
                                            <span style={{ color: "red" }}></span>
                                        </td>

                                        <td>
                                            <a target="_blank" href={otherName}>
                                                {other}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <table className="table table-bordered table-hover table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th className="form-label lightgreen fs-6 text-center table-heading-color">
                                            S.No.
                                        </th>

                                        <th className="form-label lightgreen fs-6 text-center  table-heading-color">
                                            Evidence Name

                                        </th>

                                        <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                            Uploaded Document
                                        </th>


                                    </tr>
                                </thead>

                                <tbody>
                                    {formData.evidence_name &&
                                        formData.evidence_name.map((_, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="evidence_name"
                                                        className="form-control"
                                                        disabled
                                                        placeholder="evidence name"
                                                        required
                                                        value={
                                                            formData.evidence_name[index]
                                                                ? formData.evidence_name[index].remarks
                                                                : ""
                                                        }
                                                        onChange={(event) =>
                                                            handleEvidenceChange(index, event.target.value)
                                                        }
                                                    />
                                                </td>

                                                {formData.evidence_name[index]?.name ? (
                                                    <>
                                                        <td>
                                                            <a target="_blank" href={dynamicname[index]}>
                                                                {dynamic[index]}
                                                            </a>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <td></td>
                                                )}


                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <div className="row mt-3">


                                <div className="col-12 d-flex">

                                    {
                                        (siteFindingStatus == 2 && siteVisitFindingClient == null) ||
                                            (siteFindingStatus == 1 && siteVisitFindingClient == null) ||
                                            (siteFindingStatus == 3 && siteVisitFindingClient == null) ?
                                            ""
                                            :

                                            <div className="col-6 my-4" style={{ marginTop: "25px" }}>
                                                <p className="form-label lightgreen text-bold fs-6">Client Uploaded Findings</p>
                                                <span >
                                                    <a target="_blank" className="mt-0" href={siteVisitFindingClientName}>
                                                        {siteVisitFindingClient}
                                                    </a>
                                                </span>
                                            </div>

                                    }


                                    {
                                        (siteFindingStatus == 2 && siteVisitFindingClient == null) ||
                                            (siteFindingStatus == 1 && siteVisitFindingClient == null) ||
                                            (siteFindingStatus == 3 && siteVisitFindingClient == null) ?
                                            ""
                                            :


                                            <div className='col-6' style={{ marginTop: "25px" }}>
                                                <p className="form-label lightgreen text-bold fs-6">Uploaded Findings</p>
                                                <span>
                                                    <a target="_blank" href={siteVisitFindingTlName}>
                                                        {siteVisitFindingTl}
                                                    </a>
                                                </span>

                                            </div>
                                    }

                                </div>
                            </div>


                            {(siteFindingStatus == 1) ||
                                (siteFindingStatus == 2) ||
                                (siteFindingStatus == 3) ? '' :

                                <div className="row mt-4">
                                    <div className="col-12">
                                        <label className="form-label lightgreen fs-6 mb-2">
                                            Team Leader Remarks{" "}
                                        </label>
                                        <ReactQuill
                                            theme="snow"
                                            value={remark}
                                            name="remarks"
                                            readOnly={true}
                                            style={{
                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>

                                </div>
                            }

                            {siteFindingStatus == 2 || (siteFindingStatus == 1 && siteFindingRemark == null) || (siteFindingStatus == 3 && siteFindingRemark == null) ? '' :

                                <div className="row mt-4 mb-4">
                                    <div className="col-12">
                                        <label className="form-label lightgreen fs-6 mb-2">
                                            Client Remarks<span style={{ color: "red" }}>*</span>

                                        </label>

                                        <ReactQuill
                                            theme="snow"
                                            value={clientRemark}
                                            name="remarks"
                                            readOnly={true}
                                            style={{
                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>

                                </div>

                            }
                            {loading && <div className="loader"></div>}
                        </form >
                    )
            }
        </>
    )

}

export default ClientSiteFinding