import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, dvr_report_url, md_action_url } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../config";

const MdRFIRound = (props) => {
  const { id } = useParams();
  const { isTL } = props;

  const [program, setProgram] = useState([]);
  const [remarkMD, setRemarkMD] = useState("");
  const [remarkTM, setRemarkTM] = useState("");
  const [action, setAction] = useState("");
  const [rfiStatus, setRfiStatus] = useState("");

  const [fvrName, setFVRName] = useState(null);
  const [fvr, setFvr] = useState("");
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [verificationDeedName, setVerificationDeedName] = useState(null);
  const [verificationDeed, setVerificationDeed] = useState("");
  const [validationDeedName, setValidationDeedName] = useState(null);
  const [validationDeed, setValidationDeed] = useState("");

  const [loading, setLoading] = useState(false);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { teams } = response.data;

      setRfiStatus(record.status);

      setRemarkMD(record.rfi_mdremarks);
      setRemarkMD((record.status == 18 ) ? record.rfi_mdremarks = "" :record.rfi_mdremarks)


      setRemarkTM(record.rfi_tmremarks);

      setAction(record.status);

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.fvr_report}`;
      setFVRName(url1);
      setFvr(record.fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
      setERName(url2);
      setER(record.tr_er_doc);

      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
      setPDDName(url3);
      setPdd(record.tr_pdd_doc);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
      setVerificationDeedName(url4);
      setVerificationDeed(record.verification_deed);

      let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
      setValidationDeedName(url5);
      setValidationDeed(record.validation_deed);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  const handleRemarksChange = (content) => {
    setRemarkMD(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmitAction = async (e) => {
    e.preventDefault();

    if ((remarkMD == null || remarkMD == '<p><br></p>' || remarkMD == '') && action) {
      toast.error('Please Add Some Remarks')
  }
  else {    

      try {

        setLoading(true); 

        let payload = {
          project_id: id,
          status: action,
          rfi_mdremarks: remarkMD,
        };
        const response = await axios.post(
          `${md_action_url}`,
          payload,
          API_HEADER
        );
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Document Submitted Successfully");
          navigate("/dashboard");
          setRemarkMD("");
          setAction("");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      }finally {
      setLoading(false);
    }
   
  }
  };

  return (
    <>
      <form
        method="post"
        encType="json/form-data"
        onSubmit={handleSubmitAction}
      >
        <table className="table table-bordered  table-hover">
          <thead>
            <tr>
              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color ">
                S.No
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Document Name
              </th>

              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Uploaded Document
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">1</td>
              <td className="text-center lightgreen p-3">
                FVR Report
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={fvrName} target="_blank">
                  {fvr}
                </a>
              </td>
            </tr>

            <tr>
              <td class="text-center">2</td>
              <td className="text-center lightgreen p-3">
                ER
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={erName} target="_blank">
                  {er}
                </a>
              </td>
            </tr>
            <tr>
              <td class="text-center">3</td>
              <td className="text-center lightgreen p-3">
                PDD/MR
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={pddName} target="_blank">
                  {pdd}
                </a>
              </td>
            </tr>

            <tr>
              <td class="text-center">4</td>
              <td className="text-center lightgreen p-3">
                Verification Deed
              </td>

              <td>
                <a href={verificationDeedName} target="_blank">
                  {verificationDeed}
                </a>
              </td>
            </tr>

            <tr>
              <td class="text-center">5</td>
              <td className="text-center lightgreen p-3">
                Validation Deed
              </td>

              <td>
                <a href={validationDeedName} target="_blank">
                  {validationDeed}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        {rfiStatus === 18 ? (
          <>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold  fs-6">TM Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTM}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">MD Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="approve"
                    name="mdremark"
                    value={20}
                    onChange={handleActionChange}
                    className=" text-success"
                  />
                  <label className="text-success mx-1" htmlFor="approve">
                    Approved
                  </label>
                </span>

                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="clarification_required"
                    name="mdremark"
                    value={19}
                    onChange={handleActionChange}
                    className="text-danger "
                  />
                  <label
                    className="text-danger mx-1"
                    htmlFor="clarification_required"
                  >
                    Clarification Required
                  </label>
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkMD}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkMD }}
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              <div className="container my-4 d-flex justify-content-end pr-0">
                <button
                  className="btn  px-3 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div >
                <ReactQuill
                  theme="snow"
                  value={remarkTM}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">MD Remarks</p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">
                <span>
                  {rfiStatus === 20 ? (
                    <Alert
                      className="col-12"
                      message="Approved"
                      type="success"
                      showIcon
                    />
                  ) : rfiStatus === 19 ? (
                    <Alert
                      className="col-12"
                      message="Clarification Required"
                      banner
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkMD}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkMD }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {loading && <div className="loader"></div>}
      </form>
    </>
  );
};

export default MdRFIRound;
