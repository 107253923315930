import { Pagination, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HEADER, BASE_DOCUMENT, getKickoutTracking } from "../../config";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import formatDate from "../../utlis/DateFormat";

const KickoutSummary = ({ id }) => {
    const navigate = useNavigate();

    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); 
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${getKickoutTracking}/${id}`, API_HEADER);
                const data = response.data.record || [];
                setActivityData(data);
                setTotalRecords(response.data.record.length);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchActivity();
    }, [id]);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const paginatedData = activityData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handleKickout = (id, kickId) => {
        navigate(`/kicktrack/${id}/${kickId}`);
    };

    return (
        <>
           <Spin spinning={loading}>
            <table className="table table-bordered table-hover team-change-table">
                <thead>
                    <tr>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">S.No.</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Kickout Start Date</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Kickout Document</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Kickout Registry Remarks</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Kickout Closed Date</th>
                        <th className="form-label lightgreen fs-6 text-center table-heading-color">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.length > 0 ? (
                        paginatedData.map((data, index) => (
                            <tr key={index}>
                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                <td>{formatDate(data.created_at)}</td>
                                <td>
                                <a href={`${BASE_DOCUMENT}/projects/${data.project_id}/${data.kickout_document}`} target="_blank" rel="noopener noreferrer">
                                View Document</a>
                                </td>
                                <td>
                                {data.kick_registrar_remarks && data.kick_registrar_remarks.replace(/<[^>]+>/g, '')}
                                </td>
                                <td>{formatDate(data.updated_at)}</td>
                                <td>
                                    <EyeOutlined
                                        onClick={() => handleKickout(data.project_id, data.id)}
                                        style={{ marginRight: "8px", color: "blue", fontSize: '20px' }}
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center "><span>No Data</span></td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
                showSizeChanger
                className="mt-4"
                style={{ display: 'flex', justifyContent: 'end' }}
            />
            </Spin>
        </>
    );
};

export default KickoutSummary;
