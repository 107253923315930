
import Swal from "sweetalert2";
import "../../App.css";
import Header from "../../Pages/Header";
import SideNavbar from "../SideNavbar";
import Footer from "../Footer";
import { Col, Form, Input, Modal, Row, Select, Table, Tag, Button } from "antd";
import { Tooltip as Tip } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import {
    API_HEADER,
    getAllClients,
    getCountryList,
    get_client_name_url,
    get_regions_url,
    getClientDetails,
    saveClient,
} from "../../config";
import AlternateContact from "../AlternateContact";

const { Option } = Select;

const ViewClient = () => {
    const location = useLocation();
    const data = location.state;
    const id = data.id
    const mode = data.mode
    const modeview = mode == 1 ? false : true
    const [clientform] = Form.useForm();

    const [continent_list, setContinentList] = useState([]);
    const [region, setRegion] = useState([]);
    const [country_list, setCountrysList] = useState([]);
    const [client_id, SetClientId] = useState(null);

    const [modalVisible, SetModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [formdisable, SetFormDisabled] = useState(false);
    const [records, setRecords] = useState([{ id: 1, name: '', email: '', mobile: '', designation: '' }]);
    const navigate = useNavigate();

    const openClientEdit = async (id, mode) => {

        try {
            const payload = {
                client_id: id,
            };

            const response = await axios.post(
                `${getClientDetails}`,
                payload,
                API_HEADER
            );

            if (response.status === 200 && response.data.status === true) {
                let clientrecord = response.data.data;
                getCountry(clientrecord.region);
                setPhone(clientrecord.contact_mobile);
                setMobile(clientrecord.mobile_number);
                setRecords(JSON.parse(clientrecord.contact_person))

                const clientDatas = {
                    name: clientrecord.name,
                    email: clientrecord.email,
                    region: clientrecord.region,
                    country: clientrecord.country,
                    address: clientrecord.address,
                    contact_person: clientrecord.contact_person,
                    contact_email: clientrecord.contact_email,
                };

                clientform.setFieldsValue(clientDatas);
                SetClientId(clientrecord.id);

                if (mode === 2) {
                    setFormTitle("View Client");
                    SetFormDisabled(true);
                } else {
                    setFormTitle("Edit Client");
                    SetFormDisabled(false);
                }
            }
        } catch (error) {
        }
    };


    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    let [clientLoad, SetClientLoad] = useState(false);

    const clientFormSubmit = (values) => {
        clientform
            .validateFields()
            .then((values) => {
                const requestData = {
                    ...values,
                    client_id,
                    mobile_number: mobile,
                    contact_person: records
                };

                axios
                    .post(`${saveClient}`, requestData, API_HEADER)
                    .then((result) => {
                        if (result.status === 200 && result.data.status === true) {
                            if (client_id === null) {
                                toast.success("Client Added Successfully!");
                            } else {
                                toast.success("Client Details Updated Successfully!");
                            }

                            clientform.resetFields();

                            SetClientLoad(true);
                            SetClientId(null);
                            setPhone(null);
                            setMobile(null);
                            navigate("/clients");
                            setRecords([{ id: 1, name: '', email: '', mobile: '', designation: '' }]);

                        } else {
                            toast.error(result.data.message);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response.data.message);
                    });
            })
            .catch((errorInfo) => {
            });
    };


    useEffect(() => {
        const getContinent = async () => {
            try {
                const result = await axios.get(`${get_regions_url}`);
                setContinentList(result.data.data);
            } catch (error) { }
        }
        getContinent()
        openClientEdit(id, mode)
    }, []);

    useEffect(() => {
        const getCountry = async (value) => {
            try {
                const result = await axios.get(`${getCountryList}?region_id=${value}`);
                setCountrysList(result.data.data);
            } catch (error) { }
        }
        getCountry();
    }, []);

    const handleRegion = (value) => {
        setRegion(value);
        getCountry(value);
    };

    const getCountry = async (value) => {
        try {
            const result = await axios.get(`${getCountryList}?region_id=${value}`);
            setCountrysList(result.data.data);
        } catch (error) { }
    };

    let [formtitle, setFormTitle] = useState("")

    const handleMobileChange = (value) => {
        const mobileNumber = value.replace(/\D/g, "");
        if (mobileNumber.length <= 16) {
            setMobile(value);
            setMobileError("");
        } else {
            setMobileError("Phone number must be up to 13 digits.");
        }
    };

    const handleRecordChange = (index, event) => {

        const { name, value } = event.target;
        const newPayments = records.map((Payment, idx) => {
            if (idx === index) {
                let updatedPayment = { ...Payment, [name]: value };

                return updatedPayment;
            }
            return Payment;
        });

        setRecords(newPayments);

    };
    const deleteRecord = (index) => {
        const newPayments = records.filter((_, idx) => idx !== index);
        setRecords(newPayments);
    };

    const addrecord = () => {
        if (records.length < 10) {
            setRecords([...records, { id: records.length + 1, name: '', email: '', mobile: '', designation: '' }]);
        }
    };

    return (
        <>
            <Header />
            <SideNavbar />
            <div className="content-wrapper bg-white">
                <div className="content">
                    <div className="container-fluid bg-white">
                        <div className="row">
                            <div className="col-10 border-0 py-2 mx-auto">
                            
                            <h5 className="clientdetails mt-4">Client Details</h5>

                                <Form
                                    form={clientform}
                                    onFinish={clientFormSubmit}
                                    layout="vertical"
                                    disabled={formdisable}
                                >
                                    <p className="textcolorblue fw-bold fy-4 fs-6">Organisation Details</p>

                                    <Row gutter={[8, 4]}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="name"
                                                label={
                                                    <span style={{ color: "#07b6af" }}>Company Name</span>
                                                }
                                                rules={[
                                                    { required: true, message: "Name is required" },

                                                ]}
                                            >
                                                <Input style={{ color: "#31363F" }} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                name="email"
                                                label={<span style={{ color: "#07b6af" }}>Email</span>}
                                                rules={[
                                                    { required: true, message: "Email is required" },
                                                    { type: "email", message: "Invalid email" },
                                                    {
                                                        pattern: /^[&,.\-_\w\s@]{1,50}$/,
                                                        message:
                                                            "Please enter a valid email (up to 50 characters, only @, &, , ., -, _ special characters are allowed)",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="email"
                                                    placeholder="name@example.com"
                                                    style={{ color: "#31363F" }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                name="region"
                                                label={<span style={{ color: "#07b6af" }}>Region</span>}
                                                rules={[{ required: true, message: "Region is required" }]}
                                            >
                                                <Select
                                                    placeholder="Select Region"
                                                    onChange={handleRegion}
                                                    style={{ color: "#31363F" }}
                                                >
                                                    <Option value="">Select Region</Option>
                                                    {continent_list.map((item, index) => {
                                                        return (
                                                            <Option key={index} value={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                name="country"
                                                label={<span style={{ color: "#07b6af" }}>Country</span>}
                                                rules={[{ required: true, message: "Country is required" }]}
                                            >
                                                <Select
                                                    placeholder="Select Country"
                                                    style={{ color: "#31363F" }}
                                                >
                                                    <Option value="">Select Country</Option>
                                                    {country_list.map((item, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={item.id}
                                                                style={{ color: "#31363F" }}
                                                            >
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                name="address"
                                                label={<span style={{ color: "#07b6af" }}>Address</span>}
                                            >
                                                <Input.TextArea style={{ color: "#31363F" }} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            {<label style={{ color: "#07b6af" }}>Contact No.</label>}
                                            <PhoneInput
                                                country={"in"}
                                                value={mobile}
                                                onChange={handleMobileChange}
                                                enableSearch={true}
                                                disableSearchIcon={true}
                                                searchPlaceholder="Search"
                                                countryCodeEditable={false}
                                                disabled={formdisable}
                                                inputProps={{
                                                    name: "mobile",
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                            />
                                            {mobileError && (
                                                <div style={{ color: "red" }}>{mobileError}</div>
                                            )}


                                        </Col>

                                    </Row>

                                    <p className="textcolorblue fw-bold my-4 fs-6">Contact Person Details</p>

                                    
                                    <AlternateContact records={records} formdisabled={modeview} addrecord={addrecord} handleRecordChange={handleRecordChange} deleteRecord={deleteRecord} />

                                    {mode == 1 ?
                                        <div className="container">
                                            <div className="row">
                                                <div className='d-flex justify-content-end my-5 col-12'>
                                                    <button className='btn px-3 fs-5' style={{ backgroundColor: '#07b6af', color: 'white' }} type="Submit">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ViewClient;
