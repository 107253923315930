import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../../../config";
import { BASE_DOCUMENT } from "../../../config";
import { get_KickoutInfo_url } from "../../../config";
import { tlTRRound_Kickout_url } from "../../../config";
import FileUploaderComponent from "../../../utlis/FileUploaderComponent";
import LoaderComponent from "../../../utlis/LoderComponent";



const TLKickoutTRRound = () => {

  const navigate = useNavigate();
  const { id } = useParams();


  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size

  const [kickoutStatus, setkickoutStatus] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkAT, setRemarkAT] = useState("");
  const [remarkTR, setRemarkTR] = useState("");
  const [remarkClient, setRemarkClient] = useState('');
  const [kickoutId, setKickoutId] = useState('');


  const [member, setMember] = useState();

  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [file7, setFile7] = useState('');

  const [fvrName, setFVRName] = useState(null)
  const [fvr, setFvr] = useState("")
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null)
  const [auditFinding, setAuditFinding] = useState("")
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");


  const [fileFVRSizeError, setFileFVRSizeError] = useState(false);
  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [fileRRSizeError, setFileRRSizeError] = useState(false);
  const [fileCpaSizeError, setFileCpaSizeError] = useState(false);
  const [fileAuditSizeError, setFileAuditSizeError] = useState(false);
  const [fileOtherSizeError, setFileOtherSizeError] = useState(false);

  const [kickOutDocName, setKickOutDocName] = useState(null)
  const [kickOutDoc, setKickOutDoc] = useState("")

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);


  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { kickout } = response.data;
      const { record } = response.data;

      setkickoutStatus(record.kickout_status);
      // setRemarkTL(kickout.tr_tl_remarks);
      setRemarkTL((record.kickout_status == 7 || record.kickout_status == 9 || record.kickout_status == 11) ? kickout.tr_tl_remarks == "" : kickout.tr_tl_remarks);

      setRemarkAT(kickout.tr_at_remarks);
      setRemarkTR(kickout.tr_remarks);
      setMember(kickout.kickout_status);
      setRemarkClient(kickout.tr_client_remarks);
      setKickoutId(record.kickout_id)



      let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.tr_fvr_report}`;
      setFVRName(url1);
      setFvr(kickout.tr_fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.tr_err_document}`;
      setERName(url2);
      setER(kickout.tr_err_document);


      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.tr_pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.tr_pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.tr_cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.tr_cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.tr_irr_document}`;
      setRRName(url5);
      setRR(kickout.tr_irr_document);

      let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.tr_audit_finding}`;
      setAuditFindingName(url6);
      setAuditFinding(kickout.tr_audit_finding);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.tr_other_docs}`;
      setOtherName(url7);
      setOther(kickout.tr_other_docs);

      let url8 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.kickout_document}`;
      setKickOutDocName(url8);
      setKickOutDoc(kickout.kickout_document);


    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
    // fetchDocs(id);
  }, []);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };

  const handleRemarksChange = (content) => {
    setRemarkTL(content);
  };





  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (remarkTL == null || remarkTL == '<p><br></p>' || remarkTL == '') {
      toast.error('Please Add Some Remarks')
    }
    else {
      try {
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("tr_fvr_report", file1);
        formDataToSend.append("tr_err_document", file2);
        formDataToSend.append("tr_pdd_document", file3);
        formDataToSend.append("tr_irr_document", file4);
        formDataToSend.append("tr_cpa_document", file5);
        formDataToSend.append("tr_audit_finding", file6);
        formDataToSend.append("tr_other_docs[]", file7);
        formDataToSend.append("tr_tl_remarks", remarkTL);
        formDataToSend.append("kickout_status", member);
        formDataToSend.append("kickout_id", kickoutId)


        const response = await axios.post(
          `${tlTRRound_Kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {

          toast.error(response.data.message);
        } else if (filePDDSizeError || fileERSizeError || fileOtherSizeError || fileAuditSizeError || fileFVRSizeError || fileRRSizeError || fileCpaSizeError) {
          toast.error(`File size must below ${dynamicMaxSize}MB`);
        } else {
          toast.success("Document Submitted Successfully");

          setRemarkTL("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      }
      finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);

      }
    }
  };

  return (
    <>
      {(kickoutStatus == 11 || kickoutStatus == 7 || kickoutStatus == 9) ? (
        <>
        <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
        <form
          method="post"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >

          <table className="table table-bordered  table-hover">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Document Name
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                  Upload Document
                </th>

                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                  Uploaded Document
                </th>


              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>

                <td className="text-center lightgreen p-3">
                  FVR Report
                  <span style={{ color: "red" }}>*</span>

                </td>

                <FileUploaderComponent file={file1} setFile={setFile1} />


                <td>
                  <a href={fvrName} target="_blank">{fvr}</a>
                </td>


              </tr>

              <tr>
                <td class="text-center">2</td>

                <td className="text-center lightgreen p-3">
                  ER
                  <span style={{ color: "red" }}>*</span>

                </td>


                <FileUploaderComponent file={file2} setFile={setFile2} />


                <td>
                  <a href={erName} target="_blank">{er}</a>
                </td>


              </tr>

              <tr>
                <td class="text-center">3</td>

                <td className="text-center lightgreen p-3">
                  PDD/MR
                  <span style={{ color: "red" }}>*</span>

                </td>

                <FileUploaderComponent file={file3} setFile={setFile3} />


                <td>
                  <a href={pddName} target="_blank">{pdd}</a>
                </td>


              </tr>

              <tr>
                <td class="text-center">4</td>


                <td className="text-center lightgreen p-3">
                  IRR

                </td>

                <FileUploaderComponent file={file4} setFile={setFile4} />



                <td>
                  <a href={rrName}>{rr}</a>
                </td>



              </tr>

              <tr>
                <td class="text-center">5</td>



                <td className="text-center lightgreen p-3">
                  CPA

                </td>

                <FileUploaderComponent file={file5} setFile={setFile5} />

                <td>
                  <a href={cpaName}>{cpa}</a>
                </td>


              </tr>

              <tr>
                <td class="text-center">6</td>



                <td className="text-center lightgreen p-3">
                  Audit Finding
                  <span style={{ color: "red" }}>*</span>

                </td>

                <FileUploaderComponent file={file6} setFile={setFile6} />

                <td>
                  <a href={auditFindingName} target="_blank">{auditFinding}</a>
                </td>



              </tr>


              <tr>
                <td class="text-center">7</td>


                <td className="text-center lightgreen p-3">
                  Other Document

                </td>

                <FileUploaderComponent file={file7} setFile={setFile7} />



                <td>
                  <a target="_blank" href={otherName}>
                    {other}
                  </a>
                </td>

              </tr>

            </tbody>
          </table>

          {/* <table className="table table-bordered  table-hover mt-4">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Document Name
                </th>

                <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                  Uploaded Document
                </th>

              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="text-center">1</td>

                <td className="text-center lightgreen p-3">
                  KickOut Document
                  <span style={{ color: "red" }}>*</span>
                </td>

                <td>
                  <a href={kickOutDocName} target="_blank">{kickOutDoc}</a>
                </td>
              </tr>

            </tbody>
          </table> */}



          <div className="col-5 my-4 ">
            <label
              htmlFor="Member"
              className="form-label lightgreen mb-4 fs-6"
            >
              Send To
              <span style={{ color: "red" }}>*</span>
            </label>

            <select
              id="member"
              className="form-select borderlightgreen form-select-sm"
              aria-label="Default select example"
              name="status"
              value={member}
              required
              onChange={(e) => {
                setMember(e.target.value);
              }}
            >
              <option value=''>Select</option>
              <option value={10}>Reply to Client</option>
              <option value={8}>Send to Team Members</option>
              <option value={12}>Send to TR</option>
            </select>
          </div>


          <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks<span style={{ color: "red" }}>*</span></p>
          <hr className="mb-3 lightgreen" />

          <div className="row mt-4">

            <ReactQuill
              theme="snow"
              value={remarkTL}
              name="remarks"
              dangerouslySetInnerHTML={{ __html: remarkTL }}
              onChange={handleRemarksChange}
              style={{

                height: "20vh",
                marginBottom: "2rem",
              }}
              className="mb-5"
            />

          </div>

          <div style={{ marginTop: '60px' }}>
            <p className="lightgreen fw-bold my-3 fs-6">
              Technical Reviewer Remarks

            </p>
            <hr className="mb-3 lightgreen" />


            <div>
              <span>
                {kickoutStatus == 13 ? (
                  <Alert
                    className="col-12"
                    message="Approved"
                    type="success"
                    showIcon
                  />
                ) : kickoutStatus == 7 ? (
                  <Alert
                    className="col-12"
                    message="Clarification Required"
                    banner
                  />
                ) : (
                  ""
                )}
              </span>

              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkTR}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkTR }}
                    readOnly={true}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>

          </div>


          {/* {remarkAT != null ?
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  readOnly={true}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>
            </div>

            :
            ''
          } */}

{remarkAT &&
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  readOnly={true}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>
            </div>
          }



          {remarkClient &&
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkClient}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkClient }}
                  readOnly={true}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>
            </div>
          }


          <div className="container mt-4 d-flex justify-content-end pr-0">
            <button
              className="btn px-3 fs-5"
              style={{
                backgroundColor: "#07b6af",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        </>
      ) :
        (
          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >



            <>


              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                      Uploaded Document
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      FVR Report
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={fvrName} target="_blank">{fvr}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      ER
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={erName} target="_blank">{er}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      PDD/MR
                      <span style={{ color: "red" }}>*</span>
                    </td>




                    <td>
                      <a href={pddName} target="_blank">{pdd}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">4</td>

                    <td className="text-center lightgreen p-3">
                      IRR
                    </td>

                    <td>
                      <a href={rrName}>{rr}</a>
                    </td>



                  </tr>

                  <tr>
                    <td class="text-center">5</td>

                    <td className="text-center lightgreen p-3">
                      CPA
                    </td>

                    <td>
                      <a href={cpaName}>{cpa}</a>
                    </td>


                  </tr>

                  <tr>
                    <td class="text-center">6</td>

                    <td className="text-center lightgreen p-3">
                      Audit Finding
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={auditFindingName} target="_blank">{auditFinding}</a>
                    </td>
                  </tr>


                  <tr>
                    <td class="text-center">7</td>


                    <td className="text-center lightgreen p-3">
                      Other Document
                    </td>






                    <td>
                      <a target="_blank" href={otherName}>
                        {other}
                      </a>
                    </td>


                  </tr>

                </tbody>
              </table>


              {/* <table className="table table-bordered  table-hover mt-4">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>

                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                      Uploaded Document
                    </th>

                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      KickOut Document
                      <span style={{ color: "red" }}>*</span>
                    </td>

                    <td>
                      <a href={kickOutDocName} target="_blank">{kickOutDoc}</a>
                    </td>
                  </tr>

                </tbody>
              </table> */}

{kickoutStatus < 13 ?
              <div className="col-5 my-4 ">
                <label
                  htmlFor="Member"
                  className="form-label lightgreen mb-4 fs-6"
                >
                  Send To
                  <span style={{ color: "red" }}>*</span>
                </label>

                <select
                  id="member"
                  className="form-select borderlightgreen form-select-sm"
                  aria-label="Default select example"
                  name="status"
                  value={member}
                  disabled

                >
                  <option value=''>Select</option>



                  <option value={10}>Reply to Client</option>
                  <option value={8}>Send to Team Members</option>
                  <option value={12}>Send to TR</option>

                </select>
              </div>
:
'' }
              
            

              <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkTL}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkTL }}

                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>

              <div style={{ marginTop: '60px' }}>
            <p className="lightgreen fw-bold my-3 fs-6">
              Technical Reviewer Remarks

            </p>
            <hr className="mb-3 lightgreen" />


            <div>
              <span>
                {kickoutStatus == 13 ? (
                  <Alert
                    className="col-12"
                    message="Approved"
                    type="success"
                    showIcon
                  />
                ) : kickoutStatus == 7 ? (
                  <Alert
                    className="col-12"
                    message="Clarification Required"
                    banner
                  />
                ) : (
                  ""
                )}
              </span>

              <div className="row mt-4">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={remarkTR}
                    name="remarks"
                    dangerouslySetInnerHTML={{ __html: remarkTR }}
                    readOnly={true}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>

          </div>


          {/* {remarkAT != null ?
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  readOnly={true}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>
            </div>

            :
            ''
          } */}

{remarkAT &&
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Member Remarks</p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkAT}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkAT }}
                  readOnly={true}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>
            </div>
          }



          {remarkClient &&
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Client Remarks</p>
              <hr className="mb-3 lightgreen" />

              <div className="row mt-4">

                <ReactQuill
                  theme="snow"
                  value={remarkClient}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkClient }}
                  readOnly={true}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />

              </div>
            </div>
          }

            </>

          </form>
        )
        // :
        // ''
      }
    </>
  );
};

export default TLKickoutTRRound;





