
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, dvr_report_url, tl_rfi_url } from "../../config";
import {  get_KickoutInfo_url, tl_rfi_kickout_url } from "../../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER, getKickoutDetails} from "../../config";

const TrackRFI = ({project_id,kickout_id}) => {

    const navigate = useNavigate();

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size

    const [remark, setRemark] = useState("")
    const [remarkTLRFI, setRemarkTLRFI] = useState("")
    const [remarkATRFI, setRemarkATRFI] = useState("")
    const [tmRfiStatus, setTmRfiStatus] = useState("")

    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file3, setFile3] = useState('');
    const [file4, setFile4] = useState('');
    const [file5, setFile5] = useState('');
    const [file6, setFile6] = useState('');
    const [file7, setFile7] = useState('');

    const [fvrName, setFVRName] = useState(null)
    const [fvr, setFvr] = useState("")
    const [pddName, setPDDName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setERName] = useState(null);
    const [er, setER] = useState("");
    const [cpaName, setCpaName] = useState(null);
    const [cpa, setCpa] = useState("");
    const [rrName, setRRName] = useState(null);
    const [rr, setRR] = useState("");
    const [auditFindingName, setAuditFindingName] = useState(null)
    const [auditFinding, setAuditFinding] = useState("")
    const [otherName, setOtherName] = useState(null);
    const [other, setOther] = useState("");
    const [status, setStatus] = useState('')

    const [remarkTMRFI, setRemarkTMRFI] = useState("")
    const [remarkMD, setRemarkMD] = useState("")

    const [registrarRFIDateTo, setRegistrarRFIDateTo] = useState(null)
    const [registrarRFIRemark, setRegistrarRFIRemark] = useState(null)


    const [loading, setLoading] = useState(false);
    const [member, setMember] = useState();

    const [otherDocFile, setOtherDocFile] = useState([]);
    const [otherDocName, setOtherDocName] = useState(null);
    const [otherDoc, setOtherDoc] = useState([]);

    const [kickoutStatus, setkickoutStatus] = useState("");
    const [kickoutId, setKickoutId] = useState('');

    const fetchData = async (ids) => {
        try {
            const response = await axios.get(
                `${getKickoutDetails}/${project_id}/${kickout_id}`,
                API_HEADER
            );
            const { record } = response.data;
            const { kickout } = response.data;

            setKickoutId(kickout.id)
            setkickoutStatus(record.kickout_status);

            setRemarkTLRFI(kickout.kickout_status == 24 || (kickout.kickout_status == 25 || kickout.kickout_status == 28 || kickout.kickout_status == 30) ? kickout.rfi_tl_remarks = "" : kickout.rfi_tl_remarks)

            setRemarkTMRFI(kickout.rfi_tmremarks)
            setRemarkMD(kickout.rfi_mdremarks)
            setTmRfiStatus(kickout.rfi_tmaction)
            setRegistrarRFIDateTo(kickout.rfi_registrar_uploading_date)
            setRegistrarRFIRemark(kickout.rfi_registrar_remarks)
            setStatus(kickout.kickout_status)
            setMember(kickout.kickout_status)
            setRemarkATRFI(kickout.rfi_at_remarks)


            let url1 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_fvr_report}`;
            setFVRName(url1);
            setFvr(kickout.rfi_fvr_report);

            let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_err_document}`;
            setERName(url2);
            setER(kickout.rfi_err_document);


            let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_pdd_document}`;
            setPDDName(url3);
            setPdd(kickout.rfi_pdd_document);

            let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_cpa_document}`;
            setCpaName(url4);
            setCpa(kickout.rfi_cpa_document);

            let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_irr_document}`;
            setRRName(url5);
            setRR(kickout.rfi_irr_document);

            let url6 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_audit_finding}`;
            setAuditFindingName(url6);
            setAuditFinding(kickout.rfi_audit_finding);

            let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.rfi_other_docs}`;
            setOtherName(url7);
            setOther(kickout.rfi_other_docs);

        } catch (error) { }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const combined = otherDoc?.map((name, index) => ({
        name: name,
        url: otherDocName[index]
    }));

    const handleRemarksChange = (content) => {
        setRemarkTLRFI(content);
    };

    return (
        <>
           
                    <form
                        method="post"
                        encType="multipart/form-data"
                    >
                        <table className="table table-bordered  table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                        S.No
                                    </th>
                                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                                        Document Name
                                    </th>

                                    <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">

                                        Uploaded Document
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">1</td>

                                    <td className="text-center lightgreen p-3">
                                        FVR Report
                                        <span style={{ color: "red" }}>*</span>
                                    </td>

                                    <td>
                                        <a href={fvrName} target="_blank">{fvr}</a>
                                    </td>

                                </tr>

                                <tr>
                                    <td class="text-center">2</td>

                                    <td className="text-center lightgreen p-3">
                                        ER
                                        <span style={{ color: "red" }}>*</span>
                                    </td>

                                    <td>
                                        <a href={erName} target="_blank">{er}</a>
                                    </td>


                                </tr>

                                <tr>
                                    <td class="text-center">3</td>

                                    <td className="text-center lightgreen p-3">
                                        PDD/MR
                                        <span style={{ color: "red" }}>*</span>
                                    </td>

                                    <td>
                                        <a href={pddName} target="_blank">{pdd}</a>
                                    </td>

                                </tr>

                                <tr>
                                    <td class="text-center">4</td>

                                    <td className="text-center lightgreen p-3">
                                        IRR
                                    </td>

                                    <td>
                                        <a href={rrName}>{rr}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">5</td>

                                    <td className="text-center lightgreen p-3">
                                        CPA
                                    </td>

                                    <td>
                                        <a href={cpaName}>{cpa}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">6</td>

                                    <td className="text-center lightgreen p-3">
                                        Audit Finding
                                        <span style={{ color: "red" }}>*</span>
                                    </td>

                                    <td>
                                        <a href={auditFindingName} target="_blank">{auditFinding}</a>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-center">7</td>


                                    <td className="text-center lightgreen p-3">
                                        Other Document
                                    </td>

                                    <td>
                                        <a target="_blank" href={otherName}>
                                            {other}
                                        </a>
                                    </td>
                                </tr>

                            </tbody>

                        </table>

                        <div className="row">

                        <div className="col-4 my-4 ">
                            <label
                                htmlFor="Member"
                                className="form-label lightgreen mb-4 fs-6"
                            >
                                Send To
                                <span style={{ color: "red" }}>*</span>
                            </label>

                            <select
                                id="member"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="status"
                                value={member}
                                disabled
                                readOnly={true}
                            >
                                <option value=''>Select</option>
                                <option value={26}>Send to Team</option>
                                <option value={27}>Forward to TM</option>
                                <option value={29}>TM Send to MD</option>
                                <option value={31}>MD Send to Registrar </option>
                                <option value={32}>RFI Complete</option>



                            </select>
                        </div>

                        </div>


                            <div className="row" style={{ marginTop: '40px' }} >
                                <div className="col-3 mb-3 ">
                                    <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                                        RFI Submission Date<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                                <div className="col-4 mb-3 ">
                                    <input
                                        type="date"
                                        className={`form-control borderlightgreen`}
                                        id="rfidate"
                                        required
                                        disabled
                                        name="rfidate"
                                        value={registrarRFIDateTo}
                                    />
                                </div>
                            </div>


<div style={{ marginTop: '50px' }}>
                            <p className="lightgreen fw-bold  fs-6">
                                Team Leader Remarks<span style={{ color: "red" }}>*</span>
                            </p>
                            <hr className="mb-3 lightgreen" />
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    value={remarkTLRFI}
                                    name="remarks"
                                    dangerouslySetInnerHTML={{ __html: remarkTLRFI }}
                                    readOnly={true}
                                    // onChange={handleRemarksChange}
                                    style={{

                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>

                        {remarkATRFI &&
                            <div style={{ marginTop: '50px' }}>
                                <p className="lightgreen fw-bold  fs-6">
                                    Team Member Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        value={remarkATRFI}
                                        name="remarks"
                                        readOnly={true}

                                        dangerouslySetInnerHTML={{ __html: remarkATRFI }}
                                        // onChange={handleRemarksChange}
                                        style={{

                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            </div>}

                        {remarkTMRFI &&
                            <div className="mt-3">
                                <p className="lightgreen fw-bold my-3 fs-6">
                                    TM Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />
                             
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <ReactQuill
                                            theme="snow"
                                            value={remarkTMRFI}
                                            name="remarks"
                                            readOnly={true}
                                            style={{

                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>
                                </div>
                            </div>}

                        {remarkMD &&
                            <div className="mt-3">
                                <p className="lightgreen fw-bold my-3 fs-6">
                                    MD Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />
                              
                                <div className="row mt-4">
                                    <div>
                                        <ReactQuill
                                            theme="snow"
                                            value={remarkMD}
                                            name="remarks"
                                            readOnly={true}
                                            style={{

                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>
                                </div>
                            </div>}



                    </form>
        </>

    )
}

export default TrackRFI;