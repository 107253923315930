import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  BASE_DOCUMENT,
  dvr_report_url,
  tr_action_url,
  tm_rfi_url,
} from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { API_HEADER } from "../config";


const TMRfiReview = (props) => {
  const { id } = useParams();
  const { isTL } = props;

  const [program, setProgram] = useState([]);
  const [remark, setRemark] = useState("");
  const [remarkTR, setRemarkTR] = useState("");
  const [remarkTMRFI, setRemarkTMRFI] = useState("");
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkTLRFI, setRemarkTLRFI] = useState("");
  const [action, setAction] = useState("");
  const [trStatus, setTrStatus] = useState("");
  const [teamList, setTeamList] = useState([]);

  const [fvrName, setFVRName] = useState(null);
  const [fvr, setFvr] = useState("");
  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [verificationDeedName, setVerificationDeedName] = useState(null);
  const [verificationDeed, setVerificationDeed] = useState("");
  const [validationDeedName, setValidationDeedName] = useState(null);
  const [validationDeed, setValidationDeed] = useState("");


  const [tmrfiStatus, setTmrfiStatus] = useState("");
  const [tmRfiStatus, setTmRfiStatus] = useState("");
  const [remarkMD, setRemarkMD] = useState("");


  const [loading, setLoading] = useState(false);

  const [otherDocFile, setOtherDocFile] = useState([]);
  const [otherDocName, setOtherDocName] = useState(null);
  const [otherDoc, setOtherDoc] = useState([]);


  const [formData, setFormData] = useState({
    nobs_document: "",
    audit_document: "",
    visit_date: "",
  });

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { teams } = response.data;

      setRemarkTLRFI(record.rfi_tlremarks);
      setRemarkTMRFI((record.status == 16) ? record.rfi_tmremarks = "" : record.rfi_tmremarks)

      setTmrfiStatus(record.status);
      setTmRfiStatus(record.status);
      setRemarkMD(record.rfi_mdremarks);

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.fvr_report}`;
      setFVRName(url1);
      setFvr(record.fvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
      setERName(url2);
      setER(record.tr_er_doc);


      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
      setPDDName(url3);
      setPdd(record.tr_pdd_doc);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.verification_deed}`;
      setVerificationDeedName(url4);
      setVerificationDeed(record.verification_deed);


      let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.validation_deed}`;
      setValidationDeedName(url5);
      setValidationDeed(record.validation_deed);

      let otherDocsUrls = record.rfi_other_docs.split(',').map(doc => `${BASE_DOCUMENT}/projects/${record.id}/${doc.trim()}`);

      console.log("otherDocsUrls", otherDocsUrls)
      let otherDocsNames = record.rfi_other_docs.split(',');
      console.log("otherDocsNames", otherDocsNames)


      setOtherDocName(otherDocsUrls);
      setOtherDoc(otherDocsNames);

    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const combined = otherDoc?.map((name, index) => ({
    name: name,
    url: otherDocName[index]
  }));


  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  const handleRemarksChange = (content) => {
    setRemarkTMRFI(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmitAction = async (e) => {
    e.preventDefault();
    if ((remarkTMRFI == null || remarkTMRFI == '<p><br></p>' || remarkTMRFI == '') && action) {
      toast.error('Please Add Some Remarks')
    }
    else {
      try {

        setLoading(true);
        let payload = {
          project_id: id,
          status: action,
          rfi_tmremarks: remarkTMRFI,
        };
        const response = await axios.post(`${tm_rfi_url}`, payload, API_HEADER);
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          toast.success("Document Submitted Successfully");
          navigate("/dashboard");
          setRemark("");
          setAction("");
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <form
        method="post"
        encType="json/form-data"
        onSubmit={handleSubmitAction}
      >
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                S.No
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                Document Name
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                Uploaded Document
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">1</td>
              <td className="text-center lightgreen p-3">
                FVR Report
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={fvrName} target="_blank">
                  {fvr}
                </a>
              </td>
            </tr>
            <tr>
              <td class="text-center">2</td>
              <td className="text-center lightgreen p-3">
                ER
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={erName} target="_blank">
                  {er}
                </a>
              </td>
            </tr>
            <tr>
              <td class="text-center">3</td>
              <td className="text-center lightgreen p-3">
                PDD
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={pddName} target="_blank">
                  {pdd}
                </a>
              </td>
            </tr>

            <tr>
              <td class="text-center">4</td>
              <td className="text-center lightgreen p-3">
                Verification Deed
                
              </td>

              <td>
                <a href={verificationDeedName} target="_blank">
                  {verificationDeed}
                </a>
              </td>
            </tr>

            <tr>
              <td class="text-center">5</td>
              <td className="text-center lightgreen p-3">
                Validation Deed
                
              </td>

              <td>
                <a href={validationDeedName} target="_blank">
                  {validationDeed}
                </a>
              </td>
            </tr>

            <tr>
              <td class="text-center">6</td>
              <td className="text-center lightgreen p-3">
                Other Docs

              </td>

              <td>
                {combined.map((item) => (
                  <div>
                    <a target="_blank" href={item.url}>
                      {item.name}

                    </a>
                  </div>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
        {tmrfiStatus === 16 ? (
          <>
            <div>
              <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTLRFI}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div

              style={{ marginTop: "60px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="approve"
                    name="rfi"
                    value={18}
                    onChange={handleActionChange}
                    className=" text-success"
                  />
                  <label className="text-success mx-1" htmlFor="approve">
                    Approved
                  </label>
                </span>
                <span className="col-10 col-lg-4 col-md-4">
                  <input
                    type="radio"
                    id="clarification_required"
                    name="rfi"
                    value={17}
                    onChange={handleActionChange}
                    className="text-danger"
                  />
                  <label
                    className="text-danger mx-1"
                    htmlFor="clarification_required"
                  >
                    Clarification Required
                  </label>
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkTMRFI}
                    name="remarks"
                    onChange={handleRemarksChange}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
              <div className="container my-4 d-flex justify-content-end pr-0">
                <button
                  className="btn  px-3 fs-5"
                  style={{
                    backgroundColor: "#07b6af",
                    color: "white",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        ) : tmrfiStatus === 18 || tmrfiStatus == 17 ? (
          <>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTLRFI}
                  name="remarks"
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div>
                <span>
                  {tmRfiStatus === 2 ? (
                    <Alert
                      className="col-12"
                      message="Approved"
                      type="success"
                      showIcon
                    />
                  ) : tmRfiStatus === 3 ? (
                    <Alert
                      className="col-12"
                      message="Clarification Required"
                      banner
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="row mt-4">
                <div >
                  <ReactQuill
                    theme="snow"
                    value={remarkTMRFI}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (tmrfiStatus === 20 || tmrfiStatus === 19) ? (
          <>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">TM Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkTMRFI}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remark }}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px" }}
            >
              <p className="lightgreen fw-bold my-3 fs-6">MD Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div className="row mt-4">
                <span>
                  {tmrfiStatus === 20 ? (
                    <Alert
                      className="col-12"
                      message="Approved"
                      type="success"
                      showIcon
                    />
                  ) : tmrfiStatus === 19 ? (
                    <Alert
                      className="col-12"
                      message="Clarification Required"
                      banner
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkMD}
                    name="remarks"
                    readOnly={true}
                    style={{
                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {loading && <div className="loader"></div>}
      </form>
    </>
  );
};

export default TMRfiReview;
