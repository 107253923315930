import React, { useEffect, useState } from 'react';
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import Header from '../../../../Pages/Header';
import Footer from '../../../Footer';
import SideNavbar from '../../../SideNavbar';
import { Table, Tooltip as Tip } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getAllProjectsDashboard, API_HEADER } from "../../../../config";
import { useNavigate } from "react-router-dom";
import ProjectStatusSchema from '../../../../Schema/ProjectStatusSchema';

const ProgramWiseProjectTable = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const {
        workload_status,
        seriesName,
        program_id,
        programName,
        sbu_head,
        team_leader,
        trainee_validator,
        validator_verifier,
        project_stage,
        updated_period,
        region_status,
        region_id,
        contract_signed_month,
        contract_signed_status,
        title,
        startDate,
        endDate,
        graph_type
    } = location.state ?? {};
    
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            const payload = {
                program: program_id,
                page: pagination.current,
                limit: pagination.pageSize,
                program_status: seriesName,
                sbu_head: sbu_head,
                team_leader: team_leader,
                trainee_validator: trainee_validator,
                validator_verifier: validator_verifier,
                complete_status: seriesName,
                project_stage:project_stage,
                updated_period:updated_period, 
                region_status:region_status,
                region_id:region_id,
                contract_signed_status:contract_signed_status,
                contract_signed_month:contract_signed_month,
                workload_status:workload_status,
                startDate:startDate,
                endDate:endDate,
                graph_type:graph_type
            };
            try {
                const response = await axios.post(`${getAllProjectsDashboard}`, payload, API_HEADER);
                setDataSource(response.data.records.data);
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    total: response.data.records.total,
                }));
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchData();
    }, [seriesName, program_id, sbu_head, team_leader, trainee_validator, validator_verifier, pagination.current, pagination.pageSize,project_stage,updated_period,region_id]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setLoading(true);
    };

    const viewDetails = async (id) => {
        navigate(`/atdeskreview/${id}`);
    };

    const columns = [
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    S.No
                </span>
            ),
            dataIndex: "id",
            fixed: "left",
            width: 70,
            key: "id",
            render: (id, record, index) => {
                const pageIndex = (pagination.current - 1) * pagination.pageSize;
                return pageIndex + index + 1;
            },
        },
        {
            title: "EID",
            dataIndex: "earthood_id",
            key: "earthood_id",
            width: 90,
            render: (record) => (
                <Tip title={record}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                        {record}
                    </span>
                </Tip>
            ),
        },
        {
            title: "Project Name",
            dataIndex: "project_name",
            key: "project_name",
            width: 150,
            render: (record) => (
                <Tip title={record}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                        {record}
                    </span>
                </Tip>
            ),
        },
        {
            title: "Program",
            dataIndex: "program_name",
            key: "program_name",
            width: 90,
            render: (record) => (
                <Tip title={record.description}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                        {record.description}
                    </span>
                </Tip>
            ),
        },
        {
            title: "Scope",
            dataIndex: "scope_name",
            key: "scope_name",
            width: 90,
            render: (record) => (
                <Tip title={record.scope}>
                    <span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
                        {record.scope}
                    </span>
                </Tip>
            ),
        },
        {
            title: (
                <span className="text-capitalize textcolumntitle font14px fw-bold">
                    Status
                </span>
            ),
            width: 130,
            render: (record) => (
                <ProjectStatusSchema status={record.project_status} />
            ),
        },
        {
            title: "Action",
            key: "action",
            width: 80,
            render: (record) => (
                <a>
                    <EyeOutlined
                        onClick={() => viewDetails(record.id)}
                        style={{ color: "blue", fontSize: '20px' }}
                    />
                </a>
            ),
        },
    ];

    return (
        <>
            <Header />
            <SideNavbar/>
            <div className="content-wrapper bg-white">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row" style={{ justifyContent: "center" }}>
                            <div className="">
                                <div className="mx-3 my-3 border-2 border border-light-subtle p-0 rounded-3 mt-5">
                                    <div className="row border-0">
                                        <div className="col-12">
                                            <p className="text-black text-capitalize font20px fw-bold p-3 rounded-top-3">
                                                {title}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row d-flex justify-content-around'></div>
                                    <div className="row my-3 mx-3">
                                        <Table
                                            className="border-1 border rounded-0 border-subtle-light px-0"
                                            scroll={{ x: 1000 }}
                                            columns={columns}
                                            dataSource={dataSource}
                                            rowKey="id"
                                            loading={loading}
                                            pagination={pagination}
                                            onChange={handleTableChange}
                                            bordered
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ProgramWiseProjectTable;
