
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import '../../../../src/App.css'
import {
  API_HEADER,
  BASE_DOCUMENT,
  get_KickoutInfo_url,
  client_cc_action_kickout_url
} from "../../../config";
import FileUploaderComponent from "../../../utlis/FileUploaderComponent";
import LoaderComponent from "../../../utlis/LoderComponent";




const ClientKickoutCCRound = () => {

  const { id } = useParams();
  const navigate = useNavigate()

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size


  const [kickoutStatus, setkickoutStatus] = useState("");
  const [kickoutId, setKickoutId] = useState('');

  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [remarkClient, setRemarkClient] = useState('');
  const [remarkTL, setRemarkTL] = useState("");
  const [remarkCTR, setRemarkCTR] = useState("");
  const [ccStatus, setCCStatus] = useState("");


  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [file7, setFile7] = useState('');



  const [pddName, setPDDName] = useState(null);
  const [pdd, setPdd] = useState("");
  const [erName, setERName] = useState(null);
  const [er, setER] = useState("");
  const [cpaName, setCpaName] = useState(null);
  const [cpa, setCpa] = useState("");
  const [rrName, setRRName] = useState(null);
  const [rr, setRR] = useState("");
  const [otherName, setOtherName] = useState(null);
  const [other, setOther] = useState("");



  const [filePDDSizeError, setFilePDDSizeError] = useState(false);
  const [fileERSizeError, setFileERSizeError] = useState(false);
  const [fileRRSizeError, setFileRRSizeError] = useState(false);
  const [fileCpaSizeError, setFileCpaSizeError] = useState(false);
  const [fileOtherSizeError, setFileOtherSizeError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);



  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_KickoutInfo_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { kickout } = response.data;

      setKickoutId(record.kickout_id)
      setkickoutStatus(record.kickout_status);


      setRemarkTL(kickout.cc_tl_remarks);
      setRemarkCTR((kickout.kickout_status == 4 || kickout.kickout_status == 18) ? kickout.cc_client_remarks = "" : kickout.cc_client_remarks)
      setAction(kickout.cc_status);

      let url2 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_err_document}`;
      setERName(url2);
      setER(kickout.cc_err_document);

      let url3 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_pdd_document}`;
      setPDDName(url3);
      setPdd(kickout.cc_pdd_document);

      let url4 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_cpa_document}`;
      setCpaName(url4);
      setCpa(kickout.cc_cpa_document);

      let url5 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_irr_document}`;
      setRRName(url5);
      setRR(kickout.cc_irr_document);

      let url7 = `${BASE_DOCUMENT}/projects/${kickout.project_id}/${kickout.cc_other_docs}`;
      setOtherName(url7);
      setOther(kickout.cc_other_docs);

    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };


  const handleFilePDDChange = (file3) => {
    if (file3.size <= dynamicMaxSize * 1024 * 1024) {
      setFilePDDSizeError(false);
    }

    setFilePDDSizeError(false);
    setFile3(file3);
  };
  const handlePDDSizeError = (file3) => {
    setFilePDDSizeError(true);
  };


  const handleFileERChange = (file2) => {
    if (file2.size <= dynamicMaxSize * 1024 * 1024) {
      setFileERSizeError(false);
    }

    setFileERSizeError(false);
    setFile2(file2);
  };
  const handleERSizeError = (file2) => {
    setFileERSizeError(true);
  };


  const handleFileCpaChange = (file5) => {
    if (file5.size <= dynamicMaxSize * 1024 * 1024) {
      setFileCpaSizeError(false);
    }

    setFileCpaSizeError(false);
    setFile5(file5);
  };
  const handleCpaSizeError = (file5) => {
    setFileCpaSizeError(true);
  };

  const handleFileRRReportChange = (file4) => {
    if (file4.size <= dynamicMaxSize * 1024 * 1024) {
      setFileRRSizeError(false);
    }

    setFileRRSizeError(false);
    setFile4(file4);
  };
  const handleRRSizeError = (file4) => {
    setFileRRSizeError(true);
  };


  const handleFileOtherChange = (file7) => {
    if (file7.size <= dynamicMaxSize * 1024 * 1024) {
      setFileOtherSizeError(false);
    }

    setFileOtherSizeError(false);
    setFile7(file7);
  };

  const handleOtherSizeError = (file7) => {
    setFileOtherSizeError(true);
  };

  const handleRemarksChange = (content) => {
    setRemarkCTR(content);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(false);

    if (remarkCTR == null || remarkCTR == '<p><br></p>' || remarkCTR == '') {
      toast.error('Please Add Some Remarks')
    }
    else {
      try {
        setIsSubmitting(true);

        const formDataToSend = new FormData();


        formDataToSend.append("project_id", id);
        formDataToSend.append("cc_err_document", file2);
        formDataToSend.append("cc_pdd_document", file3);
        formDataToSend.append("cc_irr_document", file4);
        formDataToSend.append("cc_cpa_document", file5);
        formDataToSend.append("cc_other_docs[]", file7);
        formDataToSend.append("cc_client_remarks", remarkCTR);
        formDataToSend.append("kickout_id", kickoutId);

        const response = await axios.post(
          `${client_cc_action_kickout_url}`,
          formDataToSend,
          CONFIG_Token2
        );

        if (!response.data.status) {
          toast.error(response.data.message);
        } else if (filePDDSizeError || fileERSizeError || fileOtherSizeError || fileRRSizeError || fileCpaSizeError) {
          toast.error(`File size must below ${dynamicMaxSize}MB`);
        } else {
          toast.success("Document Submitted Successfully");

          setRemarkTL("");
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Error occurred while submitting data");
      } finally {
        // setLoading(false); // Hide loader
        setIsSubmitting(false);

      }
    }
  };

  return (
    <>
      {kickoutStatus == 18 ?
        <div>
          <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

          <form
            method="post"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Upload Document
                  </th>

                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Uploaded Document
                  </th>

                </tr>
              </thead>
              <tbody>


                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    ER
                    <span style={{ color: "red" }}>*</span>

                  </td>


                  {/* <td className='p-3'>

                    <FileUploader
                      handleChange={handleFileERChange}
                      name="er_report"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleERSizeError}
                    />


                    {fileERSizeError ? (
                      <span className="text-danger">
                        File size greater than {dynamicMaxSize}MB is not allowed
                      </span>
                    ) : (

                      <span>{file2 ? file2.name : ""}</span>
                    )}
                  </td> */}
                  <FileUploaderComponent file={file2} setFile={setFile2} />


                  <td>
                    <a href={erName} target="_blank">{er}</a>
                  </td>


                </tr>

                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    PDD/MR
                    <span style={{ color: "red" }}>*</span>

                  </td>


                  {/* <td className='p-3'>

                    <FileUploader
                      handleChange={handleFilePDDChange}
                      name="pdd_report"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handlePDDSizeError}
                    />


                    {filePDDSizeError ? (
                      <span className="text-danger">
                        File size greater than {dynamicMaxSize}MB is not allowed
                      </span>
                    ) : (

                      <span>{file3 ? file3.name : ""}</span>
                    )}
                  </td> */}
                  <FileUploaderComponent file={file3} setFile={setFile3} />


                  <td>
                    <a href={pddName} target="_blank">{pdd}</a>
                  </td>


                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    IRR

                  </td>

                  {/* <td className="p-3">

                    <FileUploader
                      handleChange={handleFileRRReportChange}
                      name="rr_report"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleRRSizeError}
                    />

                    {fileRRSizeError ? (
                      <span className="text-danger">
                        File size greater than {dynamicMaxSize} is not allowed
                      </span>
                    ) : (
                      <span>{file4 ? file4.name : ""}</span>
                    )}
                  </td> */}
                  <FileUploaderComponent file={file4} setFile={setFile4} />



                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>


                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">
                    CPA

                  </td>

                  {/* <td className="p-3">

                    <FileUploader
                      handleChange={handleFileCpaChange}
                      name="cpa_report"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      required={false}
                      onSizeError={handleCpaSizeError}
                    />




                    {fileCpaSizeError ? (
                      <span className="text-danger">
                        File size greater than {dynamicMaxSize} is not allowed
                      </span>
                    ) : (
                      <span>{file5 ? file5.name : ""}</span>
                    )}
                  </td> */}

                  <FileUploaderComponent file={file5} setFile={setFile5} />


                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>

                </tr>
                <tr>
                  <td class="text-center">5</td>


                  <td className="text-center lightgreen p-3">
                    Other Document

                  </td>

                  {/* <td className="p-3">

                    <FileUploader
                      handleChange={handleFileOtherChange}
                      name="other_doc"
                      types={fileTypes}
                      multiple={false}
                      maxSize={dynamicMaxSize}
                      onSizeError={handleOtherSizeError}
                    />

                    {fileOtherSizeError ? (
                      <span className="text-danger">
                        File size greater than {dynamicMaxSize} mb is not allowed
                      </span>
                    ) : (
                      <span>{file7 ? file7.name : ""}</span>
                    )}
                  </td> */}
                  <FileUploaderComponent file={file7} setFile={setFile7} />


                  <td>
                    <a target="_blank" href={otherName}>
                      {other}
                    </a>
                  </td>


                </tr>

              </tbody>
            </table>



            <div className="mb-4">
              <p className="lightgreen fw-bold ">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTL}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remarkTL }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>

            <div>
              <p className="lightgreen fw-bold">Client Remarks<span style={{ color: "red" }}>*</span></p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkCTR}
                  name="remarks"
                  onChange={handleRemarksChange}
                  dangerouslySetInnerHTML={{ __html: remarkCTR }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>




            <div className="container mt-4 d-flex justify-content-end pr-0">
              <button
                className="btn px-3 fs-5"
                style={{
                  backgroundColor: "#07b6af",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
            {loading && <div className="loader"></div>}
          </form>

        </div>
        :

        <div>
          <table className="table table-bordered  table-hover">
            <thead>
              <tr>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  S.No
                </th>
                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Document Name
                </th>


                <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                  Uploaded Document
                </th>

              </tr>
            </thead>
            <tbody>


              <tr>
                <td class="text-center">1</td>

                <td className="text-center lightgreen p-3">
                  ER
                  <span style={{ color: "red" }}>*</span>
                </td>




                <td>
                  <a href={erName} target="_blank">{er}</a>
                </td>


              </tr>

              <tr>
                <td class="text-center">2</td>

                <td className="text-center lightgreen p-3">
                  PDD/MR
                  <span style={{ color: "red" }}>*</span>
                </td>




                <td>
                  <a href={pddName} target="_blank">{pdd}</a>
                </td>

              </tr>

              <tr>
                <td class="text-center">3</td>



                <td className="text-center lightgreen p-3">
                  IRR
                </td>




                <td>
                  <a href={rrName}>{rr}</a>
                </td>



              </tr>

              <tr>
                <td class="text-center">4</td>



                <td className="text-center lightgreen p-3">
                  CPA
                </td>



                <td>
                  <a href={cpaName}>{cpa}</a>
                </td>


              </tr>



              <tr>
                <td class="text-center">5</td>


                <td className="text-center lightgreen p-3">
                  Other Document
                </td>






                <td>
                  <a target="_blank" href={otherName}>
                    {other}
                  </a>
                </td>


              </tr>

            </tbody>
          </table>


          {/* {kickoutStatus == 14 || kickoutStatus == 15 || (kickoutStatus == 17 && remarkCTR == null) || kickoutStatus == 23 || kickoutStatus == 21 || (kickoutStatus == 20 && remarkCTR == null) ? '' : */}
          {remarkTL &&
            <div className="mb-4 mt-4">
              <p className="lightgreen fw-bold ">Team Leader Remarks</p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkTL}
                  name="remarks"
                  dangerouslySetInnerHTML={{ __html: remarkTL }}
                  readOnly={true}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          }
          {/* {kickoutStatus == 14 || kickoutStatus == 15 || (kickoutStatus == 17 && remarkCTR == null) || kickoutStatus == 23 || kickoutStatus == 21 || (kickoutStatus == 20 && remarkCTR == null) ? '' : */}
          {remarkCTR &&
            <div>
              <p className="lightgreen fw-bold">Client Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />
              <div>
                <ReactQuill
                  theme="snow"
                  value={remarkCTR}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remarkCTR }}
                  style={{
                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
          }



        </div>

      }
    </>
  )
}

export default ClientKickoutCCRound;

