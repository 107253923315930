import React, { useEffect, useState } from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { get_list_of_users_sbu_cc_url, sbu_cc_action_url, get_project_details_url, API_HEADER, } from '../config';
import axios from "axios";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import LoaderComponent from "../utlis/LoderComponent";


const SBUCCReview = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [listOfUsers, setListOfUsers] = useState([])
    const [list, setList] = useState('')
    const [remark, setRemark] = useState('')
    const [CCName, setCCName] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [tlCCRemark, setTLCCRemark] = useState('')
    const [sbuRemark, setSBURemark] = useState('')
    const [listItem, setListItem] = useState('')
    const [ccStatus, setCCStatus] = useState('')


    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [dateError, setDateError] = useState('')

    const [loading, setLoading] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${get_list_of_users_sbu_cc_url}`)
            const { data } = response.data
            setListOfUsers(data)
            data.map((item, i) => {
                if (item.id == list) {
                    setCCName(item.name)
                }
            })
        }
        fetchData()
        fetchTLRemark(id)
    }, [list])

    const fetchTLRemark = async (id) => {
        try {
            const response = await axios.get(
                `${get_project_details_url}/${id}`,
                API_HEADER
            );
            const { record } = response.data;
            setTLCCRemark(record.cc_tl_remarks)
            setCCStatus(record.cc_status)
            setSBURemark(record.cc_ccremarks)
            setListItem(record.cc_id)
            setStartDate(record.cc_starting_date);
            setEndDate(record.cc_end_date);
        }
        catch (error) { }
    }
    const handleListOfUsers = (event) => {
        const selectedList = event.target.value;
        setList(selectedList)
    }


    const handleRemarksChange = (content) => {
        setRemark(content);
    };

    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setIsSubmitting(false);


        if (remark == null || remark == '<p><br></p>' || remark == '') {
            toast.error('Please Add Some Remarks')
        }
        else {

            try {
                // setLoading(true); // Show loader
                setIsSubmitting(true);

                const payload = { 'cc_id': list, 'cc_ccremarks': remark, "project_id": id, "cc_status": 2, 'cc_starting_date': startDate, 'cc_end_date': endDate }

                Swal.fire({
                    title: "Confirmation?",
                    html: `Are you sure, you want to allocate <strong>${CCName}</strong> as a  CC Reviewer?`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    reverseButtons: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const response = await axios.post(`${sbu_cc_action_url}`, payload, CONFIG_Token2)
                        if (response.status === 200 && response.data.status == true) {
                            toast.success("CC Allocated Successfully");
                            navigate(`/dashboard`);
                        } else {
                            toast.error("Failed to allocate CC")
                        }
                    } else {
                        toast.error("Failed to allocate CC")
                    }
                });

            } catch (error) {
            } finally {
                // setLoading(false); 
                setIsSubmitting(false);

            }

        }

    }

    const getCurrentDate = () => {
        const today = new Date();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        const year = today.getFullYear();

        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    };



    const validateDates = (dateFrom, dateTo) => {
        if (dateTo && dateFrom && dateTo <= dateFrom) {
            setDateError("End Date must be greater than or equal to Start Date");
        } else {
            setDateError('');
        }
    };


    const handleStartDateChange = (e) => {

        const value = e.target.value;


        const selectedDate = new Date(value);
        const currentDate = new Date();
        if (selectedDate > currentDate) {
            setStartDate(value)
            validateDates(value, endDate);
        } else {
            setDateError("Selected date must be greater than the current date.");
        }
    };

    const handleEndDateChange = (e) => {
        const value = e.target.value;

        const selectedDate = new Date(value);
        const currentDate = new Date();
        const startdate = new Date(startDate);
        if (selectedDate > currentDate && selectedDate > startdate) {
            setEndDate(value)
            validateDates(startDate, value);
        } else {
            setDateError("End Date must be greater than or equal to Start Date");
        }
    };

    return (
        <>
            {ccStatus == 1 ?
                <>
                    <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
                    <div>
                        <div className='row'>
                            <div className="col-12">
                                <label className="form-label lightgreen fs-6 mb-3">
                                    TL Remarks
                                </label>
                                <ReactQuill
                                    theme="snow"
                                    value={tlCCRemark}
                                    name="remarks"
                                    readOnly={true}
                                    style={{

                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-lg-6 col-md-4 col-12 mb-3 d-flex mt-4 ">
                                <label htmlFor="list_of_users" className="form-label lightgreen fs-6" style={{ width: '200px' }}>
                                    List Of Users<span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                    id="list_of_users"
                                    className="form-select borderlightgreen form-select-sm"
                                    aria-label="Default select example"
                                    name="list_of_users"
                                    // value={listOfUsers}
                                    onChange={handleListOfUsers}
                                >
                                    <option value={""}>
                                        Select
                                    </option>
                                    {listOfUsers.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-2 mt-4 ">
                            <div className="col-6 mb-3">
                                <label htmlFor="startdate" className="form-label lightgreen fs-6">
                                    Start Date <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="date"
                                    className={`form-control borderlightgreen`}
                                    id="start_date"
                                    required
                                    name="start_date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    min={getCurrentDate()} // Set the min attribute to disable past dates
                                />

                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="enddate" className="form-label lightgreen fs-6">
                                    Likely Completion Date<span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="date"
                                    className={`form-control borderlightgreen`}
                                    id="end_date"
                                    required
                                    name="end_date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    min={getCurrentDate()} // Set the min attribute to disable past dates
                                />
                                {dateError && <div className="text-danger">{dateError}</div>}
                            </div>
                        </div>


                        <div className='row'>
                            <div className="col-12">
                                <label className="form-label lightgreen fs-6 mb-3">
                                    Remarks{" "}
                                    <span style={{ color: "red" }}>*</span>
                                </label>

                                <ReactQuill
                                    theme="snow"
                                    value={remark}
                                    name="remarks"
                                    onChange={handleRemarksChange}
                                    style={{
                                        height: "20vh",
                                        marginBottom: "2rem",
                                    }}
                                    className="mb-5"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="d-flex justify-content-end  col-12">

                                <button
                                    className="btn px-3 fs-5"
                                    style={{
                                        backgroundColor: "#07b6af",
                                        color: "white",
                                    }}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>

                            </div>
                        </div>
                    </div>
                </>
                :
                <div>
                    <div className='row'>
                        <div className="col-12">
                            <label className="form-label lightgreen fs-6 mb-3">
                                TL Remarks
                            </label>
                            <ReactQuill
                                theme="snow"
                                value={tlCCRemark}
                                name="remarks"
                                readOnly={true}
                                style={{

                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-lg-6 col-md-4 col-12 mb-3 d-flex mt-4 ">
                            <label htmlFor="list_of_users" className="form-label lightgreen fs-6" style={{ width: '200px' }}>
                                List Of Users<span style={{ color: "red" }}>*</span>
                            </label>

                            <select
                                id="list_of_users"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="list_of_users"
                                value={listItem}
                                readOnly={true}
                                disabled
                            >
                                <option value={""}>
                                    Select
                                </option>
                                {listOfUsers.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-2 mt-4 ">
                        <div className="col-6 mb-3">
                            <label htmlFor="startdate" className="form-label lightgreen fs-6">
                                Start Date <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                type="date"
                                className={`form-control borderlightgreen`}
                                id="start_date"
                                disabled
                                name="start_date"
                                value={startDate}
                            // onChange={handleStartDateChange}
                            // min={getCurrentDate()} // Set the min attribute to disable past dates
                            />

                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="enddate" className="form-label lightgreen fs-6">
                                Likely Completion Date<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                type="date"
                                className={`form-control borderlightgreen`}
                                id="end_date"
                                disabled
                                name="end_date"
                                value={endDate}

                            />

                        </div>
                    </div>


                    <div className='row'>
                        <div className="col-12">
                            <label className="form-label lightgreen fs-6 mb-3">
                                SBU Remarks
                            </label>

                            <ReactQuill
                                theme="snow"
                                value={sbuRemark}
                                name="remarks"
                                readOnly={true}
                                style={{
                                    height: "20vh",
                                    marginBottom: "2rem",
                                }}
                                className="mb-5"
                            />
                        </div>
                    </div>


                </div>
            }

        </>
    )
}

export default SBUCCReview
