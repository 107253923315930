// please install npm install react-apexcharts apexcharts
import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER, contract_signed_monthly } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";




function ContractSignedMonthlyGraph()
{
    const navigate = useNavigate();

   const [proposalSigned, setProposalSigned]= useState([]);
   const [proposalSignedValue, setProposalSignedValue]= useState([]);
   const [createdMonth, setCreatedMonth]= useState([]);
   const [createdYear, setCreatedYear] = useState([]);
   const [regionWiseData, setRegionWiseData] = useState([]);

   useEffect( ()=>{
       const proposalsigned=[];
       const proposalsignedvalue=[];
       const createdmonth=[];
       const YearCreated = [];

       const getProposalScopeWise= async()=>{
       const payload = { "fy": "" };
       const reqData= await axios.post(`${contract_signed_monthly}`, payload,API_HEADER);
       const MyprogramwiseData = reqData.data.contract_monthly

       for(let i=0; i< MyprogramwiseData.length; i++)
       {        

        proposalsigned.push(((MyprogramwiseData[i].contract_signed_count)));
        proposalsignedvalue.push(((MyprogramwiseData[i].rfi_registrar_count)));
        const dateString = MyprogramwiseData[i].month_year_created;
        const [month, year] = dateString.split('-');
        const date = new Date(year, parseInt(month) - 1);
        const formattedMonth = date.toLocaleString('en-US', { month: 'long' });
        const formattedYear = date.getFullYear();
        createdmonth.push(formattedMonth);
        YearCreated.push(formattedYear);
       }
       
       setProposalSigned(proposalsigned);
       setProposalSignedValue(proposalsignedvalue);
       setCreatedMonth(createdmonth);
       setCreatedYear(YearCreated)
       setRegionWiseData(MyprogramwiseData);

       }

       getProposalScopeWise();

   },[]);

   const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
    const contract_signed_status = w.globals.seriesNames[seriesIndex];

    const title = w.globals.seriesNames[dataPointIndex];

    const contract_signed_month = w.globals.labels[dataPointIndex];

    if (contract_signed_month) {
        if(contract_signed_status=='Project Delivered'){
            navigate('/programwiseprojecttable', { state: { title:contract_signed_status,contract_signed_status, contract_signed_month } });
        }else{
            navigate('/proposaltable', { state: { title:contract_signed_status,contract_signed_status, contract_signed_month } });
        }
    }

   };

   return(
    <React.Fragment>
        <div className="container-fluid">
           
            <Chart
            type="bar"
            width={"100%"}
            height={400}
            series={[
                {
                    name:"Project Contract Signed",
                    data: proposalSigned,
                    color: '#182b55'
                },
                {
                    name:"Project Delivered",
                    data: proposalSignedValue,
                    color: '#900c3f'
                },
            ]}

            options={{
                chart:{
                    toolbar: {
                        show: false,  
                      },
                    stacked:false,
                    events: {
                        dataPointSelection: handleChartClick,
                    },
                },
                plotOptions:{
                    bar:{
                        horizontal:false,
                        columnWidth:'40%',
                        dataLabels : {
                            total: {
                                enabled: true,
                                style: {
                                  fontSize: '11px',
                                  fontWeight: 900
                                },
                                
                              }
                          }
                      }
                },
                stroke: {
                    width: 1,
                },
                xaxis:{
                    title:{
                        text: `Months of FY year ${createdYear[0]} - ${createdYear[0] + 1}`,
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold'
                        }
                    },
                    categories : createdMonth
                },
                yaxis:{
                    title:{
                        text:"Number of Projects",
                        style:{fontSize:'16px',fontWeight:'bold'}
                    },
                    labels:{
                        style: { fontSize: "15"},

                    }
                },
                legend:{
                    position: 'bottom',
                },
                dataLabels:{
                    enabled:false,
                },
                grid: {
                    show:true,
                    xaxis:{
                        lines:{
                            show:false
                        }
                    },
                    yaxis:{
                        lines:{
                            show:false
                        }
                    }

                },
                grid: {
                    borderColor: '#f1f1f1',
                    row: {
                      colors: ['transparent'], 
                      opacity: 1,
      
                    },
                  },

            }}

            />
        </div>
    </React.Fragment>
);
}
export default ContractSignedMonthlyGraph;