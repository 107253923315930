

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { get_project_details_url } from "../config";
import { API_HEADER, BASE_DOCUMENT } from "../config";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finding_file_url } from "../config";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";




const DraftFinding = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
    const dynamicMaxSize = my_file_maxSize.max_document_size

    // const [remark, setRemark] = useState("");
    const [tlremark, setTLRemark] = useState("");

    const [atremark, setATRemark] = useState("");

    const [clientRemark, setClientRemark] = useState("");
    const [member, setMember] = useState();
    const [isTL, setIsTL] = useState(false);
    const [fileFindingSizeError, setFileFindingSizeError] = useState(false);
    const [Findingfile, setFindingFile] = useState(null);
    const [lod, setlod] = useState("");
    const [formdisable, setFormDisable] = useState(false);
    const [status, setStatus] = useState(null);
    const [rowCount, setRowCount] = useState(1);
    const [dynamicname, setDynamicName] = useState([]);
    const [dynamic, setDynamic] = useState([]);
    const [projectid, setProjectId] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [findingTlName, setFindingTlName] = useState(null);
    const [findingTl, setFindingTl] = useState('');
    const [findingClientName, setFindingClientName] = useState(null);
    const [findingClient, setFindingClient] = useState('');
    const [formData, setFormData] = useState({ evidence_name: [] })
    const [tlformdisable, setTLFormDisable] = useState(false);
    const [findingStatus, setFindingStatus] = useState(null);


    const [pddName, setPddName] = useState(null);
    const [pdd, setPdd] = useState("");
    const [erName, setErName] = useState(null);
    const [lodName, setlodName] = useState(null);
    const [er, setEr] = useState("");
    const [mrName, setMrName] = useState(null);
    const [mr, setMr] = useState("");
    const [irrName, setirrName] = useState(null);
    const [irr, setIrr] = useState("");

    const [otherName, setOtherName] = useState(null);
    const [other, setOther] = useState("");
    const [cpaName, setcpaName] = useState(null);
    const [cpa, setCpa] = useState("");
    const [scope, setScope] = useState("");

    const [loading, setLoading] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const fetchFindingData = async (id) => {
            try {
                const response = await axios.get(
                    `${get_project_details_url}/${id}`,
                    API_HEADER
                );

                const { record } = response.data;

                if (response && response.data && response.data.record) {
                    const { record } = response.data;
                    setFormData({
                        evidence_name: record.evidences || [],
                        finding_status: record.finding_status
                    })
                    setFindingStatus(record.finding_status)
                    setClientRemark(record.client_finding_remarks)
                    setTLRemark((record.finding_status == 0 || record.finding_status == 1 || record.finding_status == 5) ? record.draft_tl_remarks = "" : record.draft_tl_remarks)
                    setATRemark(record.draft_at_remarks)
                    setProjectId(record.id);
                    setRowCount(record.evidences ? (record.evidences.length == 0 ? 1 : record.evidences.length) : 1);
                    setMember(record.finding_status)
                    setScope(record.scope_name.sector_name);
                    setFindingFile(record.finding_document)

                    if (record.finding_status == 1 || record.finding_status == 4) {
                        setFormDisable(true)
                    }
                    if (record.finding_status == 2 || record.finding_status == 4) {
                        setTLFormDisable(true)
                    }

                    const isTeamLeader = record.team.some(item => item.role_id == 1);
                    setIsTL(isTeamLeader);

                    setRowCount(record.evidences ? (record.evidences.length == 0 ? 1 : record.evidences.length) : 1);
                    setStatus(record.status);

                    let findingUrlTl = `${BASE_DOCUMENT}/projects/${record.id}/${record.finding_document}`;
                    setFindingTlName(findingUrlTl);
                    setFindingTl(record.finding_document);

                    let findingUrlClient = `${BASE_DOCUMENT}/projects/${record.id}/${record.client_finding_document}`;
                    setFindingClientName(findingUrlClient);
                    setFindingClient(record.client_finding_document);



                    const dynamicUrl = record.evidences.map((evidence) => `${BASE_DOCUMENT}/projects/${record.id}/${evidence.name}`)
                    setDynamicName(dynamicUrl)

                    const dName = record.evidences.map((evidence) => evidence.name);
                    setDynamic(dName)




                    let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.pdd_documents}`;

                    setPddName(url4);
                    setPdd(record.pdd_documents);

                    let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.er_documents}`;

                    setErName(url5);
                    setEr(record.er_documents);

                    let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.mr_documents}`;

                    setMrName(url6);
                    setMr(record.mr_documents);

                    let url7 = `${BASE_DOCUMENT}/projects/${record.id}/${record.irr_documents}`;
                    setirrName(url7);
                    setIrr(record.irr_documents);

                    let url8 = `${BASE_DOCUMENT}/projects/${record.id}/${record.cpa_documents}`;
                    setcpaName(url8);
                    setCpa(record.cpa_documents);

                    let url9 = `${BASE_DOCUMENT}/projects/${record.id}/${record.other_documents}`;
                    setOtherName(url9);
                    setOther(record.other_documents);

                }
            } catch (error) {

            }
        }
        fetchFindingData(id)

    }, [id]);

    const handleFindingFileChange = (file) => {
        if (file.size <= dynamicMaxSize * 1024 * 1024) {
            setFileFindingSizeError(false);
        }
        setFileFindingSizeError(false);
        setFindingFile(file);
    };

    const handleFindingFileError = (file) => {
        setFileFindingSizeError(true);
    };
    const handleFindingRemarksChange = (content) => {
        setTLRemark(content);
    };
    const CONFIG_Token2 = {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercent(percentCompleted);
        }
    };


    const handleSubmitFinding = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setIsSubmitting(false);


        if ((tlremark == null || tlremark == '<p><br></p>' || tlremark == '') || (Findingfile == null || Findingfile == '')) {
            toast.error("Please upload the document and remarks. ");
        }
        else {

            try {
                // setLoading(true); // Show loader
                setIsSubmitting(true)

                const formData = new FormData();

                formData.append("project_id", projectid);
                formData.append("finding_status", member);
                formData.append("finding_document", Findingfile);
                // formData.append("remarks", remark);
                formData.append("draft_tl_remarks", tlremark)

                const response = await axios.post(
                    `${finding_file_url}`,
                    formData,
                    CONFIG_Token2
                );
                if (!response.data.status) {
                    toast.error(response.data.message);
                } else if (fileFindingSizeError) {
                    toast.error(`File size must below ${dynamicMaxSize} mb`);
                } else {
                    toast.success("Draft Findings Submitted Successfully");
                    setTLRemark("");
                    setFindingFile(null);
                    setMember("");
                    navigate("/dashboard");

                }
            } catch (error) {
                toast.error("Documents and Remarks are mandatory");
            } finally {
                // setLoading(false); // Hide loader
                setIsSubmitting(false);

            }
        };

    }


    return (
        <>

            <div className="container">

                <table className="table table-bordered table-hover table-responsive-sm">
                    <thead>
                        <tr>
                            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                S.No
                            </th>
                            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                Document Name
                            </th>
                            <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                                Uploaded Document
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">1</td>
                            <td className="text-center lightgreen p-3">
                                PDD Document
                                {scope == "VAL" ?
                                    <span style={{ color: "red" }}>*</span>
                                    : ''
                                }
                            </td>
                            <td>
                                <a target="_blank" href={pddName}>
                                    {pdd}
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="text-center">2</td>
                            <td className="text-center lightgreen p-3">
                                ER Document
                                {scope == "VER" || scope == "VAL" ?
                                    <span style={{ color: "red" }}>*</span>
                                    : ''
                                }
                            </td>

                            <td>
                                <a target="_blank" href={erName}>
                                    {er}
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="text-center">3</td>
                            <td className="text-center lightgreen p-3">
                                MR Document
                                {scope == "VER" ?
                                    <span style={{ color: "red" }}>*</span>
                                    : ''
                                }
                            </td>

                            <td>
                                <a target="_blank" href={mrName}>
                                    {mr}
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="text-center">4</td>

                            <td className="text-center lightgreen p-3">
                                CPA Document
                                <span style={{ color: "red" }}></span>
                            </td>

                            <td>
                                <a target="_blank" href={cpaName}>
                                    {cpa}
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="text-center">5</td>

                            <td className="text-center lightgreen p-3">
                                IRR Document
                                <span style={{ color: "red" }}></span>
                            </td>

                            <td>
                                <a target="_blank" href={irrName}>
                                    {irr}
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="text-center">6</td>

                            <td className="text-center lightgreen p-3">Other Document</td>

                            <td>
                                <a target="_blank" href={otherName}>
                                    {other}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="table table-bordered my-4 table-hover">
                    <thead>
                        <tr>
                            <th className="form-label lightgreen fs-6 text-center table-heading-color">
                                S.No.
                            </th>
                            <th className="form-label lightgreen fs-6 text-center  table-heading-color">
                                Evidence Name
                            </th>
                            <th className="form-label lightgreen fs-6 text-center  table-heading-color">
                                Uploaded Documents
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(rowCount)].map((_, index) => (
                            <tr key={index}>
                                <td class="text-center">{index + 1}</td>
                                <td>
                                    <input
                                        type="text"
                                        name="evidence"
                                        className="form-control"
                                        disabled={true}
                                        placeholder=""

                                        value={
                                            formData.evidence_name[index]
                                                ? formData.evidence_name[index].remarks
                                                : ""
                                        }
                                    />
                                </td>

                                <td>
                                    <a target="_blank" href={dynamicname[index]}>

                                        {dynamic[index]}
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {isTL && (findingStatus === null || findingStatus === 0 || findingStatus === 5) ?
                    <>
                        <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
                        <form
                            method="post"
                            onSubmit={handleSubmitFinding}
                            encType="multipart/form-data" >

                            <div >
                                <p className="textlightgreen fw-bold mb-3 fs-5">
                                    Document and Remarks
                                </p>
                                <hr className="mb-3 lightgreen" />


                                <div className="row " style={{ width: '80vw' }}>
                                    <div className="col-4 my-4">
                                        <label
                                            htmlFor="Member"
                                            className="form-label lightgreen mb-4 fs-6"
                                        >
                                            Send To
                                            <span style={{ color: "red" }}>*</span>
                                        </label>

                                        <select
                                            id="member"
                                            className="form-select borderlightgreen form-select-sm"
                                            aria-label="Default select example"
                                            name="status"
                                            value={member}
                                            required
                                            onChange={(e) => {
                                                setMember(e.target.value);
                                            }}

                                        >
                                            <option value=''>Select</option>


                                            <option value={4}>
                                                Reply to Client
                                            </option>
                                            <option value={2}>
                                                Send to Team Members
                                            </option>
                                            <option value={6}>
                                                Draft Finding Closed
                                            </option>

                                        </select>
                                    </div>
                                    <div className="col-6 my-4">
                                        <div>
                                            {/* {
                                            findingStatus === 5 ?
                                                <label className="form-label lightgreen fs-6" htmlFor="remarks">
                                                    Finding Document<span style={{ color: "red" }}>*</span>
                                                </label>
                                                :
                                                <label className="form-label lightgreen fs-6" htmlFor="remarks">
                                                    Finding Document <span style={{ color: "red" }}>*</span>
                                                </label>
                                        } */}

                                            <label className="form-label lightgreen fs-6" htmlFor="remarks">
                                                Finding Document<span style={{ color: "red" }}>*</span>
                                            </label>



                                            {findingStatus === 5 ?

                                                <FileUploaderComponent file={Findingfile} setFile={setFindingFile} />

                                                :

                                                <FileUploaderComponent file={Findingfile} setFile={setFindingFile} />

                                            }



                                        </div>

                                    </div>

                                    {findingStatus == null || findingStatus == 0 ? '' :
                                        <div className="row mb-4">
                                            <div className="col-6" style={{ marginTop: "25px" }}>
                                                <p className="form-label lightgreen text-bold fs-6">
                                                    Finding Document
                                                </p>
                                                <span>
                                                    <a target="_blank" href={findingTlName}>
                                                        {findingTl}
                                                    </a>
                                                </span>
                                            </div>
                                            <div className="col-6" style={{ marginTop: "25px" }}>
                                                <p className="form-label lightgreen text-bold fs-6">
                                                    Client Finding Document
                                                </p>
                                                <span>
                                                    <a target="_blank" href={findingClientName}>
                                                        {findingClient}
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {/* {findingStatus == 0 || findingStatus == null || findingStatus == 1 ? '' :
                                <div className="my-4">
                                    <label className="form-label lightgreen fs-6 mb-2">
                                        Client Remarks

                                    </label>
                                    <ReactQuill
                                        theme="snow"
                                        value={clientRemark}
                                        name="remarks"
                                        readOnly={true}
                                        dangerouslySetInnerHTML={{ __html: clientRemark }}
                                        style={{
                                            // width: "100%",
                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                </div>
                            } */}

                                <div className="my-4">

                                    <label className="form-label lightgreen fs-6 mb-2">
                                        Team Leader Remarks{" "}
                                        <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <ReactQuill
                                        theme="snow"
                                        value={tlremark}
                                        name="tlremarks"
                                        required
                                        dangerouslySetInnerHTML={{ __html: tlremark }}
                                        onChange={handleFindingRemarksChange}
                                        style={{
                                            // width: "70vw",
                                            height: "20vh",
                                            marginBottom: "2rem",
                                        }}
                                        className="mb-5"
                                    />
                                    {/* {formSubmitted && isRemarkEmpty && <p style={{ color: 'red' }}>Please enter some content.</p>} */}

                                </div>

                                {clientRemark &&
                                    <div className="my-4">
                                        <label className="form-label lightgreen fs-6 mb-2">
                                            Client Remarks
                                        </label>
                                        <ReactQuill
                                            theme="snow"
                                            value={clientRemark}
                                            name="remarks"
                                            readOnly={true}
                                            dangerouslySetInnerHTML={{ __html: clientRemark }}
                                            style={{
                                                // width: "100%",
                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>}


                                {atremark &&
                                    <div className="my-4">
                                        <label className="form-label lightgreen fs-6 mb-2">
                                            Team Member Remarks

                                        </label>
                                        <ReactQuill
                                            theme="snow"
                                            value={atremark}
                                            name="remarks"
                                            readOnly={true}
                                            // dangerouslySetInnerHTML={{ __html: atRemark }}
                                            style={{
                                                // width: "100%",
                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />
                                    </div>
                                }



                            </div>

                            <div className="container " style={{ width: '100%' }}>
                                <div className="row">
                                    <div className="d-flex justify-content-end my-3 col-12 pr-0" >

                                        <button
                                            className="btn fs-5"
                                            style={{
                                                backgroundColor: "#07b6af",
                                                color: "white",
                                            }}
                                            type="submit"
                                        >
                                            Submit
                                        </button>

                                    </div>
                                </div>
                            </div>
                            {loading && <div className="loader"></div>}
                        </form>
                    </>
                    :


                    (
                        ((isTL && findingStatus === 1)) ?
                            <>
                                <LoaderComponent isSubmitting={isSubmitting} percent={percent} />

                                <form
                                    method="post"
                                    onSubmit={handleSubmitFinding}
                                    encType="multipart/form-data"
                                >

                                    <div>
                                        <p className="textlightgreen fw-bold mb-3 fs-5">
                                            Document and Remarks
                                        </p>
                                        <hr className="mb-3 lightgreen" />


                                        <div className="row " style={{ width: '80vw' }}>
                                            <div className="col-4 my-4">
                                                <label
                                                    htmlFor="Member"
                                                    className="form-label lightgreen mb-4 fs-6"
                                                >
                                                    Send To
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>

                                                <select
                                                    id="member"
                                                    className="form-select borderlightgreen form-select-sm"
                                                    aria-label="Default select example"
                                                    name="status"

                                                    value={member}
                                                    onChange={(e) => {
                                                        setMember(e.target.value);
                                                    }}
                                                    required
                                                >
                                                    <option value="">Select</option>


                                                    <option value={4}>
                                                        Reply to Client
                                                    </option>
                                                    <option value={2}>
                                                        Send to Team Members
                                                    </option>
                                                    <option value={6}>
                                                        Draft Finding Closed
                                                    </option>

                                                </select>
                                            </div>
                                            <div className="col-6 my-4">
                                                <div>
                                                    <label className="form-label lightgreen fs-6" htmlFor="remarks">
                                                        Finding Document <span style={{ color: "red" }}>*</span>
                                                    </label>


                                                    <div className="borderfileupload mx-auto">

                                                        <FileUploaderComponent file={Findingfile} setFile={setFindingFile} />

                                                    </div>




                                                </div>

                                            </div>

                                            <div className="col-6" style={{ marginTop: "25px" }}>
                                                <p className="form-label lightgreen text-bold fs-6">
                                                    Finding Document
                                                </p>
                                                <span>
                                                    <a target="_blank" href={findingTlName}>
                                                        {findingTl}
                                                    </a>
                                                </span>
                                            </div>

                                            {
                                                (findingStatus == 1 && findingClient == null) ? "" :
                                                    <div className="col-6" style={{ marginTop: "25px" }}>
                                                        <p className="form-label lightgreen text-bold fs-6">
                                                            Client Finding Document
                                                        </p>
                                                        <span>
                                                            <a target="_blank" href={findingClientName}>
                                                                {findingClient}
                                                            </a>
                                                        </span>
                                                    </div>

                                            }

                                        </div>

                                        <div className=" my-4">

                                            <label className="form-label lightgreen fs-6 mb-2">
                                                Team Leader Remarks{" "}
                                                <span style={{ color: "red" }}>*</span>
                                            </label>

                                            <ReactQuill
                                                theme="snow"
                                                value={tlremark}
                                                name="remarks"
                                                required={false}
                                                dangerouslySetInnerHTML={{ __html: tlremark }}
                                                onChange={handleFindingRemarksChange}
                                                style={{
                                                    // width: "70vw",
                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />
                                            {/* {formSubmitted && isRemarkEmpty && <p style={{ color: 'red' }}>Please enter some content.</p>} */}

                                        </div>

                                        {(findingStatus == 2 && clientRemark == null) || (findingStatus == 1 && clientRemark == null) ? '' :

                                            <div className="my-4 ">

                                                <label className="form-label lightgreen fs-6 mb-2">
                                                    Client Remarks

                                                </label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={clientRemark}
                                                    name="remarks"
                                                    required={false}
                                                    readOnly={true}
                                                    dangerouslySetInnerHTML={{ __html: clientRemark }}
                                                    style={{

                                                        height: "20vh",
                                                        marginBottom: "2rem",
                                                    }}
                                                    className="mb-5"
                                                />


                                            </div>
                                        }

                                        {atremark &&
                                            <div className="my-4">
                                                <label className="form-label lightgreen fs-6 mb-2">
                                                    Team Member Remarks

                                                </label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={atremark}
                                                    name="remarks"
                                                    readOnly={true}
                                                    // dangerouslySetInnerHTML={{ __html: atRemark }}
                                                    style={{
                                                        // width: "100%",
                                                        height: "20vh",
                                                        marginBottom: "2rem",
                                                    }}
                                                    className="mb-5"
                                                />
                                            </div>
                                        }



                                    </div>

                                    <div className="container">
                                        <div className="row">
                                            <div className="d-flex justify-content-end my-3 col-12 pr-0">
                                                <button
                                                    className="btn fs-5"
                                                    style={{
                                                        backgroundColor: "#07b6af",
                                                        color: "white",
                                                    }}
                                                    type="submit"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {loading && <div className="loader"></div>}
                                </form>
                            </>
                            :


                            <form
                                method="post"
                                onSubmit={handleSubmitFinding}
                                encType="multipart/form-data"
                            >

                                <div >
                                    <p className="textlightgreen fw-bold mb-3 fs-5">
                                        Document and Remarks
                                    </p>
                                    <hr className="mb-3 lightgreen" />
                                    <div className="row  " style={{ width: '100%' }}>
                                        <div className="col-4 my-3">
                                            <label
                                                htmlFor="Member"
                                                className="form-label lightgreen mb-3 fs-6"
                                            >
                                                Send To
                                                <span style={{ color: "red" }}>*</span>
                                            </label>

                                            <select
                                                id="member"
                                                className="form-select borderlightgreen form-select-sm"
                                                aria-label="Default select example"
                                                name="status"
                                                value={member}
                                                disabled
                                                onChange={(e) => {
                                                    setMember(e.target.value);
                                                }}
                                            >
                                                <option value=''>Select</option>



                                                <option value={4}>
                                                    Reply to Client
                                                </option>
                                                <option value={2}>
                                                    Send to Team Members
                                                </option>
                                                <option value={6}>
                                                    Draft Finding Closed
                                                </option>

                                            </select>
                                        </div>


                                        <div className="col-4" style={{ marginTop: "25px" }}>
                                            <p className="form-label lightgreen text-bold fs-6">
                                                Finding Document
                                            </p>
                                            <span>
                                                <a target="_blank" href={findingTlName}>
                                                    {findingTl}
                                                </a>
                                            </span>
                                        </div>



                                        {
                                            (findingStatus == 2 && findingClient == null) || (findingStatus == 3 && findingClient == null) || (findingStatus == 4 && findingClient == null) ? "" :
                                                <div className="col-4" style={{ marginTop: "25px" }}>
                                                    <p className="form-label lightgreen text-bold fs-6">
                                                        Client Finding Document
                                                    </p>
                                                    <span>
                                                        <a target="_blank" href={findingClientName}>
                                                            {findingClient}
                                                        </a>
                                                    </span>
                                                </div>
                                        }

                                    </div>

                                    <div className="my-4">

                                        <label className="form-label lightgreen fs-6 mb-2">
                                            Team Leader Remarks
                                            <span style={{ color: "red" }}>*</span>
                                        </label>

                                        <ReactQuill
                                            theme="snow"
                                            value={tlremark}
                                            name="remarks"
                                            readOnly={true}
                                            dangerouslySetInnerHTML={{ __html: tlremark }}
                                            onChange={handleFindingRemarksChange}
                                            style={{
                                                // width: "70vw",
                                                height: "20vh",
                                                marginBottom: "2rem",
                                            }}
                                            className="mb-5"
                                        />


                                    </div>

                                    {(findingStatus == 2 && clientRemark == null) || (findingStatus == 1 && clientRemark == null) || (findingStatus == 4 && clientRemark == null) ? '' :


                                        <div className="my-4" >

                                            <label className="form-label lightgreen fs-6 mb-2">
                                                Client Remarks

                                            </label>
                                            <ReactQuill
                                                theme="snow"
                                                value={clientRemark}
                                                name="remarks"
                                                readOnly={true}
                                                dangerouslySetInnerHTML={{ __html: clientRemark }}

                                                style={{

                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />


                                        </div>
                                    }

                                    {atremark &&
                                        <div className="my-4">
                                            <label className="form-label lightgreen fs-6 mb-2">
                                                Team Member Remarks

                                            </label>
                                            <ReactQuill
                                                theme="snow"
                                                value={atremark}
                                                name="remarks"
                                                readOnly={true}
                                                // dangerouslySetInnerHTML={{ __html: atRemark }}
                                                style={{
                                                    // width: "100%",
                                                    height: "20vh",
                                                    marginBottom: "2rem",
                                                }}
                                                className="mb-5"
                                            />
                                        </div>
                                    }







                                </div>

                                {loading && <div className="loader"></div>}

                            </form>
                    )

                }

            </div>


        </>
    )
}

export default DraftFinding


