import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API_HEADER,project_stage_wise_url ,client_project_stage_wise_graph_url} from "../../../config";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

function ATProjectStageWiseGraph({search,startDate,endDate}) {
    const [projectwisestage, setProjectWiseStage] = useState({});
    const [programWiseData, setProgramWiseData] = useState([]); // State to store MyprogramwiseData
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
               
                
                const payload = { 
                  "fy": "",
                  'start_date': startDate ? startDate.format('YYYY-MM-DD') : null,
                  'end_date': endDate ? endDate.format('YYYY-MM-DD') : null,
                };

                const response = await axios.post(`${project_stage_wise_url}`, payload, API_HEADER);
                setProjectWiseStage(response.data.data);
                setProgramWiseData(response.data.data); // Save the data
            } catch (error) {
                toast.error(error);
            }
        };
        fetchData();
    }, [search]);

    const customColors = [
        "#fe705d", "#ffb7c5", "#6b59cd", "#00cc99",
        "#008081", "#edce7a", "#ee7036", "#f3c262",
        "#a71666", "#d31638", "#4fb19d", "#823772"
    ];

    const formatLabel = (label) => {
        return label.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const formattedLabels = Object.keys(projectwisestage).map(formatLabel);

    const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
        
        const project_stage = w.globals.labels[dataPointIndex];

        const startDate1= startDate ? startDate.format('YYYY-MM-DD') : null;
        const endDate1= endDate ? endDate.format('YYYY-MM-DD') : null
        const graph_type= 'Stage Wise';
        console.log(startDate1);

        if (project_stage) {
            navigate('/programwiseprojecttable', { state: { startDate:startDate1,endDate:endDate1,graph_type, title:project_stage,project_stage } });
        }
    };

    return (
        <React.Fragment>
            <div className="container-fluid my-3">
                <Chart
                    type="pie"
                    width={"100%"}
                    height={450}
                    series={Object.values(projectwisestage)}
                    options={{
                        chart: {
                            toolbar: {
                                show: false,  // This will hide the icons on the top right corner
                              },
                            events: {
                                dataPointSelection: handleChartClick,
                            },
                        },
                        noData: { text: "Empty Data" },
                        labels: formattedLabels,
                        colors: customColors,
                        legend: { position: "bottom" },

                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default ATProjectStageWiseGraph;
