
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT, tr_action_url } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import FileUploaderComponent from "../utlis/FileUploaderComponent";
import LoaderComponent from "../utlis/LoderComponent";


import {
  API_HEADER,

} from "../config";


const TrRoundReview = (props) => {

  const navigate = useNavigate();
  const { id } = useParams();
  const { isTL } = props;

  const my_file_maxSize = JSON.parse(localStorage.getItem("mail_reminder"));
  const dynamicMaxSize = my_file_maxSize.max_document_size


  const [program, setProgram] = useState([]);
  const [remark, setRemark] = useState("")
  const [remarkTR, setRemarkTR] = useState("")
  const [remarkTL, setRemarkTL] = useState("")
  const [action, setAction] = useState("");
  const [trStatus, setTrStatus] = useState("")
  const [teamList, setTeamList] = useState([])
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');
  const [file5, setFile5] = useState('');
  const [file6, setFile6] = useState('');
  const [dvrName, setDVRName] = useState(null)
  const [dvr, setDvr] = useState("")
  const [rrName, setRRName] = useState(null)
  const [rr, setRR] = useState("")
  const [pddName, setPDDName] = useState(null)
  const [pdd, setPdd] = useState("")
  const [technicalReviewSheetName, setTechnicalReviewSheetName] = useState(null)
  const [technicalReviewSheet, setTechnicalReviewSheet] = useState("")
  const [cpaName, setCpaName] = useState(null)
  const [cpa, setCpa] = useState("")
  const [erName, setErName] = useState(null)
  const [er, setEr] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percent, setPercent] = useState(0);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;
      const { teams } = response.data;
      setTeamList(teams)
      setProgram(record.program);
      setTrStatus(record.tr_status)
      setFile1(record.dvr_report)
      setFile3(record.tr_er_doc)
      setFile4(record.tr_pdd_doc)
      setFile5(record.earthood_report)


      setRemarkTR((record.tr_status == 1 || record.tr_status == 6) ? record.dvr_tr_remarks == '' : record.dvr_tr_remarks)

      setRemarkTL(record.tr_tl_remarks)
      setStartDate(record.dvr_starting_date);
      setEndDate(record.dvr_completion_date);



      setAction(record.tr_status)

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.dvr_report}`;
      setDVRName(url1);
      setDvr(record.dvr_report);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_cpa_doc}`;
      setCpaName(url2);
      setCpa(record.tr_cpa_doc);

      let url3 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_er_doc}`;
      setErName(url3);
      setEr(record.tr_er_doc);

      let url4 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_pdd_doc}`;
      setPDDName(url4);
      setPdd(record.tr_pdd_doc);

      let url5 = `${BASE_DOCUMENT}/projects/${record.id}/${record.earthood_report}`;
      setTechnicalReviewSheetName(url5);
      setTechnicalReviewSheet(record.earthood_report);

      let url6 = `${BASE_DOCUMENT}/projects/${record.id}/${record.tr_irr_doc}`;
      setRRName(url6);
      setRR(record.tr_irr_doc);

    } catch (error) { }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(percentCompleted);
    }
  };


  const handleRemarksChange = (content) => {
    setRemarkTR(content);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };


  const handleSubmitAction = async (e) => {
    e.preventDefault()
    setIsSubmitting(false);

    if (((remarkTR == null || remarkTR == '<p><br></p>' || remarkTR == '') || (file1 == null || file1 == '') || (file3 == null || file3 == '') || (file4 == null || file4 == '') || (file5 == null || file5 == '')) && action) {
      toast.error("Please upload the document and remarks.");
    }
    else {

      try {
        // setLoading(true);
        setIsSubmitting(true);

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", id);
        formDataToSend.append("dvr_report", file1);
        formDataToSend.append("tr_cpa_doc", file2);
        formDataToSend.append("tr_er_doc", file3);
        formDataToSend.append("tr_pdd_doc", file4);
        formDataToSend.append("earthood_report", file5);
        formDataToSend.append("tr_irr_doc", file6);
        formDataToSend.append("tr_status", action);
        formDataToSend.append("dvr_tr_remarks", remarkTR);
        const response = await axios.post(
          `${tr_action_url}`,
          formDataToSend,
          CONFIG_Token2
        );
        if (!response.data.status) {
          toast.error(response.data.message);
        }
        else {
          toast.success("Document Submitted Successfully");
          navigate("/dashboard");
          setRemarkTR("")
          setAction("")
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      } finally {
        // setLoading(false);
        setIsSubmitting(false);

      }
    }
  };

  return (
    <>
      {
        trStatus == 6 || trStatus == 1 ?
          <>
            <LoaderComponent isSubmitting={isSubmitting} percent={percent} />
            <form
              method="post"
              encType="json/form-data"
              onSubmit={handleSubmitAction}
            >

              <table className="table table-bordered  table-hover">
                <thead>
                  <tr>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      S.No
                    </th>
                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Document Name
                    </th>



                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Upload Document
                    </th>


                    <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                      Uploaded Document
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>

                    <td className="text-center lightgreen p-3">
                      DVR Report<span style={{ color: "red" }}>*</span>

                    </td>




                    <FileUploaderComponent file={file1} setFile={setFile1} />


                    <td>
                      <a href={dvrName} target="_blank">
                        {dvr}
                      </a>
                    </td>

                  </tr>
                  <tr>
                    <td class="text-center">2</td>

                    <td className="text-center lightgreen p-3">
                      PDD / MR<span style={{ color: "red" }}>*</span>

                    </td>



                    <FileUploaderComponent file={file4} setFile={setFile4} />


                    <td>
                      <a href={pddName} target="_blank">
                        {pdd}
                      </a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">3</td>

                    <td className="text-center lightgreen p-3">
                      ER<span style={{ color: "red" }}>*</span>
                    </td>




                    <FileUploaderComponent file={file3} setFile={setFile3} />


                    <td>
                      <a href={erName}>{er}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">4</td>

                    <td className="text-center lightgreen p-3">IRR</td>




                    <FileUploaderComponent file={file6} setFile={setFile6} />


                    <td>
                      <a href={rrName}>{rr}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">5</td>

                    <td className="text-center lightgreen p-3">CPA</td>




                    <FileUploaderComponent file={file2} setFile={setFile2} />


                    <td>
                      <a href={cpaName}>{cpa}</a>
                    </td>

                  </tr>

                  <tr>
                    <td class="text-center">6</td>

                    <td className="text-center lightgreen p-3">
                      Technical Review Sheet
                      <span style={{ color: "red" }}>*</span>
                    </td>


                    <FileUploaderComponent file={file5} setFile={setFile5} />


                    <td>
                      <a href={technicalReviewSheetName} target="_blank">
                        {technicalReviewSheet}
                      </a>
                    </td>

                  </tr>
                </tbody>
              </table>



              <div className="row my-3">
                <div className="col-6 mb-3">
                  <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                    Start Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="trstartdate"


                    name="tr_start_date"
                    value={startDate}
                    disabled

                  />

                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                    Likely Completion Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    className={`form-control borderlightgreen`}
                    id="trenddate"

                    name="tr_end_date"
                    value={endDate}
                    disabled

                  />

                </div>
              </div>


              <div style={{ marginTop: '30px' }}>
                <p className="lightgreen fw-bold  fs-6">
                  Team Leader Remarks
                </p>
                <hr className="mb-3 lightgreen" />
                <div>

                  <ReactQuill
                    theme="snow"
                    value={remarkTL}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remarkTL }}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>
              </div>
              <div style={{ marginTop: '60px' }}>
                <p className="lightgreen fw-bold my-3 fs-6">
                  Technical Reviewer Remarks <span style={{ color: "red" }}>*</span>

                </p>
                <hr className="mb-3 lightgreen" />
                <div className="row mt-4">
                  <span className="col-10 col-lg-4 col-md-4">
                    <input
                      type="radio"
                      id="approve"
                      name="sbu_head"
                      value={8}
                      onChange={handleActionChange}
                      className=" text-success"
                    />
                    <label className="text-success mx-1" htmlFor="approve">Approved</label>
                  </span>

                  <span className="col-10 col-lg-4 col-md-4">
                    <input
                      type="radio"
                      id="clarification_required"
                      name="sbu_head"
                      value={7}
                      onChange={handleActionChange}
                      className="text-danger "
                    />
                    <label className="text-danger mx-1" htmlFor="clarification_required">
                      Clarification Required
                    </label>
                  </span>
                </div>
                <div className="row mt-4">
                  <div >
                    <ReactQuill
                      theme="snow"
                      value={remarkTR}
                      name="remarks"
                      dangerouslySetInnerHTML={{ __html: remark }}
                      onChange={handleRemarksChange}
                      style={{

                        height: "20vh",
                        marginBottom: "2rem",
                      }}
                      className="mb-5"
                    />
                  </div>

                </div>

                <div className="container my-4 d-flex justify-content-end pr-0">
                  <button className="btn px-3 fs-5"
                    style={{
                      backgroundColor: "#07b6af",
                      color: "white",
                    }}

                    type="submit">
                    Submit
                  </button>
                </div>
              </div>

              {loading && <div className="loader"></div>}

            </form>
          </>
          :


          <form
            method="post"
            encType="json/form-data"
            onSubmit={handleSubmitAction}
          >

            <table className="table table-bordered  table-hover">
              <thead>
                <tr>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    S.No
                  </th>
                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Document Name
                  </th>


                  <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                    Uploaded Document
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>

                  <td className="text-center lightgreen p-3">
                    DVR Report<span style={{ color: "red" }}>*</span>

                  </td>



                  <td>
                    <a href={dvrName} target="_blank">
                      {dvr}
                    </a>
                  </td>

                </tr>
                <tr>
                  <td class="text-center">2</td>

                  <td className="text-center lightgreen p-3">
                    PDD / MR<span style={{ color: "red" }}>*</span>

                  </td>



                  <td>
                    <a href={pddName} target="_blank">
                      {pdd}
                    </a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">3</td>

                  <td className="text-center lightgreen p-3">
                    ER<span style={{ color: "red" }}>*</span>
                  </td>



                  <td>
                    <a href={erName}>{er}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">4</td>

                  <td className="text-center lightgreen p-3">IRR</td>




                  <td>
                    <a href={rrName}>{rr}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">5</td>

                  <td className="text-center lightgreen p-3">CPA</td>



                  <td>
                    <a href={cpaName}>{cpa}</a>
                  </td>

                </tr>

                <tr>
                  <td class="text-center">6</td>

                  <td className="text-center lightgreen p-3">
                    Technical Review Sheet
                    <span style={{ color: "red" }}>*</span>
                  </td>



                  <td>
                    <a href={technicalReviewSheetName} target="_blank">
                      {technicalReviewSheet}
                    </a>
                  </td>

                </tr>
              </tbody>
            </table>


            <div className="row my-3">
              <div className="col-6 mb-3">
                <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                  Start Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="trstartdate"
                  name="tr_start_date"
                  value={startDate}
                  disabled

                />

              </div>
              <div className="col-6 mb-3">
                <label htmlFor="visit Date" className="form-label lightgreen fs-6">
                  Likely Completion Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  className={`form-control borderlightgreen`}
                  id="trenddate"
                  name="tr_end_date"
                  value={endDate}
                  disabled

                />

              </div>
            </div>



            <div style={{ marginTop: '30px' }}>
              <p className="lightgreen fw-bold my-3 fs-6">
                Team Leader Remarks
              </p>
              <hr className="mb-3 lightgreen" />
              <div>

                <ReactQuill
                  theme="snow"
                  value={remarkTL}
                  name="remarks"
                  readOnly={true}
                  dangerouslySetInnerHTML={{ __html: remark }}
                  style={{

                    height: "20vh",
                    marginBottom: "2rem",
                  }}
                  className="mb-5"
                />
              </div>
            </div>
            <div style={{ marginTop: '70px' }}>
              <p className="lightgreen fw-bold my-3 fs-6">
                Technical Reviewer Remarks<span style={{ color: "red" }}>*</span>
              </p>
              <hr className="mb-3 lightgreen" />


              <div className="row mt-4">
                <span >
                  {trStatus === 8 ? (
                    <Alert className="col-12" message="Approved" type="success" showIcon />
                  ) : trStatus === 7 ? (
                    <Alert className="col-12" message="Clarification Required" banner />
                  ) : (
                    ''
                  )}
                </span>

              </div>
              <div className="row mt-4">
                <div>
                  <ReactQuill
                    theme="snow"
                    value={remarkTR}
                    name="remarks"
                    readOnly={true}
                    dangerouslySetInnerHTML={{ __html: remark }}
                    style={{

                      height: "20vh",
                      marginBottom: "2rem",
                    }}
                    className="mb-5"
                  />
                </div>



              </div>

            </div>
            {loading && <div className="loader"></div>}
          </form>
      }

    </>
  )
}

export default TrRoundReview